import React, { useContext, useEffect, useState } from 'react'
import axios from "axios";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Fab from '@mui/material/Fab';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { IconPlus } from '@tabler/icons-react';
import { GlobalContext } from '../contexts/GlobalContext'
import { useNavigate, useLocation } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import { format, formatDistance, parse } from 'date-fns'

function Comments(props) {
    const { url, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken, ledgers, portal, masters  } = useContext(GlobalContext);
    const [comments, setComments] = useState([]);
    const [content, setContent] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, [ ]);

    const fetchData = () => {
        axios.post( url + 'api/comments', {
            area: props.area,
            ref_id: props.ref_id,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            console.log(response);
            setComments(response.data.comments);
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const handleSubmit = (event) => {
        setContent("");
        event.preventDefault();
            axios.post( url + 'api/comments/add', {
                customer: customer,
                contact: contact,
                phone: phone,
                creator: portal == "supplier" ? 2 : 3,
                area: props.area,
                ref_id: props.ref_id,
                content: content,
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                if(response.data.error == 0) {
                    setLoading(false);
                    setComments((prev) => [...prev, ...response.data.comments]);
                } else {
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        
    };

    const Comment = (prop) => {
        return (
            <Box sx={{ display: 'flex', mt: 4, flexDirection: prop.data.created_by == customer ? 'row-reverse' : 'row' }}>
                <Avatar {...stringAvatar(prop.data.contact_name ? prop.data.contact_name : prop.data.creator_details?.name)} />
                <Card sx={{ border: 1, borderColor: 'divider',mx:1 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                        <Typography variant="body" component="p">{prop.data.content ? prop.data.content : ''}</Typography>
                    </Box>
                    <Box sx={{ borderColor: 'divider', px: 2, py:1 }}>
                        <Typography variant="caption" component="p">by { prop.data.contact_name ? prop.data.contact_name + ', ' : '' } { prop.data.creator_details?.name }, {formatDistance(new Date(prop.data.created_at), new Date(), { addSuffix: true })}</Typography>
                    </Box>
                </Card>
            </Box>
        )
    }

    function stringToColor(string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
    }
    
    function stringAvatar(name) {
        var split = name.split(' ');
        return {
            sx: {
            bgcolor: stringToColor(name),
            width: 26,
            height : 26,
            fontSize: 12
            },
            children: `${split[0][0]}${split[1] ? split[1][0] : ''}`,
        };
    }

    return (
    <React.Fragment>
        <Typography variant="h6" component="h6" sx={{ mt:4 }}>Comments ({Object.keys(comments).length})</Typography>
        {comments.map((row, index) => (
            <Comment key={row.id} data={row}></Comment>
        ))}

        <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', my: 4 }}>
                <Avatar {...stringAvatar(customerName)} />
                <TextField sx={{ mb: 1, ml: 2 }} fullWidth required={true} 
                        value={content}
                        multiline
                        rows={2}
                        placeholder='Type your comment here'
                        onChange={event => {
                            const { value } = event.target;
                            setContent(value);
                        }} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 3, mb: 8 }}>
                <LoadingButton type="submit" loading={loading} variant="contained">Post</LoadingButton>
            </Box>
        </form>
    </React.Fragment>
    )
}
export default Comments;