import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../../components/Layout';
import { GlobalContext } from '../../contexts/GlobalContext'
import { useNavigate, useParams } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { useTheme } from '@emotion/react';
import PhoneIcon from '@mui/icons-material/Phone';
import { Button, Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NoData from '../../components/NoData';
import LoadingComponent from '../../components/LoadingComponent';
import PersonIcon from '@mui/icons-material/Person';
import MailIcon from '@mui/icons-material/Mail';
import { format } from 'date-fns';

function Ledger() {
    let { id } = useParams();
    const navigate = useNavigate();
    const { url, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, ledgers, setToken, portal  } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        fetchData();
        if(window.cordova) {
            window.cordova.plugins.firebase.analytics.setCurrentScreen("Ledger View");
        }
    }, [ ]);

    console.log({contact, customer, phone, id, portal})

    const fetchData = () => {
        axios.post( url + 'api/ledger', {
            customer: customer,
            contact: contact,
            phone: phone,
            id: id,
            portal
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            // console.log({received: response.data})
            setData(response.data);
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const openLink = (link) => {
        if(!window.cordova) {
            window.open(link, '_blank').focus();
        } else {
            cordova.InAppBrowser.open(link, "_system");
        }
    }

    const Contacts = () => {
                
        if (data.hasOwnProperty('contacts')) { 
            return (
                <Box sx={{my: 2, pt:1}}>
                    <Typography variant="h6" component="p">Contacts</Typography>
                    { Object.keys(data.contacts)?.length ?
                        <React.Fragment>                            
                            {data.contacts?.map((row, index) => (
                                <Paper key={ row.id } sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, mb: 2 }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                                        <Typography variant="h6" component="h6">{ row.name }</Typography>
                                        { !row.status && <Chip variant="outlined" color="error" size="small" label="Disabled" /> }
                                    </Box>
                                    <Grid container>
                                        <Grid item xs={12} sx={{  borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                            <Typography variant="overline" component="p">Designation</Typography>
                                            <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }}>{row.designation ? row.designation_detail.title : ''}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                            <Typography variant="overline" component="p">Phone</Typography>
                                            <Typography variant="body" component="p" onClick={() => { if(row.cc && row.phone){openLink(`tel:+${row.cc}${row.phone}`); }}} sx={{ wordWrap: 'break-word' }}>{row.phone ? row.phone : ''}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                            <Typography variant="overline" component="p">Email</Typography>
                                            <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }} onClick={() => {if(row.email){ openLink(`mailto:${row.email}`) }}}>{row.email ? row.email : 'Not available'}</Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            ))}
                        </React.Fragment>
                        :
                        <NoData />
                    }
                </Box>
            )
        } else {
            return (
                <React.Fragment></React.Fragment>
            )
        }
    }

    const Info = () => {

        const openWhatsapp = (number) => {
            if (!window.cordova) {
                window.open(`https://web.whatsapp.com/send?phone=${number}`, '_blank').focus();
            } else {
                window.open(`whatsapp://send?phone=${number}`, '_system');
            }
        }

        if (data.hasOwnProperty('ledger')) { 
        return (
            <React.Fragment>
                <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, mb: 4 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                        <Typography variant="overline" component="p" >{portal === "supplier" ?  "Buyer name" : "Supplier name"}</Typography>
                        <Typography variant="h6" component="h6">{data.ledger.name}, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{ data?.ledger?.city_name }</span></Typography>
                    </Box>
                    {data.ledger.alias !== null && <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                        <Typography variant="overline" component="p" >{portal === "supplier" ?  "Buyer alias name" : "Supplier alias name"}</Typography>
                        <Typography variant="h6" component="h6">{data.ledger.alias || "Not available"} </Typography>
                    </Box>}
                    <Grid container>
                        <Grid item xs={12} sx={{ borderRight: 1, borderBottom: 1,  borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p" >Address</Typography>
                            <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }} >
                                {data.ledger.address_1 ? data.ledger.address_1 : ''}
                                {data.ledger.address_2 ? " "+ data.ledger.address_2 : ''}
                                {data.ledger.area ? " "+ data.ledger.area : ''}
                                {data.ledger.city_name ? " "+ data.ledger.city_name : ''}
                                {data.ledger.state_name ? " "+ data.ledger.state_name : ''}
                                {data.ledger.country_name ? " "+ data.ledger.country_name : ''} 
                                {data.ledger.pincode ? " - "+ data.ledger.pincode : ''}                               
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Phone</Typography>
                            <Typography variant="body" component="p" 
                            onClick={() => { if(data.ledger.cc && data.ledger.phone){openLink(`tel:+${data.ledger.cc}${data.ledger.phone}`) }}}
                             >{data.ledger.phone ? `+${data.ledger.cc} ${data.ledger.phone}` : 'Not available'}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Email</Typography>
                            <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }}
                            onClick={() => {if(data.ledger.email){ openLink(`mailto:${data.ledger.email}`) }}}
                            >{data.ledger.email ? data.ledger.email : 'Not available'}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Whatsapp</Typography>
                            <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }}
                            onClick={() => {if(data.ledger.wa_number){ openWhatsapp(data.ledger.wa_number) }}}
                            >{data.ledger.wa_number ? data.ledger.wa_number : 'Not available'}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Gst treatment</Typography>
                            <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }}>{data.ledger.gst_treatment || 'Not available'}</Typography>
                        </Grid>                        
                        <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Gst number</Typography>
                            <Typography variant="body" component="p">{data.ledger.gstin ? data.ledger.gstin : 'Not available'}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Pan number</Typography>
                            <Typography variant="body" component="p">{data.ledger.pan ? data.ledger.pan : 'Not available'}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ borderRight: 1,borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p" sx={{mb:1}}>Preferred Transport</Typography>
                            <Box sx={{ display: 'flex', justifyContent:"center", alignItems:"center", flexDirection: 'row', gap: 1 }}>
                                
                                    <React.Fragment>
                                        {data.transports !== null ?
                                        <Box sx={{width: "100%",}}>                                            
                                        { data.transports?.length > 0 && data.transports.map((row) => (
                                            <Chip sx={{m:1}} label={row.name} key={row.id} />
                                        ))} 
                                        </Box>
                                         : 
                                        <Chip sx={{m:1}} label={"No Data"} />}
                                        
                                    </React.Fragment>
                                
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p" sx={{mb:1}}> Transports other than Mumbai</Typography>
                            <Box sx={{ display: 'flex', justifyContent:"center", alignItems:"center", flexDirection: 'row', gap: 1 }}>
                                
                                    <React.Fragment>
                                        {data.transports?
                                        <Box sx={{width: "100%",}}>
                                        {data.transports2?.length > 0 && data.transports2.map((row) => (
                                            <Chip sx={{m:1}} label={row.name} key={row.id} />
                                        ))}
                                        </Box>
                                        :
                                        <Chip sx={{m:1}} label={"No Data"} />
                                        }
                                    </React.Fragment>
                                
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </React.Fragment>
        )
        } else {
            return (
                <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, px: 2, py:2 }}>
                    <Typography variant="overline" component="p">Loading...</Typography>
                </Paper>
            )
        }
    }

    const MoreAddress = () => {
        const {addresses} = data.ledger
        const AddressCard = ({address}) => {
            return (
                <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, mb: 2 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                    <Typography variant="overline" component="p">{address?.name}</Typography>
                    </Box>
                    <Grid container>
                        <Grid item xs={12} sx={{ borderRight: 1, borderBottom: 1,  borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }} >
                                {address.address_1 ? address.address_1 : ''}
                                {address.address_2 ? " "+ address.address_2 : ''}
                                {address.area ? " "+ address.area : ''}
                                {address.city_details.name ? " "+ address.city_details.name : ''}
                                {address.state_details.name ? " "+ address.state_details.name : ''}
                                {address.country_details.name ? " "+ address.country_details.name : ''}  
                                {address.pincode ? " - "+ address.pincode : ''}                               
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Phone</Typography>
                            <Typography variant="body" component="p" 
                            onClick={() => { if(address.cc_phone){openLink(`tel:+${address.cc_phone}`)}}}
                             >{`+${address.cc_phone}` || "Not available"}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Email</Typography>
                            <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }}
                            onClick={() => {if(address.email){ openLink(`mailto:${address.email}`) }}}
                            >{address.email ? address.email : 'Not available'}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            )
        }

        return (
            <Box sx={{my:2, pt:1}}>
            <Typography variant="h6" component="h6" sx={{my: 1}}>Further Addresses</Typography>
            {( addresses && addresses?.length !== 0 )? addresses?.map((address, index) => <AddressCard address={address} key={index} />) : <NoData />}
            </Box>
        )
    }

    const [numbersList, setNumbersList] = useState([]);
    const [open, setOpen] = useState(false);

    function handleMultipleNumbers (numberArray)  {
        setOpen(true);
        setNumbersList(numberArray);
    }

    const Transports = ({transports, label}) => {

        const Item = ({transport, index}) => {
            let mobileNumbers = [];
            const isMultipleNumber = transport?.phone?.includes(",")
            if(isMultipleNumber){
                mobileNumbers = transport?.phone.split(',')
            }
            return (
                <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', my:2 }}>
                    <Box sx={{padding: 2}}>

                        <Typography variant="h6" component="h6"  sx={{mb: 2}}><b>{transport.name}</b></Typography>

                        {transport.address ? <Box sx={{display: 'flex', gap: 1, mb: 1}}>
                        <LocationOnIcon fontSize='small'  />
                        <Typography variant="h6" component="h6" >{transport.address}</Typography>
                        </Box> : <></>}

                        {transport?.contact_person ? 
                        <Box sx={{display: 'flex', gap: 1, mb: 1, alignItems: "center",}}>
                        <PersonIcon fontSize='small' />
                        <Typography variant="h6" component="h6" >{transport?.contact_person?.toUpperCase()}</Typography>
                        </Box> : <></>}

                        { transport?.phone &&
                        <Box sx={{display: "flex", gap: 2, mb: 1}}>
                        <Box sx={{display: 'flex', gap: 1, flex:2, justifyContent: "flex-start", alignItems: "center",}}>
                        <PhoneIcon fontSize='small'  />
                        <Typography variant="h6" component="h6">{transport.phone && transport.phone}</Typography>
                        </Box>
                        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Button onClick={isMultipleNumber ? () => handleMultipleNumbers(mobileNumbers) :() => { openLink(`tel:${transport.phone}`) }} 
                        variant="contained" sx={{ flex: 1, overflow: 'hidden', whiteSpace: 'nowrap', }}>Call</Button>
                        </Box>
                        </Box>}

                        {transport?.email && 
                        <Box sx={{display: "flex", gap: 2, }}>
                        <Box sx={{display: 'flex', gap: 1, flex:2, justifyContent: "flex-start", alignItems: "center", }}>
                        <MailIcon fontSize='small'  />
                        <Typography variant="h6" component="h6">{transport?.email && transport?.email}</Typography>
                        </Box>
                        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Button onClick={()=> openLink(`mailto:${transport?.email}`)} 
                        variant="contained" sx={{ flex: 1, overflow: 'hidden', whiteSpace: 'nowrap', }}>Email</Button>
                        </Box>
                        </Box>}
                        
                    </Box>
                </Paper>
            )
        };


        return (
            <Box sx={{my: 2, pt:1}}>            
             <Typography variant="h6" component="h6">{label}</Typography>
                
                {(transports && transports?.length !== 0) ? 
                transports?.map((transport, index) => <Item transport={transport} index={index} key={index} />)
                :
                <NoData />
                }
            </Box>
        )
    }

    const Schedule = () => {

        let schedulesArray;
        
        if(portal === 'buyer'){
            schedulesArray = data.ledger.supplier_schedule;
        }else if(portal === "supplier"){
            schedulesArray = data.ledger.buyer_schedule;
        }
        
        const filteredArray = schedulesArray.filter((item,index) => {
            return (item.nett_status == 1 || item.due_status == 1)
        })

        const ScheduleItem = ({item}) => {   
            return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, mb: 2 }}>

                    <Grid container>

                        <Grid item xs={12} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Discount Interval</Typography>
                            <Typography variant="body" component="p">{ format(new Date(item?.effective_from), 'dd/MM/yyyy') || "Not available"} {"-"} { format(new Date(item?.effective_to), 'dd/MM/yyyy') || "Not available"}</Typography>
                        </Grid>

                       { item.due_status == 1 &&
                       <>
                       <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Due days</Typography>
                            <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }} >
                                { `${item?.due_days} days` || 'Not available'}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Due discount</Typography>
                            <Typography variant="body" component="p">{ `${item?.due_discount}%` || "Not available"}</Typography>
                        </Grid>
                        </> }                       

                        { item.nett_status == 1 &&
                            <>
                            <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">NETT days</Typography>
                            <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }} >
                                { `${item?.nett_days} days` || 'Not available'}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">NETT Discount</Typography>
                            <Typography variant="body" component="p" sx={{ wordWrap: 'break-word' }} >
                                { `${item?.nett_discount}%` || 'Not available'}</Typography>
                        </Grid>
                        </> }

                        {!!item?.remarks &&
                            <Grid item xs={12} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                <Typography variant="overline" component="p">Remarks</Typography>
                                <Typography variant="body" component="p">{ item?.remarks ||"Not available"}</Typography>
                            </Grid>
                        }

                    </Grid>
                </Paper>
                )
        }

        return (
            <Box sx={{my: 2, pt:1}}>
            <Typography variant="h6" component="h6">Schedules</Typography>
            { filteredArray?.length !== 0 ?
                filteredArray?.map((item, index) => {
                    return <ScheduleItem item={item} key={index} />
                })
                :
                <NoData />
            }
            </Box>
        )
    }

    return (
        <div>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            data.hasOwnProperty('ledger') ?
                <Layout title={ portal === 'supplier' ? "Buyer Details" : "Supplier Details" }>
                    <Info />
                    <Schedule />
                    <MoreAddress />
                    <Contacts />
                    <Transports transports={data.transports} label={"Transports"} />
                    <Transports transports={data.transports2} label={"Transports other than Mumbai"}/>
                    
                <Dialog  onClose={()=> {setOpen(false); setNumbersList([]);}} open={open}>
                <DialogTitle >
                   <Typography sx={{fontSize: "20px"}}>Contact Numbers</Typography>
                </DialogTitle>
                <List>
                    {numbersList?.map( (number, index) => {
                        if(number){
                            return  <ListItem key={index} >
                                        <ListItemButton onClick={() => {openLink(`tel:${number.trim()}`)} } >
                                        <ListItemIcon >
                                            <PhoneIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={number.trim()} />
                                        </ListItemButton>
                                    </ListItem>} 
                        }
                     )}
                </List>
                
                </Dialog>
                </Layout>
            :

                <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, px: 2, py:2 }}>
                    <Typography variant="overline" component="p">Loading...</Typography>
                </Paper>
        }
        </div>
    );
}

export default Ledger;
