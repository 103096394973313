import { Box } from "@mui/material"
import { useContext, useState } from "react"
import Layout from "../../components/Layout"
import LoadingComponent from "../../components/LoadingComponent";
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../contexts/GlobalContext";




function ReportsCategory(){

    const navigate = useNavigate();

    const { portal } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);  
    
    let reportsCategory = [
        {
            primary: "Orders",
            subCategory: [
                {
                    name: "Pending orders",
                    onClick: () => {navigate("/reports/pending_orders")}
                },
                {
                    name: "Unfulfilled order by design",
                    onClick: () => {navigate("/reports/unfullfilled_orders_by_design")}
                },
                {
                    name: "Unclosed over delivery date",
                    onClick: () => {navigate("/reports/unclosed_over_delivery_date")}
                },
                {
                    name: "Order Report",
                    onClick: () => {navigate("/reports/order_report")},
                }, 
            ]
        },
        // {
        //     primary: "Visits",
        //     subCategory: [
        //         {
        //             name: "Performance of supplier and buyer",
        //             onClick: ()=>{console.log("Performance of supplier and buyer")}
        //         }
        //     ]
        // },
        // {
        //     primary: "Performance",
        //     handleClick: ()=>{console.log("Performance")}
        // }
    ]

    if(portal === "buyer"){
        reportsCategory = [...reportsCategory, 
            {primary: "Due by Supplier", handleClick: () => {navigate("/reports/due_report") }},
            {primary: "Visits Performance by Supplier", handleClick: () => {navigate("/reports/visit_performance") } },
            {primary: "Terms by Supplier", handleClick: () => {navigate("/reports/terms_by_supplier") } },
        ]
    }else if(portal === "supplier"){
        reportsCategory = [...reportsCategory, 
            {primary: "Due by Buyer", handleClick: () => {navigate("/reports/due_report") }},
            {primary: "Visits Performance by Buyer", handleClick: () => {navigate("/reports/visit_performance") } },
            {primary: "Terms by Buyer", handleClick: () => {navigate("/reports/terms_by_buyer") } },
        ]
    }

    const NavItem = ({item}) => {
        const [open, setOpen] = useState(false)
        return (
            <>
                <ListItemButton onClick={item?.subCategory?.length > 0 ? () => {setOpen(!open)} : item?.handleClick}>
                    <ListItemText primary={item?.primary} />
                    {(item?.subCategory?.length > 0)  && (open ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>
                    {item?.subCategory?.length > 0 &&
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                            {item?.subCategory?.map((item, index) => (
                                <ListItemButton sx={{ pl: 6 }} key={index} onClick={item?.onClick}>
                                    <ListItemText primary={item?.name} />
                                </ListItemButton>
                            ))}
                            </List>
                        </Collapse>
                    }                    
            </>

        )
    }

    return(
        <>
        {  loading ? 
            <LoadingComponent loading={loading} />
            :
            <>
            <Layout title={"Reports"}>
                <Box>
                    <List
                        sx={{ width: '100%', bgcolor: '#fff' }}
                        component="nav"
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                            Reports Category
                            </ListSubheader>
                        }
                    >
                        {reportsCategory.map((item, index) => (<NavItem key={index} item={item} />))}
                    </List>
                </Box>
            </Layout>
            </>
        } 
        </>
    )
}

export default ReportsCategory;