import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { format } from 'date-fns';


export function exportPdf( tableRef , pdfTitle="Reports", printString="", content="" ) {
    
        const pdf = new jsPDF({orientation: "landscape", format: 'a4'});

        //PDF Headlines
        pdf.text(pdfTitle, 15, 15); 
        pdf.setFontSize(8);
        pdf.text(`Generated on: ${format(new Date(), 'dd MMM yyyy hh:mm:ss a')}`, 15, 20);
        pdf.setFontSize(12);

        if(!!printString){
          pdf.text(printString, 15, 30);
        }

        if(!!content){
          pdf.setFontSize(10);
          pdf.text(content, 15, 40);
          pdf.setFontSize(12);
        }

        //PDF Table
        pdf.autoTable({
          html: tableRef?.current, // Pass the table element directly
          startY: !!printString ? !!content ? 45 : 35 : 25,
        });  
        
        if(!window.cordova) {
          pdf.save(`${pdfTitle.toLowerCase().split(' ').join('_')}_${Date.now()}.pdf`); //Download pdf in website
        } else {
            
          const blob = pdf.output("blob");   

          function saveFile() {
              var dir = cordova.file.externalRootDirectory + 'Download/';
              if(device.platform == "iOS") {
                var dir = cordova.file.documentsDirectory;
              }

              window.resolveLocalFileSystemURL(dir, function(dirEntry) {
                  dirEntry.getFile(`${pdfTitle.toLowerCase().split(' ').join('_')}_${Date.now()}.pdf`, {create: true, exclusive: false}, function(fileEntry) {
                    
                      writeFile(fileEntry, blob);

                      cordova.plugins.fileOpener2.open(
                        fileEntry?.nativeURL,
                        'application/pdf',
                        {
                            error : (error) => { 
                              console.log('Error status: ' + error.status + ' - Error message: ' + error.message);
                              if(error.status == 9) {
                                alert("Cannot preview this file type. Download pdf reader app from store.")
                              }
                            },
                            success : () => { console.log('file opened successfully') }
                        }
                      );                      
                  });
              });
          }
            
          function writeFile(fileEntry, dataObj) {
              fileEntry.createWriter(function(fileWriter) {
                  fileWriter.write(dataObj);
                  console.log("File saved to downloads!");
              });
          }

          saveFile();
        }
}