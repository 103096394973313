import React, { useContext, useState } from 'react'
import axios from "axios";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import { GlobalContext } from '../contexts/GlobalContext'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.css'

registerPlugin(FilePondPluginFileRename);
registerPlugin(FilePondPluginFileValidateType);

function Void(props) {
    const { url, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken, portal  } = useContext(GlobalContext);
    
    const [voided, setVoided] = useState(props.voided)
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [loading, setLoading] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleOpen2 = () => {
        setOpen2(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleClose2 = () => {
        setOpen2(false);
    }

    const processVoid = (event) => {
        event.preventDefault();
        setLoading(true);
        var endpoint = url + 'api/vouchers/void';
        if(props.type == "entry") {
            endpoint = url + 'api/entries/void';
        }
        axios.post( endpoint, {
            customer: customer,
            contact: contact,
            phone: phone,
            id: props.id,
            creator: props.creator ? props.creator : null,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            console.log("response", response);
            if(response.data.error == 0) {
                setLoading(false);
                setVoided(1);
                handleClose();
                props?.refetch && props.refetch();
            }
        })
        .catch(function (error) {
            setLoading(false);
            console.log(error);
            alert(error?.message);
            handleClose();
        });
    }

    const processUnvoid = (event) => {
        event.preventDefault();
        setLoading(true);
        var endpoint = url + 'api/vouchers/unvoid';
        if(props.type == "entry") {
            endpoint = url + 'api/entries/unvoid';
        }
        axios.post( endpoint, {
            customer: customer,
            contact: contact,
            phone: phone,
            id: props.id,
            creator: props.creator ? props.creator : null,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            console.log("response", response);
            if(response.data.error == 0) {
                setLoading(false);
                setVoided(0);
                handleClose2();
                props?.refetch && props.refetch();
            }
        })
        .catch(function (error) {
            setLoading(false);
            console.log(error);
            alert(error?.message);
            handleClose2();
        });
    }

    const handleVoid = () => {
        setOpen(true);
    };

    const handleUnvoid = () => {
        setOpen2(true);
    }; 

    return (
        <>
            { voided == 0 ?
                <Button sx={props?.styleObj ? props.styleObj : {}} variant="contained"   onClick={handleVoid} fullWidth>Void</Button>
                :
                <Button sx={props?.styleObj ? props.styleObj : {}} variant="contained"   onClick={handleUnvoid} fullWidth>Unvoid</Button>
            }

            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                    <DialogContentText>Do you really want to mark as void?</DialogContentText>
                    <form onSubmit={processVoid}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, mt: 2 }}>
                            <Button variant="contained" size="medium" onClick={handleClose} fullWidth>Cancel</Button>
                            <LoadingButton type="submit" loading={loading} variant="contained" fullWidth>Void</LoadingButton>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>

            <Dialog onClose={handleClose2} open={open2}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent sx={{ width: '80vw', maxWidth: '500px' }}>
                    <DialogContentText>Do you really want to unvoid this entry?</DialogContentText>
                    <form onSubmit={processUnvoid}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, mt: 2 }}>
                            <Button variant="contained" size="medium" onClick={handleClose2} fullWidth>Cancel</Button>
                            <LoadingButton type="submit" loading={loading} variant="contained" fullWidth>Unvoid</LoadingButton>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default Void;