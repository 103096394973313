import React, { useContext, useEffect, useState } from 'react'
import axios from "axios";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Fab from '@mui/material/Fab';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { IconPlus } from '@tabler/icons-react';
import { GlobalContext } from '../contexts/GlobalContext'
import 'filepond/dist/filepond.css'
import { useNavigate, useLocation } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
import { format } from 'date-fns';
import heic2any from 'heic2any';

registerPlugin(FilePondPluginFileRename)
registerPlugin(FilePondPluginFileValidateType);

function UploadButton(props) {

    const { url, phone, contact, customer, token, ledgers, portal, masters } = useContext(GlobalContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [date, setDate] = useState("")
    const [ledger, setLedger] = useState("")
    const [files, setFiles] = useState([])
    const [channel, setChannel] = useState("")
    const [remarks, setRemarks] = useState("")
    const [amount, setAmount] = useState(0)
    const [pond, setPond] = useState('')
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [fileList, setFileList] = useState([])
    const [fileValidation, setFileValidation] = useState(true)

    const [info, setInfo] = useState(null);

    const [openAlert, setOpenAlert] = useState(false);

    const [fetchedCustomers, setfetchedCustomers] = useState([]);

    const handleAlertOpen = () => {
        setOpenAlert(true)
    }

    const handleAlertClose = () => {
        setOpenAlert(false)
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setLedger("");
        setDate("");
        setChannel("");
        setAmount(0);
        setRemarks("");
        setFiles([]);
        setLoading(false);
        setInfo(null);
        setFileList([]);
        setfetchedCustomers([]);
    };


    function fetchCustomers() {
        axios.post(url + 'api/linkedcustomers', {
            customer: customer,
            portal: portal
        }, { headers: { Authorization: `Bearer ${token}` } })
            .then(function (response) {
                // console.log("response", response);
                console.log("Customer details fetched successfully")
                setfetchedCustomers(response?.data)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    useEffect(() => { if (open) { fetchCustomers() } }, [open]);

    const handleCamera = () => {
        setFileValidation(false);
        navigator.camera.getPicture(function (imageData) {
            // console.log(imageData);
            // console.log(pond);
            pond.addFile("data:image/jpg;base64," + imageData)
                .then((file) => {
                    // console.log("file", file);
                    setFiles(prevFiles => [...prevFiles, file]);
                    setFileValidation(true);
                }).catch((error) => {
                    // Something went wrong
                    setFileValidation(true);
                });
        }, function (message) {
            alert(message);
            setFileValidation(true);
        }, {
            quality: 50,
            destinationType: Camera.DestinationType.DATA_URL,
            encodingType: Camera.EncodingType.JPEG,
            mediaType: Camera.MediaType.PICTURE,
            correctOrientation: true
        });
    };

    const handleFile = () => {
        pond.browse();
    };

    const handleScanner = () => {
        window.scan.scanDoc(function (imageData) {
            pond.addFile("data:image/jpg;base64," + imageData)
                .then((file) => {
                    // console.log("file", file);
                }).catch((error) => {
                    // Something went wrong
                });
        }, function (message) {
            alert(message);
        }, { sourceType: 1, quality: 0.5, returnBase64: true })
    };

    const fileRenameFunction = (file) => {
        if (file.extension == file.name) {
            return `f_${Math.random().toString(36).slice(2, 7)}_${(+new Date).toString(36).slice(-5)}.jpg`;
        } else {
            return `f_${Math.random().toString(36).slice(2, 7)}_${(+new Date).toString(36).slice(-5)}${file.extension}`;
        }
    };

    console.log({ ledger });

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        // console.log("files", files);
        // var fileList = [];
        // files?.forEach(function(f) {
        //     fileList.push({ file: f.filename, type: f.fileType, ext: f.fileExtension } );
        // })

        if ((fileList.length == 0) && !remarks) {
            handleAlertOpen();
            // alert("Either enter remarks \n ( or ) \n Add one or more attachment to create entry");
            setLoading(false);
            return;
        }

        if (props.buyer == undefined || props.seller == undefined) {
            if (portal == "supplier") {
                var seller = customer;
                var buyer = props.cardTitle == "Bulk Invoice Upload" ? 1 : ledger?.id;
            } else {
                var seller = !!props?.bulkPayment ? 1 : ledger?.id;
                var buyer = customer;
            }
        }
        else {
            var seller = props.seller;
            var buyer = props.buyer;
        }

        const fileNames = files.map( file =>  file.filename);
        axios.post(url + 'api/vouchers/add', {
            customer: customer,
            contact: contact,
            phone: phone,
            creator: portal == "supplier" ? 2 : 3,
            seller: seller,
            buyer: buyer,
            date: date,
            channel: channel?.id,
            remarks: remarks,
            amount: amount,
            link: props.link ? props.link : null,
            temp_link: props.temp_link ? props.temp_link : null,
            link_with: props.link_with ? props.link_with : null,
            order_type: props?.order_type != undefined ? props.order_type : null,
            files: JSON.stringify(fileList.filter( f => fileNames.includes(f.file))),
            voucher_type: props.voucher_type ? props.voucher_type : null,
            created_in_areas: JSON.stringify(props.createdInAreas),
        }, { headers: { Authorization: `Bearer ${token}` } })
            .then(function (response) {
                // console.log({response})
                if (response?.data?.error == 0) {
                    setLoading(false)
                    // location.reload();
                    // navigate(0);
                    // navigate(location.pathname);
                    props?.refetch && props.refetch();
                    props?.reloadRequired && window.location.reload();
                    handleClose();
                } else {
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                alert("Something went wrong! contact Admin")
                handleClose();
            });
    };

    function CustomButton() {
        if (props.buttonType == "fab") {
            return (
                <Fab color="primary" aria-label="add" onClick={handleOpen} size={"medium"} sx={{
                    position: "fixed",
                    bottom: (theme) => theme.spacing(2),
                    right: (theme) => theme.spacing(2)
                }}>
                    <IconPlus />
                </Fab>
            )
        } else if (props.buttonType == "card") {
            return (
                <Card sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py: 4 }} onClick={handleOpen}>
                    {props.icon}
                    <Typography variant="body1" component="p" sx={{ textAlign: 'center' }}>{props.cardTitle || `New ${props.title}`}</Typography>
                </Card>
            )
        } else if (props.buttonType == "buttonFull") {
            return (
                <Button variant="contained" fullWidth onClick={handleOpen} >Upload {props.title}</Button>
            )
        } else {
            return (
                <Button variant="contained" fullWidth onClick={handleOpen} >Upload {props.title}</Button>
            )
        }
    }

    function convertHEICToJPG(file) {
        return heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.6
        });
    }

    function getExtension(fileName) {
        if (fileName.includes('.')) {
            // Split the fileName by dot and return the last part
            return '.' + fileName.split('.').pop();
        } else {
            // If there's no dot, return an empty string indicating no extension
            return '';
        }
    }

    useEffect(() => {

        if (props?.voucher_type === "orders" || props?.voucher_type === "invoices" || props?.voucher_type === "payments" || props?.voucher_type === "debitnotes" || props?.voucher_type === "creditnotes") {

            const fetchInfo = () => {
                axios.post(url + 'api/check/latest-entry', {
                    buyer: portal === "buyer" ? customer : ledger?.id,
                    seller: portal === "buyer" ? ledger?.id : customer,
                    entry_type: props?.voucher_type,
                }, { headers: { Authorization: `Bearer ${token}` } })
                    .then((response) => {
                        // console.log({response})
                        setInfo(response?.data)
                    })
                    .catch((error) => {
                        console.log({ error });
                        setInfo(null)
                    })
            }

            if (!!ledger?.id) {
                fetchInfo()
            } else {
                setInfo(null)
            }
        }

    }, [ledger]);

    const [errorMessage, setErrorMessage] = useState("");
    const [openErrorAlert, setOpenErrorAlert] = useState(false);

    const ErrorMessage = () => {

        function handleClose() {
            setOpenErrorAlert(false)
            setErrorMessage('')
        }

        return (
            <>
                <Snackbar open={openErrorAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                    onClose={handleClose}>
                    <Alert
                        onClose={handleClose}
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </>
        )
    }

    return (
        <div>
            <CustomButton></CustomButton>
            <Dialog onClose={handleClose} open={open}>

                <DialogTitle>Upload {props.title}</DialogTitle>
                <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>

                    <form onSubmit={handleSubmit}>

                        {(props.buyer == undefined || props.seller == undefined) && props.cardTitle != "Bulk Invoice Upload" && !props?.bulkPayment ?
                            <Box>
                                {(props.voucher_type == "debitnotes" && portal == "supplier") ? <Alert sx={{ mb: 2 }} severity="info">Uploaded on behalf of Buyer</Alert> : <></>}

                                <InputLabel shrink>{portal == "supplier" ? "Buyer" : "Supplier"}</InputLabel>
                                <Autocomplete sx={{ mb: 2 }}
                                    options={fetchedCustomers}
                                    getOptionLabel={(option) => option?.name || ''}
                                    value={ledger}
                                    onChange={(event, newValue) => {
                                        setLedger(newValue)
                                    }}
                                    isOptionEqualToValue={(option, value) => { if (!value) { return true } else { return value?.id == option?.id } }}
                                    renderInput={(params) => <TextField {...params} required={true} />}
                                />

                                {!!info && !!Object.keys(info).length &&
                                    <Alert icon={false} severity="info" sx={{ mb: 2 }}>
                                        <Typography variant="caption" component="p" sx={{ fontSize: "0.8em" }}> Last {props?.title?.toLowerCase()} entry for this {portal === "buyer" ? "supplier" : "buyer"} occurred on <span style={{ fontWeight: "bold" }}>{format(new Date(info?.created_at), 'dd MMM yyyy HH:mm:ss')}</span> by <span style={{ fontWeight: "bold" }}>{info?.creator_name || info?.creator_details?.name}</span>.</Typography>
                                    </Alert>
                                }

                            </Box> :
                            <Box>
                                {props?.seller_name && portal === "buyer" && <Alert icon={false} severity="info" sx={{ mb: 2 }}> <b>Supplier:</b> {props.seller_name}</Alert>}
                                {props?.buyer_name && portal === "supplier" && <Alert icon={false} severity="info" sx={{ mb: 2 }}><b>Buyer:</b> {props.buyer_name}</Alert>}
                            </Box>
                        }

                        {props.quick != true && props?.voucher_type !== "invoices" &&
                            <>
                                <InputLabel shrink>Date</InputLabel>
                                <TextField sx={{ mb: 2 }} fullWidth required={true} type="date"
                                    value={date}
                                    onChange={event => {
                                        const { value } = event.target;
                                        setDate(value);
                                    }} />

                                {props.voucher_type != "payments" ?
                                    <Box>
                                        <InputLabel shrink>Channel</InputLabel>
                                        <Autocomplete sx={{ mb: 2 }}
                                            options={masters.channels}
                                            getOptionLabel={(option) => option.name ? option.name : ''}
                                            value={channel}
                                            onChange={(event, newValue) => {
                                                setChannel(newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} required={true} />}
                                            isOptionEqualToValue={(option, value) => {
                                                if (!value) {
                                                    return true
                                                } else {
                                                    return option?.id == value?.id
                                                }
                                            }}
                                        />
                                    </Box>
                                    :
                                    <></>
                                }


                                {props.order_type != 4 ?
                                    <Box>
                                        <InputLabel shrink>Amount</InputLabel>
                                        <TextField sx={{ mb: 2 }} fullWidth type="number" step="any"
                                            value={amount}
                                            onChange={event => {
                                                const { value } = event.target;
                                                setAmount(value);
                                            }} />
                                    </Box>
                                    :
                                    <></>
                                }

                            </>
                        }

                        <InputLabel shrink>Remarks</InputLabel>
                        <TextField sx={{ mb: 2 }} fullWidth
                            value={remarks}
                            onChange={event => {
                                const { value } = event.target;
                                setRemarks(value);
                            }}
                            rows={4}
                            multiline={true}
                            required={false}
                        />

                        <InputLabel shrink>Attachments</InputLabel>
                        <Alert severity="info" sx={{ mb: 2 }}>
                            <Typography variant="body2" sx={{ fontSize: "12px" }}> Allowed File types are jpg, jpeg, heic, png, pdf, xls, xlsx, xlsm, xlsb, ods, csv </Typography>
                        </Alert>
                        <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: 'row', gap: 1, mb: 2 }}>
                            {window.cordova &&
                                <Button variant="contained" size="medium" onClick={handleCamera}>Camera</Button>
                            }
                            <Button variant="contained" size="medium" onClick={handleFile}>File</Button>
                            {/* { window.cordova &&
                                <Button variant="contained" size="medium" onClick={handleScanner}>Scan</Button>
                            } */}
                        </Box>
                        <FilePond
                            acceptedFileTypes={
                                ["application/pdf",
                                    "image/png",
                                    "image/jpg",
                                    "image/jpeg",
                                    "image/heic",
                                    'application/vnd.ms-excel',  // .xls
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
                                    'application/vnd.ms-excel.sheet.macroEnabled.12', // .xlsm
                                    'application/vnd.ms-excel.sheet.binary.macroEnabled.12', // .xlsb
                                    "text/csv", //.csv
                                    "application/vnd.oasis.opendocument.spreadsheet", //.ods
                                    ""
                                ]
                            }
                            ref={ref => { setPond(ref) }}
                            credits={false}
                            files={files}
                            onupdatefiles={setFiles}
                            fileRenameFunction={(File) => { return fileRenameFunction(File) }}
                            allowMultiple={true}
                            // server={ url + "api/pond/process"}
                            server={{
                                url: url,
                                revert: "./api/pond/process",
                                process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                                    console.log({ fieldName, file, files })
                                    const allowedFiles = [".jpg", ".jpeg", ".heic", ".png", ".pdf", ".xls", ".xlsx", ".xlsm", ".xlsb", ".ods", ".csv"]
                                    if (fileValidation) {
                                        if (!allowedFiles.includes(getExtension(file.name.toLowerCase()))) {
                                            error('Unkown file extension')
                                            setOpenErrorAlert(true)
                                            setErrorMessage('Unsupported file type. Please upload files with the allowed extensions. Allowed File types are jpg, jpeg, heic, png, pdf, xls, xlsx, xlsm, xlsb, ods, csv')
                                            abort()
                                        }
                                    } else {
                                        file.name = file.name + '.jpg';
                                    }
                                    try {
                                        let fn = file.name.toLowerCase()
                                        // Check if the file type is HEIC
                                        if (fn.indexOf('.heic') !== -1) {
                                            // Convert HEIC to JPG
                                            const convertedBlob = await convertHEICToJPG(file);
                                            // Create a new file object from the Blob
                                            const newFile = new File([convertedBlob], file.name.replace(/\.heic$/i, '.jpg'), {
                                                type: "image/jpeg",
                                            });
                                            file = newFile; // Update the file reference
                                        }

                                        // Now upload the file (either original or converted)
                                        // Example using fetch API
                                        const formData = new FormData();
                                        formData.append(fieldName, file, file.name);
                                        const response = await fetch(url + "api/pond/process", {
                                            method: 'POST',
                                            body: formData
                                        });

                                        if (response.ok) {
                                            const data = await response.json();
                                            load(data.name);
                                            let currentFile = { file: data.name, type: data.type, ext: data.ext };
                                            setFileList(currentFiles => [...currentFiles, currentFile]);
                                        } else {
                                            error('Could not upload file');
                                        }
                                    } catch (err) {
                                        error(err.message);
                                    }
                                }
                            }}
                            name="files"
                            labelIdle='No files selected'
                            onaddfile={(error, file) => {
                                if (!error) {
                                    setLoading(true)
                                }
                            }}
                            onprocessfiles={() => { setLoading(false) }}
                            // onprocessfilerevert={(file) => { setLoading(true) }}
                            // onremovefile={() => {
                            //     console.log("on remove file", {fileList})
                            //     setLoading(false); 
                            //     setOpenErrorAlert(false); 
                            //     setErrorMessage("")
                            // }} 
                            onerror={(error, file) => {
                                console.log({ error, file })
                                // const filesArray = files?.filter((item) => (item?.id !== file?.id));
                                // setFiles(filesArray)
                            }}
                        />

                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                            <LoadingButton type="submit" loading={loading} variant="contained">Save</LoadingButton>
                        </Box>
                    </form>

                    {openErrorAlert &&
                        <>
                            <ErrorMessage />
                        </>
                    }
                </DialogContent>

            </Dialog>
            {openAlert &&
                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ textAlign: "center" }}>
                    <Alert onClose={handleAlertClose} severity="error" variant="standard" sx={{ width: '100%' }} >
                        <Typography variant="body2">Either enter <b>Remarks</b> <Box sx={{ m: "5px 0" }}>(or)</Box> Add one or more <b>Attachment</b> to create entry!</Typography>
                    </Alert>
                </Snackbar>
            }

        </div>
    )
}
export default UploadButton;