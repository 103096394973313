import React, { useState, useRef, useEffect, useContext } from 'react'
import axios from "axios";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid'; // Grid version 1
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { MuiOtpInput } from 'mui-one-time-password-input'
import { GlobalContext } from '../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import LoadingComponent from '../components/LoadingComponent';

function Login() {
    console.log(process.env.REACT_APP_DEV_MODE);
    // useEffect(() => {
    //     versionCheck();
    // }, []);

    const navigate = useNavigate();
    const { url, ios_version, android_version, web_version, phone, loggedIn, setLoggedIn, setPhone, contact, setContact, setToken, setMasters, sessionToken, setSessionToken  } = useContext(GlobalContext);
    // const [phone, setPhone] = useState("");

    const VersionCheck = () => {
        const [update, setUpdate] = useState(false);
        const [loading, setLoading] = useState(true);
        
        axios.post(url + "api/version")
        .then(function (response) {
            console.log({response})
            if(window.cordova) {
                var platform = device.platform;
                if(platform == "iOS") {
                    if(!response.data.app.ios.includes(ios_version)) {
                        console.log("ios version mismatch");
                        setUpdate(true);
                    } else {
                        setUpdate(false);
                    }
                } else if(platform == "Android") {
                    if(!response.data.app.android.includes(android_version)) {
                        console.log("android version mismatch");
                        setUpdate(true);
                    } else {
                        setUpdate(false);
                    }
                }
            } else {
                if(!response.data.web.includes(web_version)) {
                    console.log("web version mismatch");
                    setUpdate(true);
                } else {
                    setUpdate(false);
                }
            }
            setLoading(false);
        })
        .catch(function (error) {
            console.log({error});
            setLoading(true);
        });

        return(
            <React.Fragment>
                <LoadingComponent loading={loading} />
                {
                    update ?
                    <UpdateNotice />
                    :
                    <Boot />
                }
            </React.Fragment>
        )
    }

    const Boot = () => {
        const { url, ios_version, android_version, web_version, phone, loggedIn, setLoggedIn, setPhone, setToken, sessionToken  } = useContext(GlobalContext);

        const [loading, setLoading] = useState(true);
        var platform = "Web";
        var version = web_version;
        if(window.cordova) {
            platform = device.platform;
            if(platform == "iOS") {
                version = ios_version;
            } else if(platform == "Android") {
                version = android_version;
            }
        }
        useEffect(() => {
            if(sessionToken) {
                if(loggedIn) {
                    axios.post(url + "api/boot", {
                        phone: phone,
                        platform: platform,
                        version: version,
                        session_token: sessionToken,
                    })
                    .then(function (response) {
                        console.log(response);
                        if(response.data.error === 1) {
                            setPhone(""); 
                            setToken(""); 
                            setLoggedIn("");
                        } else if(response.data.error === 0) {
                            setMasters(response.data.masters)
                        }
                        return navigate("/switcher");
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                } else {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        }, [ sessionToken ]);

        return(
            <React.Fragment>
                <LoadingComponent loading={loading} />
                {
                    !loading ?
                    <LoginContainer />
                    :
                    <React.Fragment />
                }
            </React.Fragment>
        )
    }

    const UpdateNotice = () => {
        return (
            <Box>
                <Typography variant="h4" mb={2} component="h4">Update required</Typography>
                <Typography variant="subtitle1" mb={3} component="p">An update will be available now or sooner in the respective app store.</Typography>
            </Box>
        );
    }

    const LoginContainer = () => {
        const [tempPhone, setTempPhone] = useState("");
        const [tempContact, setTempContact] = useState("");
        const [otp, setOtp] = useState("");
        const [error, setError] = useState(false);
        const [errorMessage, setErrorMessage] = useState("");
        const [validate, setValidate] = useState(false);
        const [loading, setLoading] = useState(false);

        const formAuth = (event) => {
            event.preventDefault();
            setLoading(true);
            axios.post(url + "api/auth", {
                phone: tempPhone,
            })
            .then(function (response) {
                console.log(response);
                if(response.data.error === 0) {
                    setTempContact(response.data.contact)
                    // contactRef.current = response.data.contact;
                    setValidate(true);
                    setError(false);
                    setErrorMessage("");
                } else {
                    setError(true);
                    setErrorMessage(response.data.message);
                }
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
        }
    
        const handleOtp = (newValue) => {
            setOtp(newValue)
        }
        
        const signup = (event) => {
            event.preventDefault();
            var signup_url = "https://rasiklalsons.com/sign-up/";
            if(window.cordova) {
                cordova.InAppBrowser.open(signup_url, '_system', 'location=yes');
            } else {
                window.open(signup_url, "_blank")
            }
        }
    
        const resendOtp = (event) => {
            event.preventDefault();
            setLoading(true);
            axios.post(url + "api/otp/resend", {
                contact: tempContact,
                phone: phone,
            })
            .then(function (response) {
                console.log(response);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
        }
    
        const formOtp = (event) => {
            event.preventDefault();
            setLoading(true);

            var platform = "Web";
            var app_version = web_version;
            var device_ = '';
            var device_version = '';
            var device_uuid = '';

            if(window.cordova) {
                platform = device.platform;
                device_ = device.model;
                device_uuid = device.uuid;
                device_version = device.version;
                if(platform == "iOS") {
                    app_version = ios_version;
                } else if(platform == "Android") {
                    app_version = android_version;
                }
            }

            axios.post(url + "api/otp/validate", {
                contact: tempContact,
                phone: tempPhone,
                platform: platform,
                app_version: app_version,
                device: device_,
                device_version: device_version,
                device_uuid: device_uuid,
                otp: otp
            })
            .then(function (response) {
                console.log(response);
                if(response.data.error === 0) {
                    setError(false);
                    setErrorMessage("");
                    setLoggedIn(true);
                    setPhone(tempPhone);
                    setContact(tempContact);
                    setToken(response.data.token);
                    setSessionToken(response.data.session_token);
                    setMasters(response.data.masters)
                    // setValidate(false);
                    setLoading(false);
                    return navigate("/switcher");
                } else {
                    setLoading(false);
                    setError(true);
                    setErrorMessage(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    
        const Error = () => {
            if(error) {
                return (
                        <Box mb={3}>
                            <Alert variant="filled" severity="error">
                                <AlertTitle>Error</AlertTitle>
                                {errorMessage}
                            </Alert>
                        </Box>
                )
            }
        }

        return (
            <Grid item xs={9} md={4}>
                <LoadingComponent loading={loading} />
                <Typography variant="h4" mb={3} component="h4">Login</Typography>
                <Error></Error>
                {  !validate ? 

                    <form onSubmit={formAuth}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                            <Typography variant="subtitle1" component="p">Enter you registered phone number to get started.</Typography>
                            <TextField InputProps={{ type: "tel", pattern:"[0-9]*" }} id="outlined-basic" label="Phone" variant="outlined" value={tempPhone} onChange={e => setTempPhone(e.target.value)} />
                            <Grid container>
                                <Grid item xs={6} md={4} sx={{ px: 1 }}>
                                    <Button variant="outlined" fullWidth onClick={(event) => signup(event) } size="large">Signup</Button>
                                </Grid>
                                <Grid item xs={6} md={4} sx={{ px: 1 }}>
                                    <Button type="submit" fullWidth variant="contained" size="large">Proceed</Button>
                                </Grid>
                            </Grid>
                            
                        </Box>
                    </form>
                    :
                    <form onSubmit={formOtp}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                            <Typography variant="subtitle1" component="p">Enter the OTP we have sent to {phone}.</Typography>
                            <MuiOtpInput TextFieldsProps={{ type: "tel", pattern:"[0-9]*" }} id="outlined-basic" variant="outlined" value={otp} onChange={handleOtp} />
                            <Grid container>
                                <Grid item xs={6} md={4} sx={{ px: 1 }}>
                                    <Button variant="outlined" fullWidth onClick={(event) => resendOtp(event) } size="large">Resend</Button>
                                </Grid>
                                <Grid item xs={6} md={4} sx={{ px: 1 }}>
                                    <Button type="submit" fullWidth variant="contained" size="large">Proceed</Button>
                                </Grid>
                            </Grid>        
                        </Box>
                    </form>
                }
            </Grid>
        )
    }

    return (
        <Grid container spacing={0} direction="row"justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
            <VersionCheck />
        </Grid>
      );
}

export default Login;
