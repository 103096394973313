import React, { useContext, useState } from 'react'
import axios from "axios";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { GlobalContext } from '../contexts/GlobalContext'
import { useNavigate } from 'react-router-dom';
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.css'
import heic2any from 'heic2any';
import { Snackbar, Typography } from '@mui/material';

registerPlugin(FilePondPluginFileRename);
registerPlugin(FilePondPluginFileValidateType);

function DebitNoteActions(props) {
    const { url, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken, portal  } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [files, setFiles] = useState([])
    const [pond, setPond] = useState('')
    const [reason, setReason] = useState("")
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [fileValidation, setFileValidation] = useState(true)

    const handleOpen = () => {
        setOpen(true);
    }

    const handleOpen2 = () => {
        setOpen2(true);
    }

    const handleClose = () => {
        setOpen(false);
        setReason("")
    }

    const handleClose2 = () => {
        setOpen2(false);
        setFileList([]);
        setFiles([])
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        axios.post( url + 'api/suppliers/checkin', {
            customer: customer,
            contact: contact,
            phone: phone,
            date: date,
            id: props.id,
            creator: props.creator ? props.creator : null,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            console.log("response", response);
            if(response.data.error == 0) {
                setLoading(false);
                props?.refetch ? props.refetch() : window.location.reload();
            }
        })
        .catch(function (error) {
            setLoading(false);
            console.log(error);
        });
    }

    const submitApproval = (event) => {
        event.preventDefault();
        setLoading(true);
        // var fileList = [];
        // files.forEach(function(f) {
        //     fileList.push({ file: f.filename, type: f.fileType, ext: f.fileExtension } );
        // })
        if(fileList.length == 0) {
            alert("Add one or more attachment to create entry");
        } else {
            const fileNames = files.map( file =>  file.filename);
            axios.post( url + 'api/suppliers/returns/approval', {
                customer: customer,
                contact: contact,
                phone: phone,
                id: props.id,
                files: JSON.stringify(fileList.filter( f => fileNames.includes(f.file))),
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                if(response.data.error == 0) {
                    setOpen(false);
                    setLoading(false)
                    // navigate(0);
                    props?.refetch ? props.refetch() : document.location.reload();
                } else {
                    setOpen(false);
                    setLoading(false);
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                setOpen(false);
                setLoading(false);
            });
        }
    }
    
    const submitRejection = (event) => {
        event.preventDefault();
        setLoading(true);
        axios.post( url + 'api/suppliers/returns/rejection', {
            customer: customer,
            contact: contact,
            phone: phone,
            id: props.id,
            approval: 2,
            reason: reason,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            if(response.data.error == 0) {
                setOpen(false);
                setLoading(false);
                props?.refetch && props.refetch()
                // location.reload();
            } else {
                setOpen(false);
                setLoading(false);
                alert(response.data.message);
            }
        })
        .catch(function (error) {
            console.log(error);
            setOpen(false);
            setLoading(false);
        });
    }

    const handleRejection = () => {
        setOpen(true);
    };

    const handleApproval = () => {
        setOpen2(true);
    };

    const handleCamera = () => {
        setFileValidation(false);
        navigator.camera.getPicture(function (imageData) {
            // console.log(imageData);
            // console.log(pond);
            pond.addFile("data:image/jpg;base64," + imageData)
            .then((file) => {
                setFiles(prevFiles => [...prevFiles, file]);
                setFileValidation(true);
                // console.log("file", file);
            }).catch((error) => {
                setFileValidation(true);
                // Something went wrong
            });
        }, function (message) {
            setFileValidation(true);
            alert(message);
        }, {
            quality: 50,
            destinationType: Camera.DestinationType.DATA_URL,
            encodingType: Camera.EncodingType.JPEG,
            mediaType: Camera.MediaType.PICTURE,
            correctOrientation: true
        });
    };    
    
    const handleFile = () => {
        pond.browse();
    };

    const handleScanner = () => {
        window.scan.scanDoc(function (imageData) {
            pond.addFile("data:image/jpg;base64," + imageData)
            .then((file) => {
                // console.log("file", file);
            }).catch((error) => {
                // Something went wrong
            });
        }, function (message) {
            alert(message);
        }, { sourceType: 1, quality: 0.5, returnBase64: true })
    };

    const fileRenameFunction = (file) => {
        console.log("file", file);
        if(file.extension == file.name) {
            return `f_${Math.random().toString(36).slice(2, 7)}_${(+new Date).toString(36).slice(-5)}.jpg`;
        } else {
            return `f_${Math.random().toString(36).slice(2, 7)}_${(+new Date).toString(36).slice(-5)}${file.extension}`;
        }
    };

    function convertHEICToJPG(file) {
        return heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.6
        });
    }

    function getExtension(fileName) {
        if (fileName.includes('.')) {
            // Split the fileName by dot and return the last part
            return '.' + fileName.split('.').pop();
        } else {
            // If there's no dot, return an empty string indicating no extension
            return '';
        }
    }

    const [errorMessage, setErrorMessage] = useState("");
    const [openErrorAlert, setOpenErrorAlert] = useState(false);    

    const ErrorMessage = () => {

        function handleClose(){
            setOpenErrorAlert(false)
            setErrorMessage('')
        }

        return(
            <>
            <Snackbar open={openErrorAlert}  anchorOrigin={{vertical: 'top', horizontal: 'center',}} 
                onClose={handleClose}>
            <Alert
                onClose={handleClose}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
            >
                {errorMessage}
            </Alert>
            </Snackbar>
            </>
        )
    }

    return (
        <div>
            { portal == "supplier" && props.approval == 0 &&
                <Grid container>
                    <Grid item xs={6} sx={{  paddingLeft: 1, paddingRight: 0.5, py:1 }}>
                        <Button variant="contained"  color="success" onClick={handleApproval} fullWidth>Accept</Button>
                    </Grid>
                    <Grid item xs={6} sx={{  paddingLeft: 0.5, paddingRight: 1, py:1 }}>
                        <Button variant="contained" color="error" onClick={handleRejection} fullWidth>Reject</Button>
                    </Grid>
                </Grid>
            }

            { props.approval == 1 &&
                <Box sx={{my:1}}>
                    <Alert sx={{ textAlign: "center", justifyContent: "center" }} severity="success">Debit Note Accepted</Alert>
                </Box>
            }

            { props.approval == 2 &&
                
                <Alert sx={{ textAlign: "center", justifyContent: "center" }} severity="error">Debit Note Rejected</Alert>
            }

            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>Reason</DialogTitle>
                <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                    <DialogContentText sx={{marginBottom: "5px"}}>
                        To reject this debit note, please enter the reason for rejection.
                    </DialogContentText>
                    <form onSubmit={submitRejection}>
                        {/* <InputLabel shrink>Reason</InputLabel> */}
                        <TextField sx={{ mb: 2 }} fullWidth required={true} 
                            value={reason}
                            onChange={event => {
                                const { value } = event.target;
                                setReason(value);
                            }} />
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                            <LoadingButton type="submit" loading={loading} variant="contained">Reject</LoadingButton>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>

            <Dialog onClose={handleClose2} open={open2}>
                <DialogTitle>Accept by Credit Note</DialogTitle>
                <DialogContent sx={{ width: '80vw', maxWidth: '500px' }}>
                    <Alert sx={{ textAlign: "left", justifyContent: "center", mb: 2 }} severity="info">A credit note is required to accept this debit note.</Alert>
                    <form onSubmit={submitApproval}>
                        <InputLabel shrink>Attachments</InputLabel>
                        <Alert severity="info" sx={{mb:2}}>
                            <Typography variant="body2" sx={{fontSize: "12px"}}> Allowed File types are jpg, jpeg, heic, png, pdf, xls, xlsx, xlsm, xlsb, ods, csv </Typography>
                        </Alert>
                        <Box sx={{ display: 'flex', justifyContent:"center", alignItems:"center", flexDirection: 'row', gap: 1, mb: 2 }}>
                            { window.cordova &&
                                <Button variant="contained" size="medium" onClick={handleCamera}>Camera</Button>
                            }
                            <Button variant="contained" size="medium" onClick={handleFile}>File</Button>
                            {/* { window.cordova &&
                                <Button variant="contained" size="medium" onClick={handleScanner}>Scan</Button>
                            } */}
                        </Box>
                        <FilePond
                            acceptedFileTypes={
                                ["application/pdf",
                                 "image/png", 
                                 "image/jpg", 
                                 "image/jpeg", 
                                 "image/heic", 
                                 'application/vnd.ms-excel',  // .xls
                                 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
                                 'application/vnd.ms-excel.sheet.macroEnabled.12', // .xlsm
                                 'application/vnd.ms-excel.sheet.binary.macroEnabled.12', // .xlsb
                                 "text/csv", //.csv
                                 "application/vnd.oasis.opendocument.spreadsheet", //.ods
                                  ""
                                ]
                            }
                            ref={ref => ( setPond(ref) )}
                            credits={false}
                            files={files}
                            onupdatefiles={setFiles}
                            fileRenameFunction={ file => ( fileRenameFunction(file) )}
                            allowMultiple={true}
                            
                            // server={ url + "api/pond/process"}
                            server={{
                                url: url,
                                revert: "./api/pond/process",
                                process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                                    const allowedFiles = [".jpg", ".jpeg", ".heic", ".png", ".pdf", ".xls", ".xlsx", ".xlsm", ".xlsb", ".ods", ".csv" ]  
                                    if (fileValidation) {
                                        if(!allowedFiles.includes(getExtension(file.name.toLowerCase()))){
                                            error('Unkown file extension')
                                            setOpenErrorAlert(true)
                                            setErrorMessage('Unsupported file type. Please upload files with the allowed extensions. Allowed File types are jpg, jpeg, heic, png, pdf, xls, xlsx, xlsm, xlsb, ods, csv')
                                            abort()
                                        }
                                    } else {
                                        file.name = file.name + '.jpg';
                                    }
                                    try {
                                        let fn = file.name.toLowerCase()
                                        // Check if the file type is HEIC
                                        if (fn.indexOf('.heic') !== -1) {
                                            // Convert HEIC to JPG
                                            const convertedBlob = await convertHEICToJPG(file);
                                            // Create a new file object from the Blob
                                            const newFile = new File([convertedBlob], file.name.replace(/\.heic$/i, '.jpg'), {
                                                type: "image/jpeg",
                                            });
                                            file = newFile; // Update the file reference
                                        }
                        
                                        // Now upload the file (either original or converted)
                                        // Example using fetch API
                                        const formData = new FormData();
                                        formData.append(fieldName, file, file.name);
                                        const response = await fetch(url + "api/pond/process", {
                                            method: 'POST',
                                            body: formData
                                        });
                        
                                        if (response.ok) {
                                            const data = await response.json();
                                            load(data.name);
                                            let currentFile = { file: data.name, type: data.type, ext: data.ext };
                                            setFileList(currentFiles => [...currentFiles, currentFile]);
                                        } else {
                                            error('Could not upload file');
                                        }
                                    } catch (err) {
                                        error(err.message);
                                    }}
                                
                            }}
                            name="files" /* sets the file input name, it's filepond by default */
                            labelIdle='No files selected'
                            onaddfile={(error, file) => { 
                                if(!error){
                                    setLoading(true)
                                }
                            }}  
                            onprocessfiles={() => setLoading(false)}   
                          //  onprocessfilerevert={() => {setLoading(true)}}   
                            // onremovefile={() => {
                            //     setLoading(false); 
                            //     setOpenErrorAlert(false); 
                            //     setErrorMessage("")
                            // }} 
                            
                            onerror={(error, file) => {
                                console.log({error, file})
                                // const filesArray = files?.filter((item) => (item?.id !== file?.id));
                                // setFiles(filesArray)
                            }}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                            <LoadingButton type="submit" loading={loading} variant="contained">Upload Credit Note</LoadingButton>
                        </Box>
                    </form>
                    {openErrorAlert &&
                        <>
                        <ErrorMessage />
                        </>
                    } 
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default DebitNoteActions;