import { Alert, AlertTitle, Button, Chip, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import BackToTop from "../../../components/BackToTop";
import CheckinButton from "../../../components/CheckinButton";
import ErrorMessage from "../../../components/ErrorMessage";
import Layout from "../../../components/Layout";
import LoadingComponent from "../../../components/LoadingComponent";
import MediaGrid from "../../../components/MediaGrid";
import ScrollComponent from "../../../components/ScrollComponent";
import UploadButton from "../../../components/VoucherUploader";
import { FilterDataContext } from "../../../contexts/FilteredDataContext";
import { GlobalContext } from "../../../contexts/GlobalContext";



export default function FilteredCheckIns(){

    const navigate = useNavigate();

    const { filterDataBuyer, filterDataSupplier, filterDataFromDate,  filterDataToDate,  filterDataStatus, } = useContext(FilterDataContext);

    const { url, customer, token, portal  } = useContext(GlobalContext);

    const title = "Filtered Check-Ins";

    const fetcher = async (page) => {        
        const response = await axios.post( url + 'api/visits?page=' + page, {
            buyer: portal === "buyer" ? customer : filterDataBuyer?.id,
            supplier: portal === "supplier" ? customer : filterDataSupplier?.id,
            from_date: filterDataFromDate,
            to_date: filterDataToDate,
            status: filterDataStatus==="allData" ? "" : filterDataStatus.toString(),
        }, { headers: { Authorization: `Bearer ${token}` }})

        return response.data
    } 
        
    const { isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery( 
        'filteredCheckins',  
        ({ pageParam = 1 }) => fetcher(pageParam),
        {
          getNextPageParam: (lastPageData) => { 
            if(lastPageData?.current_page < lastPageData.last_page){
                return lastPageData?.current_page + 1
            }else{
                return undefined
            } 
          },
          refetchOnWindowFocus: false,
        }
    );

    const currentTab = { 
        name: "Checkins", 
        status: { 0: "Upcoming", 1: "Checked-in", 2: "Ordered", 3: "Not Ordered", 4: "Not Visited", 99: "All Data",}
    };

    // console.log({filterDataBuyer, filterDataSupplier, filterDataFromDate,  filterDataToDate,  filterDataStatus,})

    const Item = ({data}) => {
        
        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>

                { filterDataStatus === "allData" && data?.status_label && 
                <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}> <Chip  label={data.status_label}  /> </Box>
                }

                <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                    <Typography variant="overline" component="p">{  portal == "supplier" ?  "Buyer" : "Supplier" }</Typography>
                    <Typography variant="h6" component="h6">{  portal == "supplier" ? data.buyer_name : data.seller_name }, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{data?.city}</span></Typography>
                    {
                        portal === "buyer" && !!data?.supplier_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.supplier_msme} /></Box>
                    }
                    {
                        portal === "supplier" && !!data?.buyer_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.buyer_msme} /></Box>
                    }
                </Box>
                <Grid container>
                    <Grid item xs={12} sx={{borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">{ (data?.status_label === "Upcoming" || data?.status_label === "Checked-In" || data?.status == 1 || data?.status == 2) ? "Plan Date" : "Date"}</Typography>
                        <Typography variant="body" component="p">{data.date ? format(parse(data.date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd MMM yyyy HH:mm:ss') : '-'}</Typography>
                    </Grid>
                    {(data?.status_label === "Checked-In" || data?.status == 2) &&
                        <Grid item xs={12} sx={{borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Check-in Date</Typography>
                            <Typography variant="body" component="p">{data?.checkin_on ? format(new Date(data?.checkin_on), 'dd MMM yyyy HH:mm:ss') :"-"}</Typography>
                        </Grid>
                    }
                    <Grid item xs={12} sx={{borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">{filterDataStatus == 2 ? "Check-in Remarks" : "Remarks"}</Typography>
                        <Typography variant="body" component="p">{ data.remarks ? data.remarks : "-" }</Typography>
                    </Grid>
                    {
                        data?.order_details !== null ? 
                            <>
                            {
                                    data.order_details?.remarks ? 
                                    <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                                        <Typography variant="overline" component="p">Order Remarks</Typography>
                                        <Typography variant="body" component="p">{ data.order_details.remarks }</Typography>
                                    </Grid> : <></>
                            }
                            <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                                <MediaGrid dir="orders" images={data?.order_details.images} />
                            </Grid>
                            <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                                {data?.order_details?.show_button ?
                                    <Button variant="contained" fullWidth  
                                    onClick={() => { navigate("/order/" + data?.order_details.id) }}>View Order</Button>
                                    :
                                    <Alert severity="warning">This order data is being processed</Alert>
                                }
                            </Grid>
                            </> 
                        : 
                            <>
                            {
                                data?.entry_details !== null ? 
                                <>
                                {
                                    data.entry_details?.remarks ? 
                                    <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                                        <Typography variant="overline" component="p">Order Remarks</Typography>
                                        <Typography variant="body" component="p">{ data.entry_details.remarks }</Typography>
                                    </Grid> : <></>
                                }
                                <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                                    <MediaGrid dir="orders" images={data?.entry_details.images} />
                                </Grid> 
                                <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                                    <Alert severity="warning">This order data is being processed</Alert> 
                                </Grid>
                                </>
                                : 
                                <></> 
                            }
                            </>
                    }
                </Grid>
                {(data.status == 1 || data.status == 2) &&
                    <Box sx={{ borderTop: 1, borderColor: 'divider',  display: 'flex', flexDirection: 'column', gap: 3, p: 1 }}>
                        <ItemAction itemStatus={data.status} id={data.id} order={data.order_id} buyer={data.buyer} seller={data.seller}/>
                    </Box>
                }
            </Paper>
        )
    }

    function ItemAction ({itemStatus, id, order, buyer, seller}) {

        if(portal == "supplier") {
            if(itemStatus == 1) {
                return (<CheckinButton creator="2" id={id} refetch={refetch}  />);
            } else if (itemStatus == 2) {
                return (<UploadButton buttonType="button" title="Order" seller={customer} buyer={buyer} link={id} voucher_type="orders" refetch={refetch} />);
            }
        } else {
            if(itemStatus == 1) {
                return (<CheckinButton creator="3" id={id} refetch={refetch} />);
            } else if (itemStatus == 2) {
                return (<UploadButton buttonType="button" title="Order" seller={seller} buyer={customer} link={id} voucher_type="orders" refetch={refetch} />);
            }
        }
    }

    let isToday = false;

    if(filterDataFromDate && filterDataToDate){
        const isSameDay = format(new Date(filterDataFromDate), 'dd/MM/yyyy') === format(new Date(filterDataToDate), 'dd/MM/yyyy');
        if(isSameDay){
            isToday = format(new Date(), 'dd/MM/yyyy') === format(new Date(filterDataFromDate), 'dd/MM/yyyy');
        }
    }

    if(!filterDataBuyer && !filterDataSupplier){
        navigate(-1, { replace: true })
        return null;
    }

    return(
        <>       
            {isError ? <ErrorMessage title={title} message={error?.message} /> :
                <>            
                {  isLoading ? 
                <LoadingComponent loading={isLoading} />
                : 
                <Layout title={title} backToTop={true}>                
                    <Box>
                        <Alert severity="success" >
                            <AlertTitle sx={{fontSize: "0.5 em"}}> Results {portal === "buyer" ? filterDataSupplier && `for ${(filterDataSupplier?.name?.toUpperCase())}` : filterDataBuyer && `for ${(filterDataBuyer?.name?.toUpperCase())}`}</AlertTitle>
                            {isToday ? `Today's Check-Ins (${format(new Date(filterDataFromDate), 'dd/MM/yyyy')})` : (filterDataFromDate && filterDataToDate) && 
                            ` From: ${format(new Date(filterDataFromDate), 'dd/MM/yyyy')} To: ${format(new Date(filterDataToDate), 'dd/MM/yyyy')}`
                            }    
                            <Box sx={{mt: 1}}>
                            { filterDataStatus == "allData" ? `Status: ${currentTab.status[99]}`: `Status: ${currentTab.status[filterDataStatus]}`}
                            </Box>
                        </Alert>                    
                    </Box>                
                    <ScrollComponent data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} Item={Item}  />
                    <BackToTop alone={true} />
                </Layout>
                }
                </>
            } 
        </>       
    )
}