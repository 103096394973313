
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import axios from "axios";

var sentryEnvironment = process.env.REACT_APP_DEV_MODE;
if ( sentryEnvironment !== "development" ) {
  Sentry.init({
  dsn: "https://403d2c31270b152dc3eb664f557fc27b@sentry.awp.dev/6",
  integrations: [
    new Sentry.BrowserTracing({
      _experiments: {
        enableInteractions: true,
      },
    }),
    new Sentry.Replay(),
  ],
  environment: sentryEnvironment,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    Sentry.captureException(error);
    return Promise.reject(error);
  },
);

const root = ReactDOM.createRoot(document.getElementById('root'));

if(!window.cordova) {
  root.render(
      <HashRouter>
        <App />
      </HashRouter>
  );
} else {
  document.addEventListener('deviceready', function() {
    root.render(
        <HashRouter>
          <App />
        </HashRouter>
    );
  }, false)
}
if(!window.cordova) {
  serviceWorkerRegistration.register();
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
