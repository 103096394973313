import React, { useContext, useState } from 'react'
import axios from "axios";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { GlobalContext } from '../contexts/GlobalContext'
import 'filepond/dist/filepond.css'
import { useNavigate } from 'react-router-dom';
import { format, parse } from 'date-fns'
import heic2any from 'heic2any';
import { Alert, Snackbar } from '@mui/material';

registerPlugin(FilePondPluginFileRename);
registerPlugin(FilePondPluginFileValidateType);

function DeliveryUploader(props) {
    const { url, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken, invoices, setInvoices, portal  } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [id, setId] = useState(props.id)
    const [date, setDate] = useState("")
    const [transport, setTransport] = useState("")
    const [lrNumber, setLrNumber] = useState("")
    const [notes, setNotes] = useState("")
    const [files, setFiles] = useState([])
    const [pond, setPond] = useState('');
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [fileList, setFileList] = useState([])
    const [fileValidation, setFileValidation] = useState(true)
    
    const handleOpen = () => {
        setOpen(true);
    };    
    const handleClose = () => {
        setOpen(false);
        setFileList([]);
        setFiles([]);
        setDate("");
        setTransport("");
        setLrNumber("");
        setNotes("");
        setPond("");
        setLoading(false);
    };    

    const handleCamera = () => {
        setFileValidation(false);
        navigator.camera.getPicture(function (imageData) {
            pond.addFile("data:image/jpg;base64," + imageData).then((file) => {
                setFiles(prevFiles => [...prevFiles, file]);
                setFileValidation(true);
                // console.log("file", file);
            }).catch((error) => {
                setFileValidation(true);
                // Something went wrong
            });
        }, function (message) {
            setFileValidation(true);
            alert(message);
        }, {
            quality: 50,
            destinationType: Camera.DestinationType.DATA_URL,
            encodingType: Camera.EncodingType.JPEG,
            mediaType: Camera.MediaType.PICTURE,
            correctOrientation: true
        });
    };    
    
    const handleFile = () => {
        pond.browse();
    };

    const handleScanner = () => {
        window.scan.scanDoc(function (imageData) {
            pond.addFile("data:image/jpg;base64," + imageData)
        }, function (message) {
            alert(message);
        }, { sourceType: 1, quality: 0.5, returnBase64: true })
    };

    const fileRenameFunction = (file) => {
        console.log("file", file);
        if(file.extension == file.name) {
            return `f_${Math.random().toString(36).slice(2, 7)}_${(+new Date).toString(36).slice(-5)}.jpg`;
        } else {
            return `f_${Math.random().toString(36).slice(2, 7)}_${(+new Date).toString(36).slice(-5)}${file.extension}`;
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        // var fileList = [];
        // files.forEach(function(f) {
        //     fileList.push({ file: f.filename, type: f.fileType, ext: f.fileExtension } );
        // })
        const fileNames = files.map( file =>  file.filename);
        axios.post( url + 'api/deliveries/add', {
            customer: customer,
            contact: contact,
            phone: phone,
            id: props.id  == undefined ? id.id : props.id,
            creator: portal == "supplier" ? 2 : 3,
            date: date,
            transport: transport,
            lr_number: lrNumber,
            notes: notes,
            created_in_areas : JSON.stringify(props.createdInAreas),
            files: JSON.stringify(fileList.filter( f => fileNames.includes(f.file))),
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            if(response.data.error == 0) {
                setOpen(false);
                setLoading(false);
                // location.reload();
                // navigate(0);
                handleClose()
                document.location.reload();
            } else {
                alert(response.data.message);
                handleClose();
            }
        })
        .catch(function (error) {
            setLoading(false);
            console.log(error);
            handleClose();
        });
    };

    function CustomButton() {
        if(props.buttonType=="card") {
            return (
                <Card sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py:4 }} onClick={handleOpen}>
                    { props.icon }
                    <Typography variant="body1" component="p" sx={{ textAlign: 'center'}}>New {props.title}</Typography>
                </Card>
            )
        } else {
            return(
                <Button variant="contained" fullWidth onClick={handleOpen} >Add Delivery</Button>
            )
        }
    }

    const [errorMessage, setErrorMessage] = useState("");
    const [openErrorAlert, setOpenErrorAlert] = useState(false);    

    const ErrorMessage = () => {

        function handleClose(){
            setOpenErrorAlert(false)
            setErrorMessage('')
        }

        return(
            <>
            <Snackbar open={openErrorAlert}  anchorOrigin={{vertical: 'top', horizontal: 'center',}} 
                onClose={handleClose}>
            <Alert
                onClose={handleClose}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
            >
                {errorMessage}
            </Alert>
            </Snackbar>
            </>
        )
    }

    function convertHEICToJPG(file) {
        return heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.6
        });
    }

    function getExtension(fileName) {
        if (fileName.includes('.')) {
            // Split the fileName by dot and return the last part
            return '.' + fileName.split('.').pop();
        } else {
            // If there's no dot, return an empty string indicating no extension
            return '';
        }
    }

    return (
        <div>
            <CustomButton></CustomButton>
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>Add Delivery</DialogTitle>
                <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                    <form onSubmit={handleSubmit}>
                        {
                            props.id  == undefined ?
                            <Box>
                                <InputLabel shrink>Invoice</InputLabel>
                                <Autocomplete sx={{ mb: 2 }}
                                    options={ invoices }
                                    getOptionLabel={(option) => option.voucher_no ? option.voucher_no + " " + (option.date ? "(" + format(parse(option.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') + ")" : '') : '' }
                                    value={id}
                                    onChange={(event, newValue) => {
                                        setId(newValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} required={true} />}
                                />
                            </Box>
                            :
                            <Box></Box>
                        }
                        <InputLabel shrink>Date</InputLabel>
                        <TextField sx={{ mb: 2 }} fullWidth required={true} type="date" 
                            value={date}
                            onChange={event => {
                                const { value } = event.target;
                                setDate(value);
                            }} />

                        <InputLabel shrink>Transport</InputLabel>
                        <TextField sx={{ mb: 2 }} fullWidth required={true}
                            value={transport}
                            onChange={event => {
                                const { value } = event.target;
                                setTransport(value);
                            }} />

                        <InputLabel shrink>LR Number</InputLabel>
                        <TextField sx={{ mb: 2 }} fullWidth required={true}
                            value={lrNumber}
                            onChange={event => {
                                const { value } = event.target;
                                setLrNumber(value);
                            }} />

                        <InputLabel shrink>Notes</InputLabel>
                        <TextField sx={{ mb: 2 }} fullWidth required={true} 
                            value={notes}
                            onChange={event => {
                                const { value } = event.target;
                                setNotes(value);
                            }} />
                            
                        <InputLabel shrink>Attachments</InputLabel>
                        <Alert severity="info" sx={{mb:2}}>
                            <Typography variant="body2" sx={{fontSize: "12px"}}> Allowed File types are jpg, jpeg, heic, png, pdf, xls, xlsx, xlsm, xlsb, ods, csv </Typography>
                        </Alert>
                        <Box sx={{ display: 'flex', justifyContent:"center", alignItems:"center", flexDirection: 'row', gap: 1, mb: 2 }}>
                            { window.cordova &&
                                <Button variant="contained" size="medium" onClick={handleCamera}>Camera</Button>
                            }
                            <Button variant="contained" size="medium" onClick={handleFile}>File</Button>
                            {/* { window.cordova &&
                                <Button variant="contained" size="medium" onClick={handleScanner}>Scan</Button>
                            } */}
                        </Box>
                        <FilePond
                            acceptedFileTypes={
                                ["application/pdf",
                                 "image/png", 
                                 "image/jpg", 
                                 "image/jpeg", 
                                 "image/heic", 
                                 'application/vnd.ms-excel',  // .xls
                                 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
                                 'application/vnd.ms-excel.sheet.macroEnabled.12', // .xlsm
                                 'application/vnd.ms-excel.sheet.binary.macroEnabled.12', // .xlsb
                                 "text/csv", //.csv
                                 "application/vnd.oasis.opendocument.spreadsheet", //.ods
                                  "" 
                                ]
                            }
                            ref={ref => ( setPond(ref) )}
                            credits={false}
                            files={files}
                            onupdatefiles={setFiles}
                            fileRenameFunction={ file => ( fileRenameFunction(file) )}
                            allowMultiple={true}
                            
                            // server={ url + "api/pond/process"}
                            server={{
                                url: url,
                                revert: "./api/pond/process",
                                process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                                    const allowedFiles = [".jpg", ".jpeg", ".heic", ".png", ".pdf", ".xls", ".xlsx", ".xlsm", ".xlsb", ".ods", ".csv" ]  
                                    if (fileValidation) {
                                        if(!allowedFiles.includes(getExtension(file.name.toLowerCase()))){
                                            error('Unkown file extension')
                                            setOpenErrorAlert(true)
                                            setErrorMessage('Unsupported file type. Please upload files with the allowed extensions. Allowed File types are jpg, jpeg, heic, png, pdf, xls, xlsx, xlsm, xlsb, ods, csv')
                                            abort()
                                        }
                                    } else {
                                        file.name = file.name + '.jpg';
                                    }
                                    try {
                                        let fn = file.name.toLowerCase()
                                        // Check if the file type is HEIC
                                        if (fn.indexOf('.heic') !== -1) {
                                            // Convert HEIC to JPG
                                            const convertedBlob = await convertHEICToJPG(file);
                                            // Create a new file object from the Blob
                                            const newFile = new File([convertedBlob], file.name.replace(/\.heic$/i, '.jpg'), {
                                                type: "image/jpeg",
                                            });
                                            file = newFile; // Update the file reference
                                        }
                        
                                        // Now upload the file (either original or converted)
                                        // Example using fetch API
                                        const formData = new FormData();
                                        formData.append(fieldName, file, file.name);
                                        const response = await fetch(url + "api/pond/process", {
                                            method: 'POST',
                                            body: formData
                                        });
                        
                                        if (response.ok) {
                                            const data = await response.json();
                                            load(data.name);
                                            let currentFile = { file: data.name, type: data.type, ext: data.ext };
                                            setFileList(currentFiles => [...currentFiles, currentFile]);
                                        } else {
                                            error('Could not upload file');
                                        }
                                    } catch (err) {
                                        error(err.message);
                                    }}
                                
                            }}
                            name="files" /* sets the file input name, it's filepond by default */
                            labelIdle='No files selected'
                            onaddfile={(error, file) => { 
                                if(!error){
                                    setLoading(true)
                                }
                            }}  
                            onprocessfiles={() => setLoading(false)}   
                          //  onprocessfilerevert={() => {setLoading(true)}}   
                            // onremovefile={() => {
                            //     setLoading(false); 
                            //     setOpenErrorAlert(false); 
                            //     setErrorMessage("")
                            // }} 
                            onerror={(error, file) => {
                                console.log({error, file})
                                // const filesArray = files?.filter((item) => (item?.id !== file?.id));
                                // setFiles(filesArray)
                            }} 
                        />

                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                            <LoadingButton type="submit" loading={loading} variant="contained">Save</LoadingButton>
                        </Box>
                    </form>
                    {openErrorAlert &&
                        <>
                        <ErrorMessage />
                        </>
                    }  
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default DeliveryUploader;