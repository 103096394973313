import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../../components/Layout';
import { GlobalContext } from '../../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import { IconUsers, IconTruck, IconRefresh, IconCoinRupee, IconTools, IconMessages, IconLogout, IconBuilding, IconMessage, 
    IconMapPin, IconBrandAppgallery, IconReceipt, IconLifebuoy, IconAd2, IconAddressBook, IconFileInvoice, IconArrowsExchange, IconReport, IconReportMoney } from '@tabler/icons-react';
import UploadButton from '../../components/VoucherUploader';
import DeliveryUploader from '../../components/DeliveryUploader';
import LoadingComponent from '../../components/LoadingComponent';

function SupplierDashboard() {
    const navigate = useNavigate();
    const { url, phone, setPhone, contact, customer, customerName, setCustomerName, token, setToken, setLoggedIn, multi, portal, setInvoices, sessionToken, setSessionToken  } = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const [catalog_access, setCatalogAccess] = useState(false);
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        setLoading(true);
        if(!!token){
            fetchData()
        }else(
            navigate("/")
        )
        console.log(loading);
        if(window.cordova) {
            window.cordova.plugins.firebase.analytics.setCurrentScreen("Supplier Dashboard");
        }
    }, []);

    const fetchData = () => {
        axios.post(url + "api/suppliers/boot", {
            customer: customer,
            contact: contact,
            phone: phone,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            console.log(response);
            if(response.data.error == 0) {
                setCatalogAccess(response.data.profile.catalog_access);
                setCustomerName(response.data.profile.customer_name);
                setInvoices(response.data.invoices);
                // setPermissions(response.data.permissions);
                var newPermisssions = [];
                response.data.permissions.map((row) => {
                    newPermisssions[row] = true;
                });
                setPermissions(newPermisssions);
            }
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const openChat = () => {
        axios.post(url + "api/getchannel", {
            customer: customer,
            contact: contact,
            phone: phone,
            creator: portal == "supplier" ? 2 : 3,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            console.log(response);
            navigate("/chat/channel/" + response.data.channel.name)
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const logoutSession = () => {
        axios.post(url + "api/logout", {
            customer: customer,
            contact: contact,
            phone: phone,
            creator: portal == "supplier" ? 2 : 3,
            session_token: sessionToken,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            setPhone(""); 
            setToken(""); 
            setLoggedIn(""); 
            setSessionToken("");
            return navigate("/")
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    return (
        <div>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title="Supplier Dashboard" hideNavigation={true}>
                <Grid container spacing={2} direction="row" justifyContent="start" alignItems="center" alignContent="center">
                    { permissions.profile &&
                        <Grid item xs={12}>
                            <Card onClick={event => { return navigate("/profile") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', px: 4, py:4 }}>
                                <IconBuilding></IconBuilding>
                                <Typography variant="body1" component="p">{customerName}</Typography>
                                <Typography variant="caption" component="p">Manage Profile</Typography>
                            </Card>
                        </Grid>
                    }
                    { multi &&
                    <Grid item xs={12}>
                        <Card onClick={event => { return navigate("/") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', px: 4, py:2 }}>
                            <IconRefresh></IconRefresh>
                            <Typography variant="body1" component="p">Switch Account</Typography>
                        </Card>
                    </Grid>
                    }
                    { permissions.checkins &&
                        <Grid item xs={6}>
                            <Card onClick={event => { return navigate("../checkins/0") }} sx={{backgroundColor: "#044B7F", color: "#fff", cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py:4 }}>
                                <IconMapPin></IconMapPin>
                                <Typography variant="body1" component="p" sx={{ textAlign: 'center'}}>Check-Ins</Typography>
                            </Card>
                        </Grid>
                    }
                    { permissions.orders &&
                    <Grid item xs={6}>
                        <Card onClick={event => { return navigate("../orders/0") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py:4 }}>
                            <IconBrandAppgallery></IconBrandAppgallery>
                            <Typography variant="body1" component="p" sx={{ textAlign: 'center'}}>Orders</Typography>
                        </Card>
                    </Grid>
                    }
                    { permissions.custom_orders &&
                    <Grid item xs={6}>
                        <Card onClick={event => { return navigate("../customorders/0") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py:4 }}>
                            <IconTools></IconTools>
                            <Typography variant="body1" component="p" sx={{ textAlign: 'center'}}>Custom Orders</Typography>
                        </Card>
                    </Grid>
                    }
                    { permissions.invoices &&
                    <Grid item xs={6}>
                        <Card onClick={event => { return navigate("../invoices/0") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py:4 }}>
                            <IconReceipt></IconReceipt>
                            <Typography variant="body1" component="p" sx={{ textAlign: 'center'}}>Invoices</Typography>
                        </Card>
                    </Grid>
                    }
                    { permissions.payments &&
                    <Grid item xs={6}>
                        <Card onClick={event => { return navigate("../payments/0") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py:4 }}>
                            <IconCoinRupee></IconCoinRupee>
                            <Typography variant="body1" component="p" sx={{ textAlign: 'center'}}>Payments</Typography>
                        </Card>
                    </Grid>
                    }
                    { permissions.creditnotes &&
                    <Grid item xs={6}>
                        <Card onClick={event => { return navigate("/creditnotes/0") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py:4 }}>
                            <IconReceipt></IconReceipt>
                            <Typography variant="body1" component="p" sx={{ textAlign: 'center'}}>Credit Notes</Typography>
                        </Card>
                    </Grid>
                    }
                    { permissions.debitnotes &&
                    <Grid item xs={6}>
                        <Card onClick={event => { return navigate("/debitnotes/0") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py:4 }}>
                            <IconReceipt></IconReceipt>
                            <Typography variant="body1" component="p" sx={{ textAlign: 'center'}}>Debit Notes</Typography>
                        </Card>
                    </Grid>
                    }
                    { permissions.tickets &&
                    <Grid item xs={6}>
                        <Card onClick={event => { return navigate("../tickets/0") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py:4 }}>
                            <IconLifebuoy></IconLifebuoy>
                            <Typography variant="body1" component="p" sx={{ textAlign: 'center'}}>Tickets</Typography>
                        </Card>
                    </Grid>
                    }
                    {  (permissions.catalog == true && catalog_access == true) &&
                        <Grid item xs={6}>
                            <Card onClick={()=> { navigate("/supplier/catalog") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py:4 }}>
                                <IconAd2></IconAd2>
                                <Typography variant="body1" component="p" sx={{ textAlign: 'center'}}>Catalog</Typography>
                            </Card>
                        </Grid>
                    }                    
                    { permissions.ledgers &&
                    <Grid item xs={6}>
                        <Card onClick={event => { return navigate("/ledgers") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py:4 }}>
                            <IconAddressBook></IconAddressBook>
                            <Typography variant="body1" component="p" sx={{ textAlign: 'center'}}>Buyers</Typography>
                        </Card>
                    </Grid>
                    }
                    {  window.cordova && permissions.chat &&
                        <Grid item xs={6}>
                            <Card onClick={event => { return navigate("/chat") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py:4 }}>
                                <IconMessages></IconMessages>
                                <Typography variant="body1" component="p" sx={{ textAlign: 'center'}}>Chat</Typography>
                            </Card>
                        </Grid>
                    }
                    {  window.cordova && permissions.rasiklal_chat &&
                    <Grid item xs={6}>
                        <Card onClick={event => { openChat() }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py:4 }}>
                            <IconMessage></IconMessage>
                            <Typography variant="body1" component="p" sx={{ textAlign: 'center'}}>Rasiklal Chat</Typography>
                        </Card>
                    </Grid>
                    }
                    <Grid item xs={6}>
                        <Card onClick={event => { navigate("/ledgerstatements") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py:4 }}>
                            <IconFileInvoice></IconFileInvoice>
                            <Typography variant="body1" component="p" sx={{ textAlign: 'center'}}>Ledger Statements</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card onClick={event => { navigate("/financial_statement") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py:4 }}>
                            <IconReportMoney></IconReportMoney>
                            <Typography variant="body1" component="p" sx={{ textAlign: 'center'}}>Financial Statement</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card onClick={event => { navigate("/othertransactions") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py:4 }}>
                            <IconArrowsExchange></IconArrowsExchange>
                            <Typography variant="body1" component="p" sx={{ textAlign: 'center'}}>Other Transactions</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card onClick={event => { navigate("/reportscategory") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py:4 }}>
                            <IconReport></IconReport>
                            <Typography variant="body1" component="p" sx={{ textAlign: 'center'}}>Reports</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card onClick={event => { logoutSession() }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py:4 }}>
                            <IconLogout></IconLogout>
                            <Typography variant="body1" component="p" sx={{ textAlign: 'center'}}>Logout</Typography>
                        </Card>
                    </Grid>
                </Grid>
                <Typography variant="h6" component="h6" sx={{ mt: 4, mb: 2 }}>Quick Actions</Typography>
                <Grid container spacing={2} direction="row" justifyContent="start" alignItems="center" alignContent="center">
                    { permissions.orders &&
                    <Grid item xs={6}>
                        <UploadButton 
                            buttonType="card" icon={<IconBrandAppgallery />} title="Order" voucher_type="orders" quick={true} 
                            createdInAreas={[ 
                                {label : portal == "supplier" ? "Supplier App" : "Buyer App"},
                                {label : "Quick Action Button"},
                            ]} 
                        />
                    </Grid>
                    }
                    { permissions.invoices &&
                    <React.Fragment>
                        <Grid item xs={6}>
                            <UploadButton 
                                buttonType="card" icon={<IconReceipt />} 
                                title="Invoice" voucher_type="invoices" quick={true} cardTitle={"Bulk Invoice Upload"}
                                createdInAreas={[ 
                                    {label : portal == "supplier" ? "Supplier App" : "Buyer App"},
                                    {label : "Quick Action Button"},
                                ]} 
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DeliveryUploader 
                                buttonType="card" icon={<IconReceipt />} title="Delivery" 
                                createdInAreas={[ 
                                    {label : portal == "supplier" ? "Supplier App" : "Buyer App"},
                                    {label : "Quick Action Button"},
                                ]} 
                            />
                        </Grid>
                    </React.Fragment>
                    }
                    { permissions.debitnotes &&
                    <Grid item xs={6}>
                        <UploadButton 
                            buttonType="card" icon={<IconReceipt />} 
                            title="Debit Note" voucher_type="debitnotes" quick={true} 
                            createdInAreas={[ 
                                {label : portal == "supplier" ? "Supplier App" : "Buyer App"},
                                {label : "Quick Action Button"},
                            ]} 
                        />
                    </Grid>
                    }
                    { permissions.payments &&
                    <Grid item xs={6}>
                        <UploadButton 
                            buttonType="card" icon={<IconCoinRupee />} title="Payment" 
                            voucher_type="payments" quick={true} 
                            createdInAreas={[ 
                                {label : portal == "supplier" ? "Supplier App" : "Buyer App"},
                                {label : "Quick Action Button"},
                            ]} 
                        />
                    </Grid>
                    }
                </Grid>
                <Typography variant="h6" component="h6" sx={{ mt: 4, mb: 2 }}>Support</Typography>
                <Grid container spacing={2} direction="row" justifyContent="start" alignItems="center" alignContent="center">
                <Grid item xs={6}>
                    <Card onClick={event => { return navigate("/support/transports") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py:4 }}>
                        <IconTruck></IconTruck>
                        <Typography variant="body1" component="p" sx={{ textAlign: 'center'}}>Transports</Typography>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card onClick={event => { return navigate("/support/managers") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py:4 }}>
                        <IconUsers></IconUsers>
                        <Typography variant="body1" component="p" sx={{ textAlign: 'center'}}>Rasiklal Team</Typography>
                    </Card>
                </Grid>
                </Grid>
            </Layout>
        }
        </div>
    );
}

export default SupplierDashboard;
