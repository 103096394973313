import { useTheme } from "@emotion/react";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";

const LoadingComponent = ({loading}) => {

    const theme = useTheme();

    return (
        <>
        <Backdrop open={loading} sx={{ color: theme.palette.primary.main, background: theme.palette.background.default, zIndex: (theme) => theme.zIndex.drawer + 1 }} >
            <Box sx={{display: "flex", flexDirection: "column",  width: "100%", alignItems: "center",  gap: 3}}>
                <CircularProgress  />
                <Typography>Loading</Typography>
            </Box>
        </Backdrop>
        </>
    )
}

export default LoadingComponent;

