import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react"
import Layout from "../../../components/Layout";
import LoadingComponent from "../../../components/LoadingComponent";
import { GlobalContext } from "../../../contexts/GlobalContext";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { parseISO, format } from 'date-fns';
import DownloadPDF from "../../../components/DownloadPDF";
import DownloadExcel from "../../../components/DownloadExcel";
import { Box } from "@mui/system";
import NoData from "../../../components/NoData";




export default function TermsByBuyer() {

    const { url,  customer, token, portal  } = useContext(GlobalContext);

    const title = "Terms By Buyer"
    
    const [loading, setLoading] = useState(true)

    const [data, setData] = useState([]); 
    
    const tableRef = useRef();

    const printString = portal === "buyer" ? `Buyer : ${data?.buyer?.name}` : `Supplier : ${data?.supplier?.name}`;

    // console.log({printString})

    useEffect(() => {

        const fetchData = () => {
            axios.post( url + "api/suppliers/termsBySupplier" , {
                buyer: portal === 'buyer' ? customer : "" ,
                supplier: portal === 'supplier' ? customer : ""                
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                // console.log({response})
                setData(response?.data)
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            });
        };

        fetchData() 

    }, [])

    const ReportsTable = ({list}) => {

        // console.log({list})

        const ReportsTableDataRow = ({item, index}) => {
            return(
                <>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="left" rowSpan={item?.buyer_schedule?.length + 1}>{index+1}</TableCell>
                    <TableCell align="left" rowSpan={item?.buyer_schedule?.length + 1}>{item?.name || ""}, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{ item?.city_name }</span></TableCell>
                    <TableCell align="left">{"Default"}</TableCell>
                    <TableCell align="left">{"-"}</TableCell>
                    <TableCell align="left">{"-"}</TableCell>
                    <TableCell align="left">{(item?.due_status !== null ||item?.due_status == 1 ) ? ( (item?.due_days && item?.due_discount) ? `${item?.due_days} Days ${item?.due_discount} %` : "NA"): "NA"}</TableCell>
                    <TableCell align="left">{(item?.nett_status !== null ||item?.nett_status == 1 ) ? ((item?.nett_days && item?.nett_discount) ? `${item?.nett_days} Days ${item?.nett_discount} %` : "NA"): "NA"}</TableCell>
                    <TableCell align="left">{item?.discount_remarks || "NA"}</TableCell>
                </TableRow>
                {item?.buyer_schedule?.map((data, index) => (
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index}>
                        <TableCell align="left">{(data?.active != 0) ? "Active" : "In-Active" }</TableCell>
                        <TableCell align="left">{ ( isNaN(Date.parse(data?.effective_from)) ? null : format(parseISO(data?.effective_from), 'dd MMM yyyy') ) || "NA"}</TableCell>
                        <TableCell align="left">{( isNaN(Date.parse(data?.effective_to)) ? null : format(parseISO(data?.effective_to), 'dd MMM yyyy') ) || "NA"}</TableCell>
                        <TableCell align="left">{(data?.due_status !== null ||data?.due_status == 1 ) ? ((data?.due_days && data?.due_discount) ? `${data?.due_days || 0} Days ${data?.due_discount || 0} %`: "NA"): "NA"}</TableCell>
                        <TableCell align="left">{(data?.nett_status !== null ||data?.nett_status == 1 ) ? ((data?.nett_days && data?.nett_discount) ? `${data?.nett_days || 0} Days ${data?.nett_discount || 0} %` : "NA"): "NA"}</TableCell>
                        <TableCell align="left">{data?.remarks || "NA"}</TableCell>
                    </TableRow>
                ))
                }
            </>
            )
        }

        return(
            <TableContainer component={Paper}>
                <Table ref={tableRef}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight: "bold"}}>S.No.</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>{portal === "supplier" ? "Buyer" : "Supplier"}</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Status</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Effective From</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Effective To</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Due Terms</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Nett Terms</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Remarks</TableCell>                        
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list?.length !== 0 && list?.sort((a, b) => a.name.localeCompare(b.name))?.map((item, index) => (<ReportsTableDataRow key={index} item={item} index={index} />))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
    
    return(
        <>
        {  loading ? 
            <LoadingComponent loading={loading} />
            :
            <Layout title={title}>
                {portal === "buyer" ? 
                
                (data?.buyers?.length !==0 ?
                <>
                    <Box sx={{mb:2, display: "flex", gap: 1}}>
                        <DownloadPDF tableRef={tableRef} pdfTitle={title} printString={printString} />
                        <DownloadExcel tableRef={tableRef} filename={title} sx={{background: "#217346"}} />
                    </Box>
                    <ReportsTable list={data?.buyers}/>
                </> : <NoData />
                )

                :

                (data?.buyers?.length !==0 ?
                <>
                    <Box sx={{mb:2, display: "flex", gap: 1}}>
                        <DownloadPDF tableRef={tableRef} pdfTitle={title} printString={printString}/>
                        <DownloadExcel tableRef={tableRef} filename={title} sx={{background: "#217346"}} />
                    </Box>
                    <ReportsTable list={data?.buyers}/>
                </> : <NoData /> 
                )

                }
            </Layout>
        } 
        </>
    )
}
