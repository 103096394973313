import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { LoadingButton } from '@mui/lab';
import { Alert, Autocomplete, Box, Dialog, DialogContent, DialogTitle, Fab, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { IconFilter, IconSearch } from '@tabler/icons-react'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../contexts/GlobalContext'; 

dayjs.extend(utc);
dayjs.extend(timezone);

function FilterButton({ setBuyer, setSupplier, setFromDate, setToDate, buyer, supplier, setFilterApplied, currentTab, currentStatus, setCurrentStatus}) {

    const { url, user, token, customer, portal  } = useContext(GlobalContext);
    console.log({ url, user, token, customer, portal  })
    const [ modalOpen, setModalOpen ] = useState(false);
    const [selectedBuyer, setSelectedBuyer] = useState("")
    const [selectedSupplier, setSelectedSupplier] = useState("")
    const [selectedFromDate, setSelectedFromDate] = useState()
    const [selectedToDate, setSelectedToDate] = useState()
    const [buyerOptions, setBuyerOptions] = useState([])
    const [supplierOptions, setSupplierOptions] = useState([])
    const [selectedCurrentStatus, setSelectedCurrentStatus] = useState(99)

    const [loading, setLoading] = useState(false);

    function handleOpen(){
        setModalOpen(true);
    }   

    function handleClose(){
        setModalOpen(false);
        setSelectedBuyer("")
        setSelectedSupplier("");
        setSelectedFromDate();
        setSelectedToDate();
        setSelectedCurrentStatus(99);
        setBuyerOptions([]);
        setSupplierOptions([]);
    }
    
    function handleSubmit(){
        if((selectedFromDate && selectedToDate) || (!selectedFromDate && !selectedToDate)){
        setLoading(true);
        setBuyer && setBuyer(selectedBuyer);
        setSupplier && setSupplier(selectedSupplier);
        setFromDate && setFromDate(selectedFromDate);
        setToDate && setToDate(selectedToDate);
        setCurrentStatus && setCurrentStatus(selectedCurrentStatus)
        handleClose();
        setLoading(false);
        setFilterApplied(true)
        }
    }

    function clearFilter(){
        setLoading(true);
        setBuyer && setBuyer("");
        setSupplier && setSupplier("");
        setFromDate && setFromDate("");
        setToDate && setToDate("");
        handleClose();
        setLoading(false);
    }

    const fetchBuyers = (query) => {
        if (query.length) {
            axios.post( url + 'api/admin/customers', {
                mode: "buyer",
                supplier: supplier,
                search_term: query
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setBuyerOptions(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }

    const fetchSuppliers = (query) => {
        if (query.length) {
            axios.post( url + 'api/admin/customers', {
                mode: "supplier",
                buyer: buyer,
                search_term: query
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setSupplierOptions(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }


  return (
    <>
        <Fab color="primary" aria-label="add" onClick={handleOpen} 
            sx={{ 
                position: "fixed", 
                bottom: (theme) => theme.spacing(2), 
                left: (theme) => theme.spacing(2) 
            }}
            size={"medium"}
        >
            <IconSearch />
        </Fab>
        <Dialog onClose={handleClose} open={modalOpen}>
            <DialogTitle>Filter</DialogTitle>
            <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                {
                    currentTab?.name === "CustomOrders" && <Alert severity="info" sx={{mb: 2}}> Unprocessed data will not be listed when selecting All Data </Alert>
                }
                {
                    setBuyer && (
                        <>
                            <InputLabel shrink>Buyer </InputLabel>
                            <Autocomplete sx={{ mb: 2 }}
                                options={ buyerOptions }
                                getOptionLabel={(option) => option.name ? option.name : '' }
                                onInputChange={(event, newInputValue) => { setBuyerOptions([]); fetchBuyers(newInputValue) }}
                                value={selectedBuyer}
                                onChange={(event, newValue) => {
                                    setSelectedBuyer(newValue)
                                }}
                                renderInput={(params) => <TextField {...params} required={false} />}
                            />
                        </>
                    )
                }
                {
                    setSupplier && (
                        <>
                            <InputLabel shrink>Supplier </InputLabel>
                            <Autocomplete sx={{ mb: 2 }}
                                options={ supplierOptions }
                                getOptionLabel={(option) => option.name ? option.name : '' }
                                onInputChange={(e, newInputValue) => { setSupplierOptions([]); fetchSuppliers(newInputValue) }}
                                value={selectedSupplier}
                                onChange={(e, newValue) => {
                                    setSelectedSupplier(newValue)
                                }}
                                renderInput={(params) => <TextField {...params} required={false} />}
                            />
                        </>
                    )
                }
                {
                    setFromDate && (
                        <>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <InputLabel shrink>From Date </InputLabel>
                                { (selectedToDate && !selectedFromDate ) ? <InputLabel shrink sx={{color: "red"}}>Select From Date </InputLabel> : <></> }
                                <DatePicker sx={{ mb: 2, width: "100%" }}
                                    timezone='Asia/Kolkata'
                                    value={selectedFromDate} 
                                    onChange={  (newDate) => setSelectedFromDate(newDate)  }
                                    format={'DD/MM/YYYY'}
                                    maxDate={selectedToDate ? selectedToDate : ""}
                                    slotProps={{
                                        textField: {
                                            InputProps: {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                <CalendarMonthIcon />
                                                </InputAdornment>
                                            ),                                            
                                            },
                                        },

                                    }}
                                />

                                <InputLabel shrink>To Date </InputLabel>
                                { (selectedFromDate && !selectedToDate ) ? <InputLabel shrink sx={{color: "red"}}>Select To Date </InputLabel> : <></> }
                                <DatePicker sx={{ mb: 2, width: "100%" }}
                                    timezone='Asia/Kolkata'
                                    value={selectedToDate}
                                    onChange={  (newDate) =>  setSelectedToDate(newDate)    }
                                    format={'DD/MM/YYYY'}
                                    minDate={selectedFromDate ? selectedFromDate : ""}
                                    slotProps={{
                                        textField: {
                                            InputProps: {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                <CalendarMonthIcon />
                                                </InputAdornment>
                                            ),
                                            },
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </>
                    )
                }
                {
                    (currentTab?.name === "Checkins" || currentTab?.name === "CustomOrders" || currentTab?.name === "Payments" || currentTab?.name === "Tickets") && 
                    <>
                    <InputLabel shrink>Select status</InputLabel>
                    <Select
                        sx={{ mb: 2 }}
                        fullWidth
                        value={selectedCurrentStatus}
                        onChange={(event) => setSelectedCurrentStatus(event.target.value) }
                    >
                        {
                            Object.entries(currentTab.status).map( ([key, value], index) => (<MenuItem value={+key} key={index}>{value}</MenuItem>))
                        }
                    </Select>
                    </>
                }

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                    <LoadingButton loading={loading} variant="contained" onClick={handleSubmit}>Filter</LoadingButton>
                    <LoadingButton loading={loading} variant="contained" color={"error"} onClick={clearFilter}>Clear Filter</LoadingButton>
                </Box>
            </DialogContent>
        </Dialog>
    </>
  )
}

export default FilterButton