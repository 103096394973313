import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react"
import Layout from "../../../components/Layout";
import LoadingComponent from "../../../components/LoadingComponent";
import { GlobalContext } from "../../../contexts/GlobalContext";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownloadPDF from "../../../components/DownloadPDF";
import DownloadExcel from "../../../components/DownloadExcel";
import { Box } from "@mui/system";
import NoData from "../../../components/NoData";




export default function UnfullfilledOrdersByDesign() {

    const { url,  customer, token, portal  } = useContext(GlobalContext);

    const title = "Unfulfilled Orders By Design";
    
    const [loading, setLoading] = useState(true)

    const [data, setData] = useState([]); 
    
    const tableRef = useRef();

    const printString = portal === "buyer" ? `Buyer : ${data[0]?.buyer_name}` : `Supplier : ${data[0]?.seller_name}`;

    // console.log({printString})

    useEffect(() => {

        const fetchData = () => {
            axios.post( url + 'api/reports/orders/pendings/designs' , {
                buyer: portal === 'buyer' ? customer : "" ,
                supplier: portal === 'supplier' ? customer : ""                
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                // console.log({response})
                setData(response?.data)
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            });
        };

        fetchData() 

    }, [])

    const ReportsTable = () => {

        const ReportsTableDataRow = ({item, index}) => {           

            return(
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }}}>
                    <TableCell component="th" scope="row">{index+1}</TableCell>
                    <TableCell align="left">
                        {(portal == "buyer") ? item?.voucher_details?.seller_details?.name : item?.voucher_details?.buyer_details?.name}, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{ (portal == "buyer") ? item?.voucher_details?.seller_details?.city_details?.name : item?.voucher_details?.buyer_details?.city_details?.name }</span>
                    </TableCell>                    
                    <TableCell align="left">{item?.design}</TableCell>
                    <TableCell align="left">{item?.category_details?.name}</TableCell>
                    <TableCell align="left">{item?.selected_qty}</TableCell>
                    <TableCell align="left">{item?.dispatched}</TableCell>
                    <TableCell align="left">{item?.cancelled}</TableCell>
                    <TableCell align="left">{item?.pending}</TableCell>
                    <TableCell align="left">{item?.excess_qty}</TableCell>
                </TableRow>
            )
        }

        return(
            <TableContainer component={Paper}>
                <Table ref={tableRef}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight: "bold"}}>S.No.</TableCell>
                            <TableCell sx={{fontWeight: "bold"}}>{portal == "buyer" ? "Supplier" : "Buyer"}</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Design</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Category</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Selected Qty</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Dispatched Qty</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Cancelled Qty</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Pending Qty</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Excess Qty</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.length !== 0 && data?.map((item, index) => (<ReportsTableDataRow index={index} key={index} item={item} />))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
    
    return(
        <>
        {  loading ? 
            <LoadingComponent loading={loading} />
            :
            <Layout title={title}>
                {data?.length !==0 ?
                <>
                    <Box sx={{mb:2, display: "flex", gap: 1}}>
                        <DownloadPDF tableRef={tableRef} pdfTitle={title} printString={printString} />
                        <DownloadExcel tableRef={tableRef} filename={title} sx={{background: "#217346"}} />
                    </Box>
                    <ReportsTable />
                </> 
                :
                <NoData />
                }
            </Layout>
        } 
        </>
    )
}
