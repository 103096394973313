import { LoadingButton } from "@mui/lab";
import { Alert, Avatar, Button, Chip, Dialog, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Snackbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import LoadingComponent from "../../components/LoadingComponent";
import { GlobalContext } from "../../contexts/GlobalContext";
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.css';
import axios from "axios";
import { format, parseISO } from "date-fns";
import MediaGrid from "../../components/MediaGrid";
import NoData from "../../components/NoData";
import heic2any from "heic2any";

registerPlugin(FilePondPluginFileRename)
registerPlugin(FilePondPluginFileValidateType);

export default function DeemedCustomersView(){

    const {id} = useParams();

    const { url, phone, contact, customer, token, ledgers, portal, masters  } = useContext(GlobalContext);

    const [isLoading, setIsLoading] = useState(false);
    const [markFinalVersion, setMarkFinalVersion] = useState(false);
    const [data, setData] =  useState(null)
    const [fileValidation, setFileValidation] = useState(true)

    useEffect(() => {

        const fetchVersionList = () => {
            axios.post( url + `api/buyers/orders/${id}/versions`, {}, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setIsLoading(true)
                setData(response?.data);
                setIsLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            });
        }

        fetchVersionList()


    }, [])

    const Version = ({item}) => {
        
        return(
            <>
            <Box sx={{my:1, mb:3}}>
                <Paper sx={{p:1}} elevation={3}>
                    <Grid container sx={{border: 1,  borderColor: 'divider'}}>
                        <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p" sx={{textAlign: "center", fontWeight: "bold"}}>version {item?.version || 0}</Typography>
                            {!!data?.order?.finalized_version_id && data?.order?.finalized_version_id === item?.id && <Box sx={{display: "flex", justifyContent: "center", mt:1}}><Chip color="success" label={"Finalized Version"} /></Box> }
                        </Grid>
                        <Grid item xs={6} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1,  textAlign: "center"  }}>
                            <Typography variant="overline" component="p">Uploaded On</Typography>
                            <Typography variant="body" component="p" sx={{wordWrap: "break-word", mt:1}}>{!!item?.created_at ? format(parseISO(item?.created_at), "dd MMM yyyy HH:mm:ss a") : '-'}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{  borderColor: 'divider', px: 1, py:1,  textAlign: "center"  }}>
                            <Typography variant="overline" component="p">Uploaded By</Typography>
                            <Typography variant="body" component="p" sx={{wordWrap: "break-word", mt:1}}>{!!item?.creator_details?.name && <Chip  size={"small"} label={item?.creator_details?.name} />}</Typography>
                            {!!item?.creator_details?.customer_details && <Typography variant="body" component="p" sx={{mt:1}}>{item?.creator_details?.customer_details?.name || "" }, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{item?.creator_details?.customer_details?.city_details?.name || "-"}</span></Typography>}
                        </Grid>
                        <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                            <MediaGrid dir="orders" images={item?.files} />
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
            </>
        )
    }

    const Actions = () => {

        const MarkFinal = () => {
            const [openModal, setOpenModal] =  useState(false);
            const [loading, setLoading] = useState(false);
            const [selectedVersion, setSelectedVersion] = useState("");

            function handleModalOpen(){
                setOpenModal(true)
            }
            
            function handleModalClose(){
                setOpenModal(false)
                loading && setLoading(false)
                setSelectedVersion("")
            }

            function handleSubmit(event){
                event.preventDefault();
                if(!!selectedVersion){
                    setLoading(true)
                    axios.post( url + `api/buyers/orders/${id}/finalize`, 
                    {
                        version_id : selectedVersion,
                        contact : contact,
                        customer: customer,
                        creator: portal == "supplier" ? 2 : 3,
                    }, 
                    { headers: { Authorization: `Bearer ${token}` }})
                    .then(function (response) {
                        // console.log({response});
                        if(response?.error == 0){
                            handleModalClose()
                            document.location.reload(); 
                        }else{
                            document.location.reload();
                            handleModalClose()
                        }                        
                    })
                    .catch(function (error) {
                        console.log(error);
                        handleModalClose()
                    })
                }
            }

            function handleSelectChange(event){
                setSelectedVersion(event?.target?.value);
            }

            return(
                <>
                    <Button variant="contained" onClick={handleModalOpen} >Mark as Final</Button>
                    {openModal &&
                        <Dialog onClose={handleModalClose} open={openModal}>                        
                        <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>

                            <form onSubmit={handleSubmit}>

                                <Typography variant="body2" component="p">Are you sure you want to confirm the selected version as final?</Typography>

                                
                                <FormControl sx={{ mt: 3, minWidth: "100%" }} size="small">
                                    <InputLabel id="select-label">Select the final version</InputLabel>
                                    <Select labelId="select-label" label="Select the final version" value={selectedVersion} onChange={handleSelectChange} >
                                        {data?.order?.versions?.map((item) => (<MenuItem key={item?.id} value={item?.id}>version {item?.version}</MenuItem>))}
                                    </Select>
                                </FormControl>
                                
                                
                                <Box sx={{ display: 'flex', flexDirection: 'row-reverse', gap: 1, mt: 4 }}>
                                    <LoadingButton type="submit" loading={loading} variant="contained">Confirm</LoadingButton>
                                    <Button color="error" variant="contained" onClick={handleModalClose}>Cancel</Button>
                                </Box>
                            </form>

                        </DialogContent>
                    </Dialog>
                    }
                </>
            )
        }

        const UploadVersion = () => {

            const [openModal, setOpenModal] = useState(false);
            const [files, setFiles] = useState([]);
            const [pond, setPond] = useState('');
            const [loading, setLoading] = useState(false);
            const [fileList, setFileList] = useState([])

            function handleModalOpen(){
                setOpenModal(true)
            }
            
            function handleModalClose(){
                setOpenModal(false)
                setFiles([])
                setLoading(false)
                setPond("")
                setFileList([])
            }

            const handleSubmit = async (event) => {
                event.preventDefault();
                setLoading(true);
                
                // var fileList = [];
                // files.forEach(function(file) {
                //     fileList.push({ file: file.filename, type: file.fileType, ext: file.fileExtension } )            
                // })
        
                if(fileList.length == 0) {
                    alert("Add one or more attachment to create entry");
                } else {
                    const fileNames = files.map( file =>  file.filename);
                    axios.post( url + `api/buyers/orders/${id}/versions/add`, {
                        files: JSON.stringify(fileList.filter( f => fileNames.includes(f.file))),
                        customer: customer,
                        contact: contact
                    }, { headers: { Authorization: `Bearer ${token}` }})
                    .then(function (response) {
                        if(response?.data?.error == 0) {
                            handleModalClose();
                            document.location.reload();                            
                        } else {
                            alert(response?.data?.message);
                            handleModalClose();
                        }
                    })
                    .catch(function (error) {
                        console.log({error});
                        handleModalClose()
                    });
                }
            };

            const fileRenameFunction = (file) => {
                if(file.extension == file.name) {
                    return `f_${Math.random().toString(36).slice(2, 7)}_${(+new Date).toString(36).slice(-5)}.jpg`;
                } else {
                    return `f_${Math.random().toString(36).slice(2, 7)}_${(+new Date).toString(36).slice(-5)}${file.extension}`;
                }
            };

            const handleCamera = () => {
                setFileValidation(false);
                navigator.camera.getPicture(function (imageData) {
                    pond.addFile("data:image/jpg;base64," + imageData)
                    .then((file) => {
                        setFiles(prevFiles => [...prevFiles, file]);
                        setFileValidation(true);
                        console.log("file", file);
                    }).catch((error) => {
                        setFileValidation(true);
                        console.log(error);
                    });
                }, function (message) {
                    alert(message);
                    setFileValidation(true);
                }, {
                    quality: 50,
                    destinationType: Camera.DestinationType.DATA_URL,
                    encodingType: Camera.EncodingType.JPEG,
                    mediaType: Camera.MediaType.PICTURE,
                    correctOrientation: true
                });
            };    
            
            const handleFile = () => {
                pond.browse();
            };

            const [errorMessage, setErrorMessage] = useState("");
            const [openErrorAlert, setOpenErrorAlert] = useState(false);    

            const ErrorMessage = () => {

                function handleClose(){
                    setOpenErrorAlert(false)
                    setErrorMessage('')
                }

                return(
                    <>
                    <Snackbar open={openErrorAlert}  anchorOrigin={{vertical: 'top', horizontal: 'center',}} 
                        onClose={handleClose}>
                    <Alert
                        onClose={handleClose}
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {errorMessage}
                    </Alert>
                    </Snackbar>
                    </>
                )
            }

            function convertHEICToJPG(file) {
                return heic2any({
                    blob: file,
                    toType: "image/jpeg",
                    quality: 0.6
                });
            }

            function getExtension(fileName) {
                if (fileName.includes('.')) {
                    // Split the fileName by dot and return the last part
                    return '.' + fileName.split('.').pop();
                } else {
                    // If there's no dot, return an empty string indicating no extension
                    return '';
                }
            }

            return(
                <>
                    <Button variant="contained" onClick={handleModalOpen} >Upload Versions</Button>
                    {openModal &&
                        <Dialog onClose={handleModalClose} open={openModal}>
                        <DialogTitle>Upload Versions</DialogTitle>                        
                        <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>

                            <form onSubmit={handleSubmit}>
                                <InputLabel shrink>Attachments</InputLabel>
                                <Alert severity="info" sx={{mb:2}}>
                                    <Typography variant="body2" sx={{fontSize: "12px"}}> Allowed File types are jpg, jpeg, heic, png, pdf, xls, xlsx, xlsm, xlsb, ods, csv </Typography>
                                </Alert>
                                <Box sx={{ display: 'flex', justifyContent:"center", alignItems:"center", flexDirection: 'row', gap: 1, mb: 2 }}>
                                    { window.cordova &&
                                        <Button variant="contained" size="medium" onClick={handleCamera}>Camera</Button>
                                    }
                                    <Button variant="contained" size="medium" onClick={handleFile}>File</Button>
                                </Box>
                                <FilePond
                                    acceptedFileTypes={
                                        ["application/pdf",
                                         "image/png", 
                                         "image/jpg", 
                                         "image/jpeg", 
                                         "image/heic", 
                                         'application/vnd.ms-excel',  // .xls
                                         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
                                         'application/vnd.ms-excel.sheet.macroEnabled.12', // .xlsm
                                         'application/vnd.ms-excel.sheet.binary.macroEnabled.12', // .xlsb
                                         "text/csv", //.csv
                                         "application/vnd.oasis.opendocument.spreadsheet", //.ods
                                          ""
                                        ]
                                    }
                                    ref={ref => { setPond(ref) }}
                                    credits={false}
                                    files={files}
                                    onupdatefiles={setFiles}
                                    fileRenameFunction={ file => (fileRenameFunction(file))}
                                    allowMultiple={true}                            
                                    // server={ url + "api/pond/process"}
                                    server={{
                                        url: url,
                                        revert: "./api/pond/process",
                                        process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                                            const allowedFiles = [".jpg", ".jpeg", ".heic", ".png", ".pdf", ".xls", ".xlsx", ".xlsm", ".xlsb", ".ods", ".csv" ]  
                                            if (fileValidation) {
                                                if(!allowedFiles.includes(getExtension(file.name.toLowerCase()))){
                                                    error('Unkown file extension')
                                                    setOpenErrorAlert(true)
                                                    setErrorMessage('Unsupported file type. Please upload files with the allowed extensions. Allowed File types are jpg, jpeg, heic, png, pdf, xls, xlsx, xlsm, xlsb, ods, csv')
                                                    abort()
                                                }
                                            } else {
                                                file.name = file.name + '.jpg';
                                            }
                                            try {
                                                let fn = file.name.toLowerCase()
                                                // Check if the file type is HEIC
                                                if (fn.indexOf('.heic') !== -1) {
                                                    // Convert HEIC to JPG
                                                    const convertedBlob = await convertHEICToJPG(file);
                                                    // Create a new file object from the Blob
                                                    const newFile = new File([convertedBlob], file.name.replace(/\.heic$/i, '.jpg'), {
                                                        type: "image/jpeg",
                                                    });
                                                    file = newFile; // Update the file reference
                                                }
                                
                                                // Now upload the file (either original or converted)
                                                // Example using fetch API
                                                const formData = new FormData();
                                                formData.append(fieldName, file, file.name);
                                                const response = await fetch(url + "api/pond/process", {
                                                    method: 'POST',
                                                    body: formData
                                                });
                                
                                                if (response.ok) {
                                                    const data = await response.json();
                                                    load(data.name);
                                                    let currentFile = { file: data.name, type: data.type, ext: data.ext };
                                                    setFileList(currentFiles => [...currentFiles, currentFile]);
                                                } else {
                                                    error('Could not upload file');
                                                }
                                            } catch (err) {
                                                error(err.message);
                                            }}
                                        
                                    }}
                                    name="files" 
                                    labelIdle='No files selected'
                                    onaddfile={(error, file) => { 
                                        if(!error){
                                            setLoading(true)
                                        }
                                    }}  
                                    onprocessfiles={() => setLoading(false)}   
                                  //  onprocessfilerevert={() => {setLoading(true)}}   
                                    // onremovefile={() => {
                                    //     setLoading(false); 
                                    //     setOpenErrorAlert(false); 
                                    //     setErrorMessage("")
                                    // }} 
                                    onerror={(error, file) => {
                                        console.log({error, file})
                                        // const filesArray = files?.filter((item) => (item?.id !== file?.id));
                                        // setFiles(filesArray)
                                    }}              
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                                    <LoadingButton type="submit" loading={loading} variant="contained">Save</LoadingButton>
                                </Box>
                            </form>

                            {openErrorAlert &&
                                <>
                                <ErrorMessage />
                                </>
                            } 

                        </DialogContent>
                    </Dialog>
                    }                    
                </>
            )
        }

        return(
            <>
            {!!!data?.order?.finalized_version_id &&
                <Box sx={{display: "flex", flexDirection: "row-reverse", p: 1, gap: 1, my:2}}>
                    {portal === "buyer" && <MarkFinal />} 
                    <UploadVersion /> 
                </Box>
            }
            </>
        )
    }
    
    return(
        <>
            {
                isLoading ? 
                <LoadingComponent loading={ isLoading } />
                : 
                <Layout title="Order Versions">
                    {data?.error == 0 ?
                        <>
                            <Actions />
                            { 
                                data?.order?.versions?.length > 0 && data?.order?.versions?.map((item) => (<Version item={item} key={item?.id} />))
                            }
                        </>
                        :
                        <LoadingComponent loading={!(data?.error == 0)} />
                    }
                </Layout>
            }
        </>
    )
}