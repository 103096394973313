import React, { useContext, useState } from 'react'
import axios from "axios";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { GlobalContext } from '../contexts/GlobalContext'
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { Alert, IconButton, Snackbar } from '@mui/material';
import { IconX } from '@tabler/icons-react';

function CheckinButton(props) {
    const { url, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken  } = useContext(GlobalContext);

    const [open, setOpen] = useState(false) //const open = false;
    const [date, setDate] = useState("")
    const [loading, setLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false)

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {        
        setDate("");
        setLoading(false);
        setOpen(false);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        axios.post( url + 'api/suppliers/checkin', {
            customer: customer,
            contact: contact,
            phone: phone,
            date: date,
            id: props.id,
            creator: props.creator ? props.creator : null,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            console.log("response", response);
            if(response.data.error == 0) {
                handleClose();
                props?.refetch ? props.refetch() : document.location.reload();
            }
            else if(response.data.error == 1) {
                setOpenAlert(true)
                handleClose()
            } else {
                alert(response.data.message)
            }
        })
        .catch(function (error) {
            setLoading(false);
            console.log(error);
        });
    }

    const handleAlertClose = () => {
        setOpenAlert(false)
    }

    return (
        <div>
            <Button variant="contained"  fullWidth onClick={handleOpen}>Check-In</Button>
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>Check-In</DialogTitle>
                <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                    <form onSubmit={handleSubmit}>
                        <InputLabel shrink>Date & Time</InputLabel>
                        <TextField sx={{ mb: 2 }} fullWidth required={true} type="datetime-local" 
                            value={date}
                            onChange={event => {
                                const { value } = event.target;
                                setDate(value);
                            }} 
                            InputProps={{
                                inputProps: {
                                  max: `${format(new Date(), "yyyy-MM-dd")}T23:59`,
                                },
                            }}
                            />
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                            <LoadingButton type="submit" loading={loading} variant="contained">Check-In</LoadingButton>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>

            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={handleAlertClose} severity="error" variant="filled"  sx={{ width: '100%' }}>
                    Another check-in already exists for this Date, Buyer, and Supplier!
                </Alert>
            </Snackbar>
        </div>
    )
}
export default CheckinButton;