import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../../components/Layout';
import DeliveryUploader from '../../components/DeliveryUploader';
import MediaGrid from '../../components/MediaGrid';
import { GlobalContext } from '../../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import { format, parse, parseISO } from 'date-fns'
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { Fancybox } from "@fancyapps/ui";
import TicketsGlobal from '../../components/TicketsGlobal';
import { Alert, Button, Chip, Divider } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import find from 'lodash/find';
import { styled } from '@mui/material/styles';
import {ButtonGroup} from '@mui/material';
import LoadingComponent from '../../components/LoadingComponent';



function Invoice() {
    let { id } = useParams();
    const navigate = useNavigate();
    const { url, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken, portal  } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        fetchData();
        if(window.cordova) {
            window.cordova.plugins.firebase.analytics.setCurrentScreen("Invoice View");
        }
    }, [ ]);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.secondary.main , // #044B7F
          borderRight: '1px solid #15649e !important',
          color: "#fff"
        },
        [`&.${tableCellClasses.body}`]: {
          borderRight: '1px solid #e3e1e1'
        },
        '&:last-child': {
            borderRight: 0
        },
    }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: "#f2f2f2",
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            
        },
    }));

    const fetchData = () => {
        axios.post( url + 'api/voucher', {
            customer: customer,
            contact: contact,
            phone: phone,
            id: id,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            console.log("Invoice", response.data);
            setData(response.data);
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const Info = () => {
        if (data.hasOwnProperty('voucher')) { 
        return (
            <React.Fragment>
                <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, mb: 4 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                        <Typography variant="overline" component="p" sx={{fontWeight: "bold"}}>{portal === "buyer" ? "Supplier" : "Buyer"}</Typography>
                        <Typography variant="h6" component="h6" sx={{fontWeight: "bold"}}>{ portal == "supplier" ? data?.voucher?.buyer_name : data?.voucher?.seller_name }</Typography>
                    </Box>
                    <Grid container>
                        <Grid item xs={4} sx={{ borderRight: 1, borderBottom: 1,  borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Invoice #</Typography>
                            <Typography variant="body" component="p" style={{wordWrap: "break-word"}}>
                                {data.voucher.voucher_no ? data.voucher.voucher_no : ''}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Date</Typography>
                            <Typography variant="body" component="p">{data.voucher.date ? format(parse(data.voucher.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">City</Typography>
                            <Typography variant="body" component="p">{data.voucher.buyer_city ? data.voucher.buyer_city : ''}</Typography>
                        </Grid>
                        <Grid item xs={4} sx={{  borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Total</Typography>
                            <Typography variant="body" component="p">&#8377; {data.voucher.total ? data.voucher.total : ''}</Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Due</Typography>
                            <Typography variant="body" component="p">&#8377; {data.summary.due ? data.summary.due : '-'}</Typography>
                        </Grid>
                        <Grid item xs={4} sx={ alertEnable ? {borderBottom: 1, borderColor: 'divider', px: 1, py:1, color: "red"} :{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Due Date</Typography>
                            <Typography variant="body" component="p">{data.summary.due_date && !!data?.summary?.due_date ? format(parse(data.summary.due_date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : '-'}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Discount Due Days</Typography>
                            <Typography variant="body" component="p">{data.voucher.due_days ? `${data.voucher.due_days} days` : '-'}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Nett Days</Typography>
                            <Typography variant="body" component="p">{data.voucher.nett_days ? `${data.voucher.nett_days} days` : '-'}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
                
                { portal == "supplier" &&
                    <Box sx={{ mb:4 }}>
                    {/* { parseInt(data.summary.due) != 0 &&
                        <Box sx={{ mb:2 }}>
                            <PaymentUploader creator="2" id={data.voucher.id} due={parseInt(data.summary.due)} />
                        </Box>
                    } */}
                        <Box sx={{ mb:4 }}>
                            <DeliveryUploader creator="2" id={data.voucher.id} />
                        </Box>
                    </Box>
                }
            </React.Fragment>
        )
        } else {
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, px: 2, py:2 }}>
                <Typography variant="overline" component="p">Loading...</Typography>
            </Paper>
        }
    }

    const isMobile = useMediaQuery('(max-width:600px)');

    // console.log({isMobile})

    const Address = () => {

        const [address, setAddress] = useState("billing")

        const BillingAddress = () => {
            if(data.voucher?.billing_address === null){
                return <></>
            }
            return(
                <>
                    { isMobile ? <></> : <Typography variant="subtitle1" component="p" sx={{textDecoration: "underline", marginBottom: "5px"}}>Billing Address</Typography>}
                    <Typography variant="body" component="p">Name: {data.voucher?.billing_address.name}</Typography>
                    <Typography variant="body" component="p">Address Line 1: {data.voucher?.billing_address.address_1}</Typography>
                    <Typography variant="body" component="p">Address Line 2: {data.voucher?.billing_address.address_2}</Typography>
                    <Typography variant="body" component="p">Area: {data.voucher?.billing_address.area}</Typography>
                    <Typography variant="body" component="p">City: {data.voucher?.billing_address.city_details.name}</Typography>
                    <Typography variant="body" component="p">State: {data.voucher?.billing_address.state_details.name}</Typography>
                    <Typography variant="body" component="p">Country: {data.voucher?.billing_address.country_details.name}</Typography>
                    <Typography variant="body" component="p">PIN: {data.voucher?.billing_address.pincode}</Typography>
                    <Typography variant="body" component="p">Phone: {data.voucher?.billing_address.cc_phone}</Typography>
                </>
            )
        }        

        const ShippingAddress = () => {
            if(data.voucher?.shipping_address === null){
                return <></>
            }
            return (
                <>
                    { isMobile ? <></> :  <Typography variant="subtitle1" component="p" sx={{textDecoration: "underline", marginBottom: "5px"}}>Shipping Address</Typography>}
                    <Typography variant="body" component="p">Name: {data.voucher.shipping_address.name}</Typography>
                    <Typography variant="body" component="p">Address Line 1: {data.voucher?.shipping_address.address_1}</Typography>
                    <Typography variant="body" component="p">Address Line 2: {data.voucher?.shipping_address.address_2}</Typography>
                    <Typography variant="body" component="p">Area: {data.voucher.shipping_address.area}</Typography>
                    <Typography variant="body" component="p">City: {data.voucher?.shipping_address.city_details.name}</Typography>
                    <Typography variant="body" component="p">State: {data.voucher?.shipping_address.state_details.name}</Typography>
                    <Typography variant="body" component="p">Country: {data.voucher?.shipping_address.country_details.name}</Typography>
                    <Typography variant="body" component="p">PIN: {data.voucher.shipping_address.pincode}</Typography>
                    <Typography variant="body" component="p">Phone: {data.voucher?.shipping_address.cc_phone}</Typography> 
                </>
            )
        }

        // <Box sx={isMobile ? {padding: "5px"} : {display: "flex", padding: "5px"} }>
        //             <Box sx= {isMobile? {marginBottom: "5px",  padding: "5px"} : {flex: 1,  padding: "10px"}}>
        //                 <BillingAddress />                    
        //             </Box>
        //             {isMobile ? <Divider variant="middle" /> : <Divider orientation="vertical" flexItem />}
        //             <Box sx= {isMobile? {padding: "5px"} : {flex: 1, padding: "10px"}}>
        //                 <ShippingAddress />                                       
        //             </Box>
        //         </Box>

        return (            
            <>
            <Typography  variant="h6" component="h6" sx={{mb:2}}>Address</Typography>
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', mt: 1, mb: 4, p: 1 }}>
                { isMobile ? 
                <> 

                {/* <ButtonGroup sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "5px", gap: "15px"}} variant="text">
                <Button onClick={() => setAddress("billing")} sx={address === "billing" ? {}:{color: "#000"}} >Billing Address</Button>
                <Button onClick={() => setAddress("shipping")} sx={address === "billing" ? {color: "#000"}:{}} >Shipping Address</Button>
                </ButtonGroup> */}

                <Box sx={{display: "flex", justifyContent: "space-evenly", alignItems: "center", marginTop: "10px", marginBottom: "10px"}}>
                <Chip label="Billing Address"  onClick={() => setAddress("billing")} color="primary" variant={ (address === "shipping" ? "outlined" : "filled")} />
                <Chip label="Shipping Address"  onClick={() => setAddress("shipping")} color="primary" variant={ (address === "billing" ? "outlined" : "filled")} />
                </Box>

                <Box sx={ {padding: "5px"} }>
                    { (address === "billing") ? <BillingAddress /> : <ShippingAddress /> }
                </Box> 
                </> 
                : 
                <>
                <Box sx={ {display: "flex", padding: "5px"} }>
                    <Box sx= {{flex: 1,  padding: "10px"}}>
                        <BillingAddress />                    
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <Box sx= {{flex: 1, padding: "10px"}}>
                        <ShippingAddress />                                       
                    </Box>
                </Box>
                </> }
            </Paper>
            </>
            
            
        )
    }

    const ItemRow = (props) => {
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        if(!row.hasOwnProperty('invoiced')){
            row.invoiced = 0;
        }

        return (
          <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell>
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>{open ? <IconChevronUp /> : <IconChevronDown />}</IconButton>
              </TableCell>
              <TableCell align="left">{row.sno}</TableCell>
              <TableCell align="left">{row.particular}</TableCell>
              <TableCell align="right">{row.rate}</TableCell>
              <TableCell align="right">{row.qty}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                    <Typography variant="h6" gutterBottom component="div">Details</Typography>

                    <Grid container>
                        {
                            JSON.parse(row.attributes).length > 0 &&
                            JSON.parse(row.attributes).map( (attribute, index) => {
                                return (
                                    
                                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}  key={index}>
                                            <Typography variant="overline" component="p">{attribute.name}</Typography>
                                            <Typography variant="body" component="p">{attribute.value}</Typography>
                                        </Grid>
                                    
                                )
                            })
                        }
                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Discount (%)</Typography>
                            <Typography variant="body" component="p">{row.discount}</Typography>
                        </Grid>
                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Discount (&#8377;)</Typography>
                            <Typography variant="body" component="p">&#8377;{row.discount_amount}</Typography>
                        </Grid>
                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Tax (%)</Typography>
                            <Typography variant="body" component="p">{row.tax}</Typography>
                        </Grid>
                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Total</Typography>
                            <Typography variant="body" component="p">&#8377;{row.subtotal}</Typography>
                        </Grid>
                    </Grid>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
    }

    const DesktopTable = ({data}) => {
        return (
            
            <Box style={{marginBottom: "10px"}} >
            <Typography  variant="h6" component="h6" sx={{mb:2}}>Items</Typography>
            <TableContainer component={Paper}>
                <Table>
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell align="left">#</StyledTableCell>
                        <StyledTableCell align="left">Particular</StyledTableCell>
                        <StyledTableCell align="left">Rate</StyledTableCell>
                        <StyledTableCell align="left">Qty</StyledTableCell>
                        {
                            data.summary?.attributes && data.summary?.attributes?.length != 0 && 
                            data.summary?.attributes?.map((attribute, index)=>{
                                if(attribute?.name){
                                    return(
                                        <StyledTableCell align="left" key={index}>{attribute?.name}</StyledTableCell>                                    
                                    )
                                }
                            })
                        }
                        <StyledTableCell align="left">Discount (%)</StyledTableCell>
                        <StyledTableCell align="left">Discount (&#8377;)</StyledTableCell>
                        <StyledTableCell align="left">Tax (%)</StyledTableCell>
                        <StyledTableCell align="left">Total</StyledTableCell>
                    </StyledTableRow>
                    
                </TableHead>
                <TableBody>
                    { data?.voucher?.clients_voucher_items && data.voucher?.clients_voucher_items?.length != 0 &&
                    data.voucher?.clients_voucher_items?.map((row, index) => (
                        <DesktopTableRow key={index} index={index} row={row} attributes={JSON.parse(row?.attributes)} />
                    ))}
                </TableBody>
                </Table>
            </TableContainer>

            </Box> 
          
        )}


    const DesktopTableRow = (props) => {
        const { row, index } = props;

        console.log({row})

        if(!row.hasOwnProperty('invoiced')){
            row.invoiced = 0;
        }
        return (
          <React.Fragment>
            <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              <StyledTableCell align="left">{ index + 1 }</StyledTableCell>
              <StyledTableCell align="left">{row?.particular}</StyledTableCell>
              <StyledTableCell align="left">&#8377;{row?.rate}</StyledTableCell>
              <StyledTableCell align="left">{row?.qty}</StyledTableCell>
              {
                props.attributes?.length > 0 && 
                props.attributes.map((attribute, index)=>{
                    // const attr_value = find(row.attributes, { id: attribute.id.toString() });
                    return(
                        <StyledTableCell align="left" key={index}>{ attribute?.value || '-'}</StyledTableCell>                                    
                    )
                })
                }
                <StyledTableCell align="left">{row?.discount || "0"}</StyledTableCell>
                <StyledTableCell align="left">&#8377;{row?.discount_amount || "0"}</StyledTableCell>
                <StyledTableCell align="left">{row?.tax}</StyledTableCell>
                <StyledTableCell align="left">&#8377;{row?.subtotal}</StyledTableCell>


            </StyledTableRow>
    
          </React.Fragment>
        );
    }

   
    const Items = () => {
        return (
            <Box>
                <Typography variant="h6" component="h6" sx={{mb:2}}>Items</Typography>
                <TableContainer component={Paper} sx={{ mb: 1 }}>
                    <Table stickyHeader aria-label="collapsible table">
                        <TableHead>
                        <TableRow>
                            <TableCell width="30px" />
                            <TableCell width="50px" align="left" size="small">#</TableCell>
                            <TableCell align="left">Particular</TableCell>
                            <TableCell align="right">Rate</TableCell>
                            <TableCell align="right">Qty</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {data?.voucher?.clients_voucher_items?.map((row, index) => (
                            <ItemRow key={index} row={row} />
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    const Payments = () => {

        function redirectToPayment(id){
            navigate(`/payment/${id}`)
        }
        
        return (
            <Box>
                <Typography variant="h6" component="h6" sx={{mb:2, mt:2}}>Payments</Typography>
                {data.payments?.map((row) => {
                    return( 
                    <Card key={row.id} sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mb: 4 }}>
                        <Grid container>
                            <Grid item xs={4} sx={{ borderBottom: 1, borderRight: 1, borderColor: 'divider', px: 1, py:2 }}>
                                <Typography variant="overline" component="p">Payment No.</Typography>
                                <Typography variant="body" onClick={() => {if(!!row?.payment?.payment_no){redirectToPayment(row?.payment?.id)}}} component="p">{row?.payment?.payment_no}</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ borderBottom: 1, borderRight: 1, borderColor: 'divider', px: 1, py:2 }}>
                                <Typography variant="overline" component="p">Payment Date</Typography>
                                <Typography variant="body" component="p">{!!row?.created_at ? format(parseISO(row?.created_at), "yyyy-MM-dd hh:mm:ss a") : '-'}</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:2 }}>
                                <Typography variant="overline" component="p">Paid Amount</Typography>
                                <Typography variant="body" component="p">&#8377; {row?.total ? row?.total : '-'}</Typography>
                            </Grid>
                            {!!row?.narration &&
                                <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:2 }}>
                                    <Typography variant="overline" component="p">Narration</Typography>
                                    <Typography variant="body" component="p">{row.narration ? row.narration : '-'}</Typography>
                                </Grid>
                            }
                        </Grid>
                        <MediaGrid dir="payments" images={row.images} />
                    </Card>)
                })}
            </Box>
        )
    }

    const Deliveries = () => {
        return (
            <Box sx={{mt: 2}}>
                <Typography variant="h6" component="h6" sx={{mb:2}}>Deliveries</Typography>
                {data.deliveries?.map((row) => (
                    <Paper key={row.id} sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mb: 4 }}>
                        <Grid container>
                            <Grid item xs={6} sx={{ borderBottom: 1, borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                                <Typography variant="overline" component="p">Date</Typography>
                                <Typography variant="body" component="p">{row.date ? format(parse(row.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                <Typography variant="overline" component="p">Transport</Typography>
                                <Typography variant="body" component="p">{row.transport ? row.transport : '-'}</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ borderBottom: 1, borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                                <Typography variant="overline" component="p">LR</Typography>
                                <Typography variant="body" component="p">{row.lr_number ? row.lr_number : '-'}</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                <Typography variant="overline" component="p">Notes</Typography>
                                <Typography variant="body" component="p">{row.notes ? row.notes : '-'}</Typography>
                            </Grid>
                        </Grid>
                        <MediaGrid dir="deliveries" images={row.images} />
                    </Paper>
                ))}
            </Box>
        )
    }

    const [alertEnable, setAlertEnable] = useState(false);

    const [timeRemaining, setTimeRemaining] = useState(0);

    useEffect(()=>{
        
        const today = new Date()
        const dueDate = new Date(data.summary?.due_date)
        const daysRemaining = Math.floor((dueDate - today) / (1000 * 60 * 60 * 24))
        // const hoursDifference = Math.floor(((dueDate - today) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

        if(daysRemaining>0 && daysRemaining<=7){
            setAlertEnable(true)
            setTimeRemaining(daysRemaining)
        }
    }, [data])

    return (
        <div>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title="Invoice">
                { alertEnable && 
                    <Alert  severity="error">
                        {`Only ${timeRemaining} days left for due date discount!`}
                    </Alert>
                }
                <Info />
                <Address />
                { isMobile ?  <Items /> :  <DesktopTable data={data} /> }
                { !!data?.payments && data?.payments?.length > 0 && <Payments />}                
                <Deliveries />
                <TicketsGlobal seller={data.voucher.seller} buyer={data.voucher.buyer} area="clients_vouchers" id={data.voucher.id} />
            </Layout>
        }
        </div>
    );
}

export default Invoice;