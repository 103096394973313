import { Box, Grid, Paper, Tab, Tabs, Typography, Button, Chip } from "@mui/material";
import axios from "axios";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import BackToTop from "../../components/BackToTop";
import ErrorMessage from "../../components/ErrorMessage";
import FilterForm from "../../components/FilterForm";
import Layout from "../../components/Layout";
import LoadingComponent from "../../components/LoadingComponent";
import MediaGrid from "../../components/MediaGrid";
import ScrollComponent from "../../components/ScrollComponent";
import UploadMedia from "../../components/UploadMedia";
import Void from "../../components/Void";
import UploadButton from "../../components/VoucherUploader";
import { GlobalContext } from "../../contexts/GlobalContext";

export default function InvoicesPage() {


    let { status } = useParams();
    const navigate = useNavigate();
    const { url,customer, token, portal, contact, phone } = useContext(GlobalContext);
    const title = "Invoices";

    const fetcher = async (page, pageStatus) => {        
        const response = await axios.post( url + 'api/invoices?page=' + page, {
            customer: customer,
            contact: contact,
            phone: phone,
            status: pageStatus,
            creator: portal == "supplier" ? 2 : 3,
        }, { headers: { Authorization: `Bearer ${token}` }})

        return response.data
    }

    const { isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery( 
        ['invoices', status],  
        ({ pageParam = 1 }) => fetcher(pageParam, status),
        {
          getNextPageParam: (lastPageData) => { 
            if(lastPageData?.current_page < lastPageData.last_page){
                return lastPageData?.current_page + 1
            }else{
                return undefined
            } 
          },
          refetchOnWindowFocus: false,
        }
    );

    const Item = ({data}) => {

        if (data.images){
            // console.log(JSON.parse(data.images));
        }

        if(data?.summary !== null && typeof data?.summary === 'string') {
            data.summary = JSON.parse(data?.summary);
        }

        var voided = 0;
        if(status == 0) {
            if(data.status == 6) {
                voided = 1;
            }
        } else {
            if(data.status == 3) {
                voided = 1;
            }
        }

        function findRemainingDays () {
            const today = new Date()
            const dueDate = new Date(data.summary?.due_date)
            // console.log({today, dueDate})
            const timeDifference = dueDate - today
            const daysRemaining = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
            // console.log({daysRemaining})
            if(daysRemaining>0 && daysRemaining<=7){
                return {border: 1, borderColor: 'red', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4}
            }
        }

        let styleObject;

        if (status == 1){
         styleObject = findRemainingDays();
        }

        return (
            <Paper sx={styleObject ? styleObject : { border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                <Box  sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                    <Typography variant="overline" component="p">{portal === "buyer" ? "Supplier" : "Buyer"}</Typography>
                    <Typography variant="h6" component="h6">{ portal == "supplier" ? data.buyer_name : data.seller_name }, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{ portal == "supplier" ? data.buyer_city_details?.name : data.seller_city_details?.name }</span></Typography>
                    {
                        portal === "buyer" && !!data?.supplier_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.supplier_msme} /></Box>
                    }
                    {
                        portal === "supplier" && !!data?.buyer_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.buyer_msme} /></Box>
                    }
                </Box>
                <Grid container>
                    <Grid item xs={4} sx={{ borderBottom: 1, borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Invoice #</Typography>
                        <Typography variant="body" component="p" style={{wordWrap: "break-word"}}>
                            {data.voucher_no ? data.voucher_no : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ borderBottom: 1, borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Date</Typography>
                        <Typography variant="body" component="p">{data.date ? format(parse(data.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Total</Typography>
                        <Typography variant="body" component="p">&#8377; {data.total ? data.total : '-'}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Due</Typography>
                        <Typography variant="body" component="p">&#8377; {data.summary ?  JSON.parse(data.summary?.due)  : '-'}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={styleObject ? {borderRight: 1,color: "red", borderColor: 'divider', px: 1, py:1} :{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Due Date</Typography>
                        <Typography variant="body" component="p">{data.summary ? ( data?.summary?.due_date ? format(parse(data.summary?.due_date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : "-" )  : '-'}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Box style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Typography variant="overline" component="p" >{"  "} Discount</Typography>
                        </Box>                        
                        { !!data?.summary ? 
                            <Typography variant="body" component="p"> &#8377; { (data?.summary)?.totalDiscount ?? "-" }</Typography> 
                            : 
                            <Typography variant="body" component="p">-</Typography>
                        }                        
                    </Grid>
                </Grid>

                <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%' }}>
                    <MediaGrid dir="invoices" images={data.images} />
                </Box>
                
                {
                    status == 0 && portal == "supplier" &&
                    <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%', px: 1, paddingTop: 1, display: "flex", jsutifyContent: "center", alignItems: "center", gap: "5px" }}>
                        <Box sx={{flex: 1, flexGrow: 1}}>
                            <UploadMedia
                                id={data.id} module="entry"
                                dir="invoices" link={url + 'api/media/add'}
                                refetch={refetch}
                            />
                        </Box>
                        <Box sx={{ flex: 1, flexGrow: 1 }}>
                            <Void styleObj={{height: "100%", marginBottom: 1}} refetch={refetch} id={data.id} type={ status == 0 ? 'entry' : 'voucher' } voided={ voided } />
                        </Box> 
                    </Box>
                    
                }

                { status != 0 &&  data?.status_label !== "Voided" &&
                    <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%', px: 1, py: 1, display: "flex", jsutifyContent: "center", alignItems: "center", gap: "5px" }}>
                        <Box sx={{flex: 1, flexGrow: 1}}>
                            <Button variant="contained"  fullWidth onClick={()=> navigate("/invoice/" + data.id)}>View Invoice</Button>
                        </Box>
                        <Box sx={{ flex: 1, flexGrow: 1 }}>
                            <Void styleObj={{height: "100%"}} refetch={refetch} id={data.id} type={ status == 0 ? 'entry' : 'voucher' } voided={ voided } />
                        </Box> 
                    </Box>
                }                    
                
            </Paper>
        )
    }

    // console.log({isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch})

    const currentTab = { name: "Invoices", status: { 1: "Un Paid", 2: "Paid", 99: "All Data" }};

    return (
        <>
        {isError ? <ErrorMessage title={title} message={error?.message} /> :
            <>
            {  isLoading ? 
                <LoadingComponent loading={ isLoading } /> 
                : 
                <Layout title={title} backToHome="true">
                    <Box sx={data?.pages[0]?.data?.length !== 0 ? { borderBottom: 1, borderColor: 'divider' } : {}}>

                        <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={parseInt(status)} onChange={(event, newStatus) => { navigate("/invoices/" + newStatus) }}>
                            <Tab label="Unproccessed" />
                            <Tab label="Unpaid" />
                            <Tab label="Paid" />
                            <Tab label="Voided" />
                        </Tabs>

                        <ScrollComponent data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} Item={Item}  />
                    </Box>

                    <FilterForm currentTab={currentTab} navigateURL={"/filtered_invoices"} />

                    { portal == "supplier" && 
                        <UploadButton buttonType="fab" title="Invoice" voucher_type="invoices" refetch={refetch}
                            createdInAreas={[ 
                                {label : portal == "supplier" ? "Supplier App" : "Buyer App"},
                                {label : "Add Button"},
                            ]}
                        />
                    }

                    { portal == "supplier" ? <BackToTop /> : <BackToTop alone={true}/> }                 
                    
                </Layout>
            }
            </>
        }
        </>
    )
}