import React, { useState, createContext } from "react";
  
export const FilterDataContext = createContext();

export const FilteredDataProvider = ({children}) => {

    // filter for checkins

    const [filterDataBuyer, setFilterDataBuyer] = useState(undefined);
    const [filterDataSupplier, setFilterDataSupplier] = useState(undefined);
    const [filterDataFromDate, setFilterDataFromDate] = useState("");
    const [filterDataToDate, setFilterDataToDate] = useState("");
    const [filterDataStatus, setFilterDataStatus] = useState(0);
    
    // filter for plans

    const [plansFromDate, setPlansFromDate] = useState("")
    const [plansToDate, setPlansToDate] = useState("")

    // filter for Query

    const [filterDataQuery, setFilterDataQuery] = useState("")

    function clearContextState() {
        setFilterDataBuyer("");
        setFilterDataSupplier("");
        setFilterDataFromDate("");
        setFilterDataToDate("");
        setFilterDataStatus("");
        setPlansFromDate("");
        setPlansToDate("");
        setFilterDataQuery("");
    }

    const value = {
        filterDataBuyer, setFilterDataBuyer, filterDataSupplier, setFilterDataSupplier, filterDataFromDate, setFilterDataFromDate,
        filterDataToDate, setFilterDataToDate, filterDataStatus, setFilterDataStatus, clearContextState, plansFromDate, setPlansFromDate,
        plansToDate, setPlansToDate, filterDataQuery, setFilterDataQuery
    }

    return(
        <FilterDataContext.Provider value={value}>
            {children}
        </FilterDataContext.Provider>
    )

}