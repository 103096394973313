import * as XLSX from 'xlsx';

export const exportToExcel = (tableRef, filename="report") => {
    

    const workbook = XLSX.utils.table_to_book(tableRef?.current, { sheet: "Sheet 1" });

    if(!window.cordova){
        console.log("web environment")
        XLSX.writeFile(workbook, `${filename.toLowerCase()?.split(' ')?.join('_')}_${Date.now()}` + '.xlsx');
    }else{
        // Use type: 'base64' to get base64-encoded data
        const base64Data = XLSX.write(workbook, { bookType: 'xlsx', type: 'base64' });

        // Convert base64 to Uint8Array
        const uintArray = new Uint8Array([...atob(base64Data)].map(char => char.charCodeAt(0)));

        // Create Blob
        const blobData = new Blob([uintArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });


        console.log({workbook, base64Data, uintArray, blobData});

        var dir = cordova.file.externalRootDirectory + 'Download/';
        if(device.platform == "iOS") {
            var dir = cordova.file.documentsDirectory;
          }

        // Get the cordova file system
        window.resolveLocalFileSystemURL(dir, function(directoryEntry) {
            
            directoryEntry.getFile(`${filename.toLowerCase().split(' ').join('_')}_${Date.now()}.xlsx`, { create: true }, function(fileEntry) {
            
            fileEntry.createWriter(function(writer) {
                // Write the Blob data to the file
                writer.write(blobData);
        
                writer.onwriteend = function() {
                cordova.plugins.fileOpener2.open(
                    fileEntry?.nativeURL,    // file path
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // mime type
                    {
                    error: function(error) {
                        console.error('Error opening file: ', error);
                        if(error.status == 9) {
                            alert("Cannot preview this file type. Download excel app from store.")
                        }
                    },
                    success: function() {
                        console.log('File opened successfully');
                    },
                    }
                );
                };
        
                writer.onerror = function(error) {
                console.error('Error writing file: ', error);
                };

            });
            });
        });
    }
}