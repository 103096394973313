import { Box, Grid, Paper, Tab, Tabs, Typography, Button, Chip } from "@mui/material";
import axios from "axios";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import BackToTop from "../../components/BackToTop";
import ErrorMessage from "../../components/ErrorMessage";
import FilterForm from "../../components/FilterForm";
import Layout from "../../components/Layout";
import LoadingComponent from "../../components/LoadingComponent";
import ScrollComponent from "../../components/ScrollComponent";
import TicketUploader from "../../components/TicketUploader";
import { GlobalContext } from "../../contexts/GlobalContext";

export default function TicketsPage() {

    let { status } = useParams();
    const navigate = useNavigate();
    const { url,customer, token, portal } = useContext(GlobalContext);
    const title = "Tickets";

    const fetcher = async (page, pageStatus) => {        
        const response = await axios.post( url + 'api/tickets?page=' + page, {
            customer: customer,
            buyer: portal == "buyer" ? customer : "",
            supplier: portal == "supplier" ? customer : "",
            status: pageStatus,
            from_date: "",
            to_date: "",
            creator: portal == "supplier" ? 2 : 3,
        }, { headers: { Authorization: `Bearer ${token}` }})

        return response.data
    }

    const { isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery( 
        ['tickets', status],  
        ({ pageParam = 1 }) => fetcher(pageParam, status),
        {
          getNextPageParam: (lastPageData) => { 
            if(lastPageData?.current_page < lastPageData.last_page){
                return lastPageData?.current_page + 1
            }else{
                return undefined
            } 
          },
          refetchOnWindowFocus: false,
        }
    );

    const Item = ({data}) => {

        return (
            <Paper onClick={() => { navigate("/ticket/" + data?.id) }} sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                
                <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>                    
                    {(portal == "supplier" ) ?
                        ( data?.buyer_name ?
                            <>
                                <Typography variant="overline" component="p">{"Buyer"}</Typography>
                                <Typography variant="h6" component="h6">{data?.buyer_name}, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{ data?.buyer_city_details?.name }</span></Typography>
                                {
                                    !!data?.buyer_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.buyer_msme} /></Box>
                                }
                            </> :
                            <Chip label="Rasiklal Team" />
                        )
                        :
                        <></>
                    }

                    {(portal == "buyer") ?
                        ( data?.seller_name ?                
                            <>
                                <Typography variant="overline" component="p">{"Supplier"}</Typography>
                                <Typography variant="h6" component="h6">{data.seller_name}, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{ data?.seller_city_details?.name }</span></Typography>
                                {
                                    !!data?.supplier_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.supplier_msme} /></Box>
                                }
                            </> : 
                            <Chip label="Rasiklal Team" />
                        )
                        :
                        <></>
                    }                    
                </Box>
                <Grid container>
                    <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Ticket #</Typography>
                        <Typography variant="body" component="p">{data.ticket_no ? data.ticket_no : '-'}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Date</Typography>
                        <Typography variant="body" component="p">{data.date ? format(parse(data.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : '-'}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Subject</Typography>
                        <Typography variant="body" component="p">{data.subject ? data.subject : ''}</Typography>
                    </Grid>
                </Grid>
                <Box sx={{margin: "5px"}}>
                    <Button variant="contained" onClick={() => { navigate("/ticket/" + data.id) }} fullWidth>View Tickets</Button>
                </Box>
            </Paper>
        )
    }

    // console.log({isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch})

    const currentTab = { name: "Tickets", status: { 0: "Open", 1: "Closed", 99: "All Data",}};

    return (
        <>
        {isError ? <ErrorMessage title={title} message={error?.message} /> :
            <>
            {  isLoading ? 
                <LoadingComponent loading={ isLoading } />
                : 
                <Layout title={title} backToHome="true">
                    <Box sx={data?.pages[0]?.data?.length !== 0 ? { borderBottom: 1, borderColor: 'divider' } : {}}>

                        <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={parseInt(status)} onChange={(event, newStatus) => { navigate("/tickets/" + newStatus) }}>
                            <Tab label="Open" />
                            <Tab label="Closed" />
                        </Tabs>

                        <ScrollComponent data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} Item={Item}  />
                    </Box>

                    <FilterForm currentTab={currentTab} navigateURL={"/filtered_tickets"} />

                    <TicketUploader refetch={refetch} />

                    <BackToTop />                    
                    
                </Layout>
            }
            </>
        }
        </>
    )
}