import { LoadingButton } from "@mui/lab";
import { Box, Button, Chip, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Paper, Tab, Tabs, TextField, Typography } from "@mui/material";
import axios from "axios";
import { format, parse } from "date-fns";
import { useContext, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import BackToTop from "../../components/BackToTop";
import ErrorMessage from "../../components/ErrorMessage";
import FilterForm from "../../components/FilterForm";
import Layout from "../../components/Layout";
import LoadingComponent from "../../components/LoadingComponent";
import MediaGrid from "../../components/MediaGrid";
import ScrollComponent from "../../components/ScrollComponent";
import UploadMedia from "../../components/UploadMedia";
import Void from "../../components/Void";
import UploadButton from "../../components/VoucherUploader";
import { FilterDataContext } from "../../contexts/FilteredDataContext";
import { GlobalContext } from "../../contexts/GlobalContext";

export default function CustomOrdersPage() {

    let { status } = useParams();
    const navigate = useNavigate();
    const { url, phone, contact, customer, token, portal } = useContext(GlobalContext);
    
    const title = "Custom Orders";

    const fetcher = async (page, pageStatus) => {        
        const response = await axios.post( url + 'api/customorders?page=' + page, {
            customer: customer,
            buyer: portal == "buyer" ? customer : "",
            supplier: portal == "supplier" ? customer : "",
            status: pageStatus,
            from_date: "",
            to_date: "",
        }, { headers: { Authorization: `Bearer ${token}` }})

        return response.data
    }

    const { isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery( 
        ['customOrders', status],  
        ({ pageParam = 1 }) => fetcher(pageParam, status),
        {
          getNextPageParam: (lastPageData) => { 
            if(lastPageData?.current_page < lastPageData.last_page){
                return lastPageData?.current_page + 1
            }else{
                return undefined
            } 
          },
          refetchOnWindowFocus: false,
        }
    );

    // console.log({isLoading, data, error, isError, fetchNextPage, hasNextPage})

    const currentTab = { name: "CustomOrders", status: {  1: "Ordered", 2: "Completed", 99: "All Data",}};

    const Item = ({data}) => {

        var reason = '';
        if(data?.approval == 2 && data?.approval_log) {
            reason = JSON.parse(data?.approval_log)[0]?.reason || "-";
        }

        function redirectOnClick () {
            if((data?.status_label ==="Ordered") || (data?.status_label ==="Completed")) { 
                return navigate("/order/" + data?.id) 
            }
            else{
                return navigate("/order/" + data?.voucher_id) 
            }
        }

        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer',width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4, }}>
                <Box>                    
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                        <Typography variant="overline" component="p">{portal == "supplier" ? "Buyer" : "Supplier"}</Typography>
                        <Typography variant="h6" component="h6">{ portal == "supplier" ? data.buyer_name : data.seller_name }, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{ portal == "supplier" ? data.buyer_city_details?.name : data.seller_city_details?.name }</span></Typography>
                        {
                            portal === "buyer" && !!data?.supplier_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.supplier_msme} /></Box>
                        }
                        {
                            portal === "supplier" && !!data?.buyer_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.buyer_msme} /></Box>
                        }
                    </Box>
                    <Grid container>
                        {
                            (status != 0 || data?.status_label ==="Ordered" || data?.status_label ==="Completed" ) ?
                            <>
                                <Grid item xs={4} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                    <Typography variant="overline" component="p">Order #</Typography>
                                    <Typography variant="body" component="p">{ data?.voucher_no || '-'}</Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                    <Typography variant="overline" component="p">Date</Typography>
                                    <Typography variant="body" component="p">{data?.date ? format(parse(data?.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                    <Typography variant="overline" component="p">Total</Typography>
                                    {data.total ? 
                                    <Typography variant="body" component="p">&#8377; {data?.total} </Typography> 
                                    :
                                    <Typography variant="body" component="p">-</Typography> 
                                    }
                                </Grid>
                            </>
                            :
                            <></>
                        }

                        { data?.status == 1 && data?.approval == 0 &&
                            <Grid item xs={12} sx={{ px: 1, py:1, borderBottom: 1, borderColor: 'divider', }}>
                                <Typography variant="overline" component="p">Date</Typography>
                                <Typography variant="body" component="p">{data.date ? format(parse(data.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                            </Grid>
                        }

                        <Grid item xs={12} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Remarks</Typography>
                            <Typography variant="body" component="p">{data?.remarks ? data.remarks : '-'}</Typography>
                        </Grid>

                        {
                            data?.approval == 2
                            ?
                            <Grid item xs={12} sx={{ borderTop: 1, borderColor: 'divider',px: 1, py:1 }}>
                                <Typography variant="overline" component="p">Rejection Reason</Typography>
                                <Typography variant="body" component="p">{reason ? reason : '-'}</Typography>
                            </Grid>
                            :
                            <></>
                        }
                    </Grid>
                </Box>

                <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%' }}>
                    <MediaGrid dir="orders" images={data?.images}  />
                </Box>

                {data?.status_label !== "Voided" &&
                <Box>
                {
                    portal == "buyer" && data?.approval == 0 &&
                    <Box sx={{display: "flex", gap:1, borderTop: 1, borderColor: "divider", paddingTop: "5px"}}>
                        <Box sx={{paddingLeft:1, flex: 1}}>
                            <Button variant="contained" fullWidth onClick={redirectOnClick}>Order Chat</Button>
                        </Box>
                        <Box sx={{paddingRight:1, flex: 1}}>
                            <UploadMedia 
                                id={data.id} module="entry"
                                dir="orders" link={url + 'api/media/add'}
                                refetch={refetch}
                            />
                        </Box>
                    </Box>
                }

                { portal == "supplier" && data?.approval == 0  &&
                    <Box sx={{ borderTop: 1, borderColor: 'divider' }}>
                        <CustomOrderActions approval={data.approval} id={data.id} />
                        <Box sx={{p:1, borderTop: 1, borderColor: 'divider',}}>
                            <Button variant="contained" fullWidth onClick={redirectOnClick}>Order Chat</Button>
                        </Box>
                    </Box>
                }

                { (data?.status_label ==="Ordered") &&
                    <Box sx={{  display: 'flex', justifyContent:"center", alignContent: "stretch", flexDirection: 'row', gap: 1, borderTop: 1, borderColor: 'divider', width: '100%', px: 1, py: 1 }}>
                        <Box sx={{flex: 1, flexGrow: 1}}>
                            <Button variant="contained" sx={{height: "100%"}} fullWidth onClick={()=>{navigate("/order/view/" + data?.id)}} >View Order</Button>
                        </Box>
                        <Box sx={{flex: 1, flexGrow: 1}}>
                            <Button variant="contained" sx={{height: "100%"}} fullWidth onClick={redirectOnClick} >Order Chat</Button>
                        </Box>
                        <Box sx={{flex: 1, flexGrow: 1}}>
                            <Void styleObj={{height: "100%"}} refetch={refetch} id={data.id} type={ 'voucher' } voided={data.status != 3 ? 0 : 1 } />
                        </Box>                         
                    </Box>
                }

                { ( data?.approval == 2 || data?.status == 6 ||  data?.status_label ==="Completed") &&
                    <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%', px: 1, py: 1, display: "flex", gap:1}}>
                        {data?.status == 6 &&
                            <Box sx={{flex: 1}}>
                                <Button variant="contained" fullWidth onClick={()=>{navigate("/order/view/" + data?.id)}} >View Order</Button>
                            </Box>
                        }
                        <Box sx={{flex: 1}}>
                            <Button variant="contained" fullWidth onClick={redirectOnClick} >Order Chat</Button>
                        </Box>
                    </Box>
                }
                </Box>
                }
            </Paper>
        )
    };

    const CustomOrderActions = ({approval, id}) => {

        const [loading, setLoading] = useState(false);
        
        const [open, setOpen] = useState(false)
        const [acceptModalOpen, setAcceptModalOpen] = useState(false)
        const [reason, setReason] = useState("")
        
        const handleApproval = (event) => {
            event.preventDefault();
            setLoading(true)
            axios.post( url + 'api/suppliers/customorders/approval', {
                customer: customer,
                contact: contact,
                phone: phone,
                id: id,
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                if(response?.data?.error == 0) {
                    setLoading(false)
                    setOpen(false);
                    setAcceptModalOpen(false);
                    refetch();
                } else {
                    alert(response?.data?.message);
                    setLoading(false)
                    setOpen(false);
                    setAcceptModalOpen(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false)
                setOpen(false);
                setAcceptModalOpen(false);
            });
        }     

        const handleRejection = (event) => {
            event.preventDefault();
            setLoading(true)
            axios.post( url + 'api/suppliers/customorders/rejection', {
                customer: customer,
                contact: contact,
                phone: phone,
                id: id,
                reason: reason,
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                if(response?.data?.error == 0) {
                    setLoading(false)
                    setOpen(false);
                    refetch();
                } else {
                    alert(response?.data?.message);
                    setLoading(false)
                    setOpen(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false)
                setOpen(false);
            });
        }    

        return (
        <>
            { portal == "supplier" && approval == 0 &&
                <Grid container >
                    <Grid item xs={6} sx={{  paddingLeft: 1, paddingRight: 0.5, py:1 }}>
                        <Button variant="contained" color="success" onClick={() => {setAcceptModalOpen(true)}} fullWidth>Accept</Button>
                    </Grid>
                    <Grid item xs={6} sx={{ paddingLeft: 0.5, paddingRight: 1, py:1 }}>
                        <Button variant="contained" color="error" onClick={() => {setOpen(true)}} fullWidth>Reject</Button>
                    </Grid>
                </Grid>
            }

            <Dialog onClose={() => { setOpen(false) }} open={open}>
                <DialogTitle>Reject Order</DialogTitle>
                <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                    <DialogContentText sx={{marginBottom: "5px"}}>
                        To reject this custom order, please enter the reason for rejection.
                    </DialogContentText>
                    <form onSubmit={handleRejection}>
                        <TextField sx={{ mb: 2 }} fullWidth required={true} 
                            value={reason}
                            onChange={(event) => {
                                const { value } = event.target;
                                setReason(value);
                            }} />
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                            <LoadingButton loading={loading} type="submit" variant="contained">Reject</LoadingButton>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>

            <Dialog onClose={() => {setAcceptModalOpen(false)}} open={acceptModalOpen}>
                <DialogTitle>Accept Order</DialogTitle>
                <DialogContent sx={{ width: '80vw', maxWidth: '500px' }}>
                    <Typography>Do you accept this order?</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, mt: 2 }}>
                        <Button type="submit" variant="contained" color="error" onClick={() => {setAcceptModalOpen(false)}}>Close</Button>
                        <LoadingButton loading={loading} type="submit" variant="contained"  onClick={handleApproval}>Accept</LoadingButton>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
        )
    }

    return (
        <>
        {isError ? <ErrorMessage title={title} message={error?.message} /> :
            <>
            {  isLoading ? 
                <LoadingComponent loading={ isLoading } />
                : 
                <Layout title={title} backToHome="true">
                    <Box sx={data?.pages[0]?.data?.length !== 0 ? { borderBottom: 1, borderColor: 'divider' } : {}}>

                        <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={parseInt(status)} onChange={(event, newStatus) => { navigate("/customorders/" + newStatus) }}>
                            <Tab label="Approval" />
                            <Tab label="Ordered" />
                            <Tab label="Completed" />
                            <Tab label="Rejected" />
                            <Tab label="Voided" />
                        </Tabs>

                        <ScrollComponent data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} Item={Item}  />
                    </Box>

                    <FilterForm currentTab={currentTab} navigateURL={"/filtered_customorders"} />

                    <UploadButton buttonType="fab" title="Order" voucher_type="orders" order_type={4} refetch={refetch}
                        createdInAreas={[ 
                            {label : portal == "supplier" ? "Supplier App" : "Buyer App"},
                            {label : "Add Button"},
                        ]}
                    />

                    <BackToTop />
                    
                </Layout>
            }
            </>
        }
        </>
    )
}