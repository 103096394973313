import { Button, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Layout from "../../components/Layout";
import LoadingComponent from "../../components/LoadingComponent";
import { GlobalContext } from "../../contexts/GlobalContext";
import { format, parse } from "date-fns";
import NoData from "../../components/NoData";
import { useNavigate } from "react-router-dom";


 function LedgerStatements () {

    const navigate = useNavigate()

    const { url, customer, token } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);

    

    useEffect(() => {
        setLoading(true)
        setItems([]);
        setHasMore(true);
        setCurrentPage(1);
        fetchData(1);
    }, [])

    function fetchData (page) {
        axios.post( url + 'api/ledgerstatements?page=' + page, {
            customer: customer,            
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            setLoading(true)
            setItems((prev) => [...prev, ...response.data.ledgerStatements.data]);
            setCurrentPage(parseInt(response.data.ledgerStatements.current_page));
            setHasMore(response.data.ledgerStatements.current_page !== response.data.ledgerStatements.last_page);
            setLoading(false)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const Items = ({item}) => {
         
        return (
            <>
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                <Grid container>
                    <Grid item xs={12} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Statement Type</Typography>
                        <Typography variant="body" component="p">{item?.statement_type?.name || "Not Available"}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">From Date</Typography>
                        <Typography variant="body" component="p">{item?.from_date ? format(parse(item?.from_date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : 'Not Available'}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">To Date</Typography>
                        <Typography variant="body" component="p">{item?.to_date ? format(parse(item?.to_date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : 'Not Available'}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Remarks</Typography>
                        <Typography variant="body" component="p">{item?.remarks || "Not Available"}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, pt:1 }}>
                        <Button variant="contained" sx={{padding: "10px", marginBottom: "5px",}} onClick={() => { navigate( "/ledgerstatement/"+ item?.id )}}>
                            Upload statement
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            </>
        )
    }

    return (
        <>
        {  loading ? 
            <LoadingComponent loading={loading} />
            :
            <>
            <Layout title={"Ledger Statements"}>
                <Box>
                    {(items?.length !== 0) ?
                        <InfiniteScroll scrollThreshold={0.5} dataLength={items.length} next={() => fetchData(currentPage + 1)} style={{ display: 'flex', flexDirection: 'column' }} inverse={false} hasMore={hasMore} loader={<p style={{ textAlign: 'center', fontSize: '14px' }}>Loading...</p>} endMessage={ items.length !== 0 ? <p style={{ textAlign: 'center', fontSize: '14px' }}>You have reached end of the list</p> : <></>}>
                            {items?.length != 0 && items.map((item, index) => { return <Items item={item} key={index} /> })}
                        </InfiniteScroll>
                        :
                        <NoData />
                    }
                </Box>
            </Layout>
            </>
        }        
        </>
    )
 }

 export default LedgerStatements;