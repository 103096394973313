import { Box, Grid, Paper, Tab, Tabs, Typography, Button, Chip } from "@mui/material";
import axios from "axios";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import BackToTop from "../../components/BackToTop";
import ErrorMessage from "../../components/ErrorMessage";
import FilterForm from "../../components/FilterForm";
import Layout from "../../components/Layout";
import LoadingComponent from "../../components/LoadingComponent";
import MediaGrid from "../../components/MediaGrid";
import ScrollComponent from "../../components/ScrollComponent";
import UploadButton from "../../components/VoucherUploader";
import { GlobalContext } from "../../contexts/GlobalContext";

export default function PaymentsPage() {

    let { status } = useParams();
    const navigate = useNavigate();
    const { url,customer, token, portal } = useContext(GlobalContext);
    const title = "Payments";

    const fetcher = async (page, pageStatus) => {        
        const response = await axios.post( url + 'api/payments?page=' + page, {
            customer: customer,
            buyer: portal == "buyer" ? customer : "",
            supplier: portal == "supplier" ? customer : "",
            status: pageStatus,
            from_date: "",
            to_date: "",
            creator: portal == "supplier" ? 2 : 3,
        }, { headers: { Authorization: `Bearer ${token}` }})

        return response.data
    }

    const { isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery( 
        ['payments', status],  
        ({ pageParam = 1 }) => fetcher(pageParam, status),
        {
          getNextPageParam: (lastPageData) => { 
            if(lastPageData?.current_page < lastPageData.last_page){
                return lastPageData?.current_page + 1
            }else{
                return undefined
            } 
          },
          refetchOnWindowFocus: false,
        }
    );

    const Item = ({data}) => {
        return (
            <Paper  sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                
                <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                    <Typography variant="overline" component="p">{portal == "supplier" ? "Buyer" : "Supplier"}</Typography>
                    <Typography variant="h6" component="h6">{ portal == "supplier" ? data.buyer_name : data.seller_name }, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{ portal == "supplier" ? data.buyer_city_details?.name : data.seller_city_details?.name }</span></Typography>
                    {
                        portal === "buyer" && !!data?.supplier_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.supplier_msme} /></Box>
                    }
                    {
                        portal === "supplier" && !!data?.buyer_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.buyer_msme} /></Box>
                    }
                </Box>
                <Grid container sx={{ borderColor: 'divider' }}>
                    <Grid item xs={6} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Date</Typography>
                        <Typography variant="body" component="p">{data?.date ? format(parse(data.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Total</Typography>
                        {!!data?.total ? 
                            <Typography variant="body" component="p">&#8377; {data.total}</Typography> :
                            <Typography variant="body" component="p">{"-"}</Typography>
                        }
                    </Grid>
                    {!!data?.remarks &&
                        <Grid item xs={12} sx={{ borderTop: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Remarks</Typography>
                            <Typography variant="body" component="p">{data?.remarks || "-"}</Typography>
                        </Grid>
                    }
                    {!!data?.narration &&
                        <Grid item xs={12} sx={{ borderTop: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Remarks</Typography>
                            <Typography variant="body" component="p">{data?.narration || "-"}</Typography>                        
                        </Grid>
                    }
                </Grid>
                <Box sx={{ borderTop: 1, borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                    <MediaGrid dir="payments" images={data?.images} />
                </Box>
                {(!!data?.payment_no || data?.status_label == "pending" || data?.status_label == "completed") &&
                    <Box sx={{margin: "5px"}}>
                        <Button variant="contained" onClick={() => {navigate("/payment/" + data?.id)}} fullWidth>View Payments</Button>
                    </Box>
                }
            </Paper>
        )
    }

    // console.log({isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch})

    const currentTab = { name: "Payments", status: { 1: "Pending", 2: "Completed", 99: "All Data" }};    

    return (
        <>
        {isError ? <ErrorMessage title={title} message={error?.message} /> :
            <>
            {  isLoading ? 
                <LoadingComponent loading={ isLoading } />
                : 
                <Layout title={title} backToHome="true">
                    <Box sx={data?.pages[0]?.data?.length !== 0 ? { borderBottom: 1, borderColor: 'divider' } : {}}>

                        <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={parseInt(status)} onChange={(event, newStatus) => { navigate("/payments/" + newStatus) }}>
                            <Tab label="Unprocessed" />
                            <Tab label="Pending" />
                            <Tab label="Completed" />
                            <Tab label="Voided" />
                        </Tabs>

                        <ScrollComponent data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} Item={Item}  />
                    </Box>

                    <FilterForm currentTab={currentTab} navigateURL={"/filtered_payments"} />

                    <UploadButton buttonType="fab" title="Payment" voucher_type="payments" refetch={refetch}
                    createdInAreas={[ 
                        {label : portal == "supplier" ? "Supplier App" : "Buyer App"},
                        {label : "Add Button"},
                    ]}
                    />

                    <BackToTop />                    
                    
                </Layout>
            }
            </>
        }
        </>
    )
}