import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react"
import Layout from "../../../components/Layout";
import LoadingComponent from "../../../components/LoadingComponent";
import { GlobalContext } from "../../../contexts/GlobalContext";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownloadPDF from "../../../components/DownloadPDF";
import DownloadExcel from "../../../components/DownloadExcel";
import { Box } from "@mui/system";
import NoData from "../../../components/NoData";
import { Alert, Button, InputLabel, TextField, Typography, useMediaQuery } from "@mui/material";
import { format, parseISO } from "date-fns";

export default function VisitPerformance() {

    const { url,  customer, token, portal  } = useContext(GlobalContext);

    const title = `Visit Performance By ${portal==="buyer" ? "Supplier" : "Buyer"}`
    
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null); 

    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [selectedFromDate, setSelectedFromDate] = useState("");
    const [selectedToDate, setSelectedToDate] = useState("");
    const [content, setContent] = useState("")

    const isMobile = useMediaQuery('(max-width:375px)');
    
    const tableRef = useRef();

    const printString = portal === "buyer" ? `Buyer : ${data?.buyer?.name}` : `Supplier : ${data?.supplier?.name}`;

    useEffect(() => {

        if(isFilterApplied){

            let fetchData;
            setLoading(true)

            if(portal === "supplier"){
                fetchData = () => {
                axios.post( url + "api/suppliers/visit/performance/bysuppliers/" + customer , {
                    buyer: portal === 'buyer' ? customer : "" ,
                    supplier: portal === 'supplier' ? customer : "",
                    from_date: selectedFromDate,
                    to_date: selectedToDate                
                }, { headers: { Authorization: `Bearer ${token}` }})
                .then(function (response) {
                    // console.log({response})
                    setData(response?.data)
                    setLoading(false)
                })
                .catch(function (error) {
                    console.log(error);
                });
                };
            }else if(portal === "buyer"){
                fetchData = () => {
                    axios.post( url + "api/buyers/visit/performance/bybuyers/" + customer , {
                        buyer: portal === 'buyer' ? customer : "" ,
                        supplier: portal === 'supplier' ? customer : "",
                        from_date: selectedFromDate,
                        to_date: selectedToDate                
                    }, { headers: { Authorization: `Bearer ${token}` }})
                    .then(function (response) {
                        // console.log({response})
                        setData(response?.data)
                        setLoading(false)
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                    };
            }

            fetchData()
        }
    }, [isFilterApplied])

    const ReportsTable = () => {

        const ReportsTableDataRow = ({item, index}) => { 
            
            // console.log({item})

            if(!!!item){
                return false;
            }

            return(
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="left">{index+1}</TableCell>
                    <TableCell align="left">{item?.name}, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{item?.city_name}</span></TableCell>
                    <TableCell align="left">{item?.total_visits}</TableCell>
                    <TableCell align="left">{item?.pending}</TableCell>
                    <TableCell align="left">{item?.checked_in}</TableCell>
                    <TableCell align="left">{item?.order_placed}</TableCell>
                    <TableCell align="left">{item?.visit_canceled}</TableCell>
                    <TableCell align="left">{item?.not_ordered}</TableCell>
                    <TableCell align="left">{item?.not_visited}</TableCell>
                </TableRow>
            )
        }

        return(
            <TableContainer component={Paper}>
                <Table ref={tableRef}>
                    <TableHead>
                        <TableRow sx={{fontWeight: "bold"}}>
                            <TableCell sx={{fontWeight: "bold"}}>S.No.</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>{portal === "supplier" ? "Buyer" : "Supplier"}</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Total Visit</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Pending</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Checked-in</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Order Placed</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Visit Cancelled</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Not Ordered</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Not Visited</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { 
                            Object.values(data?.visits)?.length !== 0 &&
                            Object.values(data?.visits)?.sort((a, b) => a?.name.localeCompare(b?.name)).map((item, index) => (<ReportsTableDataRow key={index} item={item} index={index} />))  
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
   

    const FilterComponent = () => {
         
        const [fromDate, setFromDate] = useState("");
        const [toDate, setToDate] = useState(""); 

        const handleApply = () => {
            if(!!fromDate && !!toDate){

                setIsFilterApplied(true);
                setSelectedFromDate(fromDate);
                setSelectedToDate(toDate);
                // console.log("Filter applied" , {fromDate, toDate});
                setContent(`From Date: ${format(parseISO(fromDate), 'dd MMM yyyy')}, To Date: ${format(parseISO(toDate), 'dd MMM yyyy')}`)
            }
        }
        
        const handleClear = () => {
            setFromDate("");
            setToDate("");
            setIsFilterApplied(false);
            setSelectedFromDate("");
            setSelectedToDate("");
            setContent("");
            setData(null);
        }

        useEffect(() => {
            setFromDate(selectedFromDate);
            setToDate(selectedToDate);
        }, [])

        

        return(
            <Paper elevation={2}>
            <Box sx={{marginBottom: "10px", p:2, minWidth: "280px", maxWidth: "700px" }}>  
                <Typography sx={{mb:2, fontWeight: "bold"}} variant="subtitle2" >Date Filter</Typography>              
                <div style={ isMobile ? {display: "flex", flexDirection: "column", gap: "10px", width: "100%", marginTop:"10px"}: { display: "flex", gap: "10px", width: "100%", marginTop:"10px" }}>
                    <Box sx={{flex: 1, display: "flex", flexDirection: "column"}}>
                        <InputLabel shrink>From Date</InputLabel>
                        <TextField
                        value={fromDate}
                        onChange = {(event) => {setFromDate(event?.target?.value) }}
                        required={true} 
                        helperText={ (!!toDate && !fromDate) ? "Please enter From Date" : "" }
                        FormHelperTextProps={{error: true}}
                        InputProps={{
                            inputProps: {  max: toDate },
                        }}
                        disabled={isFilterApplied} 
                        size="small" type="date" fullWidth
                        />
                    </Box>
                    <Box sx={{flex: 1, display: "flex", flexDirection: "column"}}>
                        <InputLabel shrink>To Date </InputLabel>
                        <TextField
                        value={toDate}
                        onChange = {(event) => { setToDate(event?.target?.value) }}
                        required={true} 
                        helperText={ (!!fromDate && !toDate) ? "Please enter To Date" : "" }
                        FormHelperTextProps={{error: true}}
                        InputProps={{
                            inputProps: { min: fromDate },
                        }}
                        disabled={isFilterApplied} 
                        size="small" type="date" fullWidth
                        />
                    </Box>
                </div>

                <div style={{display: "flex", gap: "5px"}}>
                    <Button variant="contained" disabled={isFilterApplied} sx={{mt: 2}} onClick={handleApply} >Apply</Button>  
                    {isFilterApplied && <Button variant="contained" color="error" disabled={!isFilterApplied} sx={{mt: 2}} onClick={handleClear} >Clear</Button> }
                </div>
            </Box>
            
            </Paper>
        )
    }


    
    return(
        <>
        {  loading ? 
            <LoadingComponent loading={loading} />
            :
            <Layout title={title}>
                <>
                    {isFilterApplied && !!data &&  Object.values(data?.visits)?.length !== 0 &&
                        <Box sx={{mb:2, display: "flex", gap: 1}}>
                            <DownloadPDF tableRef={tableRef} pdfTitle={title} printString={printString} content={content}/>
                            <DownloadExcel tableRef={tableRef} filename={title} sx={{background: "#217346"}} />
                        </Box>
                    }

                    <FilterComponent />
                    {isFilterApplied  && !!data &&
                        <>
                        <Alert sx={{mb:2}} severity="success">
                            <Typography variant="body2" sx={{mb:2}} component="p">Date Filter Applied</Typography>
                            <Box sx={isMobile ? {display: "flex", flexDirection: "column", gap: 1} : {display: "flex", gap: 2}}> 
                                <Typography variant="body2" component="p">From: <b>{ selectedFromDate ? format(parseISO(selectedFromDate), 'dd MMM yyyy') : "" }</b></Typography>
                                <Typography variant="body2" component="p">To: <b>{ selectedToDate ? format(parseISO(selectedToDate), 'dd MMM yyyy') : "" }</b></Typography>
                            </Box>
                        </Alert>
                        </>
                    }

                    {isFilterApplied  && 
                    <>
                        {!!data &&  Object.values(data?.visits)?.length !== 0 ? 
                            <ReportsTable /> : <NoData />
                        }
                    </>
                    }

                    {
                        !isFilterApplied && <NoData />
                    }
                    
                    
                </> 
            </Layout>
        } 
        </>
    )
}
