import React, { useContext, useEffect, useState } from 'react'
import axios from "axios";
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { IconPlus } from '@tabler/icons-react';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { GlobalContext } from '../contexts/GlobalContext'
import { useNavigate } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';

function NewCheckinMultiButton(props) {
    const { url, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken, ledgers, portal  } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [ledger, setLedger] = useState([])
    const [dateTime, setDateTime] = useState("")
    const [remarks, setRemarks] = useState("")

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const [openAlert, setOpenAlert] = useState(false);
    const [skippedSuppliers, setSkippedSuppliers] = useState([]);
    const [reloadRequired, setReloadRequired] = useState(false);

     
    const [fetchedCustomers, setfetchedCustomers] =  useState([]);

    function fetchCustomers(){
        axios.post( url + 'api/linkedcustomers', { 
            customer: customer,
            portal: portal
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            // console.log("response", response);
            console.log("Customer details fetched successfully")
            setfetchedCustomers(response?.data)
        })
        .catch(function (error) {
            console.log(error);
        })
    }

    useEffect(() => { if(open){fetchCustomers()} }, [open]);

    const handleAlertClose = () => {
        reloadRequired && document.location.reload();
        setOpenAlert(false);
        setSkippedSuppliers([]);
        setReloadRequired(false);
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setLedger([])
        setDateTime("")
        setRemarks("")
        setfetchedCustomers([])
    }

    console.log({ledger})

    const handleSubmit = (event) => { 
        event.preventDefault();
        if(ledger?.length > 0){
            setLoading(true);
            axios.post( url + 'api/checkin/multi/add', {
                customer: customer,
                contact: contact,
                phone: phone,
                date_time: dateTime,
                sellers: ledger,
                buyer: customer,
                plan: props.plan,
                remarks,
                creator: portal == "supplier" ? 2 : 3,
                created_in_areas : JSON.stringify(props.createdInAreas),
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                // console.log("response", response);
                if(response.data.error == 0) {
                    setLoading(false);
                    // window.location.reload();
                    // navigate(0);
                    document.location.reload();
                }else if(response.data.error == 1){   
                    setSkippedSuppliers(response?.data?.skipped_suppliers)                 
                    setOpenAlert(true)
                    setLoading(false)
                    handleClose();
                    if(ledger?.length !== response?.data?.skipped_suppliers?.length){
                        setReloadRequired(true)
                    }
                }
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
            })
        }
    }

    return (
        <>
        <>
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>New Visit</DialogTitle>
                <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                    <form onSubmit={handleSubmit}>
                        <InputLabel shrink>Date & Time</InputLabel>
                        <TextField sx={{ mb: 2 }} fullWidth required={true} type="datetime-local" 
                            value={dateTime}
                            onChange={event => {
                                const { value } = event.target;
                                setDateTime(value);
                            }} 
                        />
                        { (props.buyer  == undefined || props.seller == undefined) &&
                            <Box>
                                <InputLabel shrink>{ portal == "supplier" ? "Buyer" : "Supplier"}</InputLabel>
                                <Autocomplete sx={{ mb: 2 }}
                                    multiple
                                    options={fetchedCustomers}
                                    getOptionLabel={(option) => option?.name || '' }
                                    value={ledger}
                                    onChange={(event, newValue) => {
                                        setLedger(newValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Box>
                        }

                        <InputLabel shrink>Remarks</InputLabel>
                        <TextField sx={{ mb: 2 }} fullWidth required={false} 
                            value={remarks}
                            onChange={event => {
                                setRemarks(event.target.value);
                            }} 
                            rows={4}
                            multiline={true}
                        />

                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                            <LoadingButton type="submit" loading={loading} variant="contained">Save</LoadingButton>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
            {props.buttonType=="card" ?
                <Card sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py:4 }} onClick={handleOpen}>
                    { props.icon }
                    <Typography variant="body1" component="p" sx={{ textAlign: 'center'}}>New Check-In</Typography>
                </Card>
                :
                <Fab color="primary" size={"medium"} aria-label="add" onClick={handleOpen} sx={{ position: "fixed", bottom: (theme) => theme.spacing(2), right: (theme) => theme.spacing(2) }}>
                    <IconPlus />
                </Fab>
            }
        </>
            {openAlert &&
                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                    <Alert onClose={handleAlertClose} severity="error" variant="filled"  sx={{ width: '100%' }}>
                        There is already another Visit (or) Checked-in scheduled for this Date, Buyer and Supplier!                    
                        <Box sx={{mt:2}}>The following suppliers are already scheduled: <b>{skippedSuppliers.join(", ")}</b></Box>
                    </Alert>
                </Snackbar>
            }
        </>
    )
}
export default NewCheckinMultiButton;