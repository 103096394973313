import {  Alert, Button, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, Drawer, Grid, IconButton, InputLabel, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Layout from "../../components/Layout";
import LoadingComponent from "../../components/LoadingComponent";
import { GlobalContext } from "../../contexts/GlobalContext";
import { format, parse } from "date-fns";
import NoData from "../../components/NoData";
import { useParams } from "react-router-dom";
import { IconX } from "@tabler/icons-react";
import { useTheme } from "@emotion/react";
import Fancybox from "../../components/Fancybox";
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.css';
import { LoadingButton } from "@mui/lab";
import heic2any from "heic2any";

registerPlugin(FilePondPluginFileRename)
registerPlugin(FilePondPluginFileValidateType);

export default function LedgerStatementView() {

    const {id} = useParams();

    const theme = useTheme();

    const { url, customer, contact, token, portal, cdn } = useContext(GlobalContext);    
    
    const [loading, setLoading] = useState(true);
    const [statement, setStatement] = useState([]);

    const [buyersData, setBuyersData] = useState([]);
    const [suppliersData, setSuppliersData] = useState([]);
    
    const [query, setQuery] = useState("");
    const [fileValidation, setFileValidation] = useState(true)

    useEffect(() => {
        const fetchStatements = (statementId) => {
            axios.post( url + 'api/ledgerstatements/view', {
                customer: customer,
                id: statementId            
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setStatement(response?.data?.statement)
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            });            
        };

        fetchStatements(id)
    }, [])


    const fetchBuyersList = (statementId) => {
        axios.post( url + 'api/ledgerstatements/buyers', {
            customer: customer,
            id: statementId,            
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            setBuyersData(response?.data)
            setLoading(false)
        })
        .catch(function (error) {
            console.log(error);
        });            
    }

    const fetchSuppliersList = (statementId) => {
        axios.post( url + 'api/ledgerstatements/suppliers', {
            customer: customer,
            id: statementId            
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            setSuppliersData(response?.data)
            setLoading(false)
        })
        .catch(function (error) {
            console.log(error);
        });            
    }

    useEffect (() => {
        if(portal === "supplier"){
            fetchBuyersList(id)
        }else if(portal === "buyer"){
            fetchSuppliersList(id)
        }
    }, [])

    const BaseInfo = () => {
        return (
        <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%',}}>
            <Box>
                <Typography variant="body" component="p" sx={{ p: 2, borderBottom: 1, borderColor: "divider", fontWeight: "bold"}}>Base Info</Typography>
            </Box>
            <Grid container>
                <Grid item xs={4} sx={{p:2}}>
                    <Typography variant="overline" component="p" sx={{mb:1}}>Customer</Typography>
                    <Typography variant="body2" component="p">{ statement?.customer_details?.name || "" }, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{ statement?.customer_details?.city_details?.name || "" }</span></Typography>                                
                </Grid>
                <Grid item xs={4} sx={{p:2}}>
                    <Typography variant="overline" component="p" sx={{mb:1}}>Statement Type</Typography>
                    <Typography variant="body2" component="p">{ statement?.statement_type?.name || "" }</Typography>                                
                </Grid>
                <Grid item xs={4} sx={{p:2}}>
                    <Typography variant="overline" component="p" sx={{mb:1}}>From Date</Typography>
                    <Typography variant="body2" component="p">{ statement?.from_date ? format(parse(statement?.from_date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : "" }</Typography>                                
                </Grid>
                <Grid item xs={4} sx={{p:2}}>
                    <Typography variant="overline" component="p" sx={{mb:1}}>To Date</Typography>
                    <Typography variant="body2" component="p">{ statement?.to_date ? format(parse(statement?.to_date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : "" }</Typography>                                
                </Grid>
                <Grid item xs={8} sx={{p:2}}>
                    <Typography variant="overline" component="p" sx={{mb:1}}>Remarks</Typography>
                    <Typography variant="body2" component="p">{ statement?.remarks || "Not Available" }</Typography>                                
                </Grid>
            </Grid>
        </Paper>
        )
    }

    const TableView = ({data}) => { 

        const bulkUpload = data.filter(item => item?.id == 1)
        
        const [filteredData, setFilteredData] = useState([]);
        const [drawerOpen, setDrawerOpen] = useState(false);
        const [selectedCustomer, setSelectedCustomer] = useState(null);

        function handleDrawerOpen(item) {
            setDrawerOpen(true); 
            setSelectedCustomer(item)
        }
        
        function handleDrawerClose() {
            setDrawerOpen(false); 
            setSelectedCustomer(null)
        }
        
        useEffect(() => {
            if(query.length > 0){
                    const resultData = data?.filter((item, index) => {
                        return item?.name?.toLowerCase()?.includes(query?.toLowerCase())
                    })
                    setFilteredData(resultData)
                }      
        }, [query])

        const MediaGrid = ({statementId, customerId, customerType}) => {

            const [filesLoading, setFilesLoading] = useState(true);
            const [filesList, setFilesList] = useState([]);

            const openLink = (link) => {
                if(!window.cordova) {
                    window.open(link, '_blank').focus();
                } else {
                    cordova.InAppBrowser.open(link, "_system");
                }
            }

            function fetchFiles (statementId, customerId, customerType) {
                axios.post( url  + "api/ledgerstatements/view/files" , {
                    id: statementId,
                    customer_id: customerId,
                    customer_type: customerType
                }, {headers: {Authorization: `Bearer ${token}`}})
                .then(function (response) {
                    setFilesList(response?.data)
                    setFilesLoading(false)
                })
                .catch(function (error) {
                    console.log(error);
                    setFilesLoading(false)
                });
            }

            const imageList  = filesList?.filter(function(item) {
                return item?.type === "image" 
            })

            const docsList  = filesList?.filter(function(item) {
                return item?.type === "docs" 
            })

            useEffect(() => {
                fetchFiles(statementId, customerId, customerType)
            }, [])

            return (
                <Box sx={{height: "200px", display: "flex", flexDirection: "column"}}>                    
                    
                        <Alert icon={false} severity="info">
                            {selectedCustomer?.id !=1 ?
                                <> <b>{portal === "buyer" ? "Supplier" : "Buyer"}{": "}</b>{selectedCustomer?.name} </>
                                :
                                <> <b>Bulk Upload</b> </>
                            }
                        </Alert>
                        {filesLoading ? 
                            <Box sx={{ flexGrow: 1, width: "100%", display: "flex", justifyContent: "center", alignItels: "center"}}> 
                                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>                          
                                    <CircularProgress sx={{ color: theme?.palette.primary.main, }} /> 
                                </Box>                           
                            </Box> 
                        :
                            <Box sx={{ flexGrow: 1, display: "flex" }}>                             
                                <Box sx={{width: "100%", overflow: "scroll", display: "flex"}}>
                                    {imageList?.length > 0 && (
                                        <Fancybox options={{ Carousel: { infinite: false } }} >
                                            <Box sx={{margin: "10px 0 10px 10px", display: "flex", flexDirection: "row"}}>
                                            {imageList?.map((image, index) => (
                                            <Box key={index} sx={{ height: "120px", width: "120px", background: theme?.palette.background.default, marginRight: "10px", borderRadius: "20px" }}>
                                                <a data-fancybox="gallery" href={cdn + image?.path}>
                                                    <img src={cdn + image?.path} width="120" height="120" alt={`Image ${index + 1}`} style={{borderRadius: "20px", objectFit: "cover"}} />
                                                </a>
                                            </Box>
                                            ))}
                                            </Box>
                                        </Fancybox>
                                    )}
                                    {docsList?.length > 0 && (
                                    <Box sx={{margin: "10px", display: "flex", flexDirection: "row" }}>
                                        {docsList.map((doc, index) =>  (
                                                <Box key={index} href={cdn + doc?.path} onClick={() => {openLink(cdn + doc?.path)}}
                                                sx={{ border: "1px solid #A9A9A9", width: "120px", height: "120px",  marginRight: "10px",  borderRadius: "20px", display:  "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <Chip label={doc?.path?.split('.').pop().toUpperCase()} variant="outlined" />                                                    
                                                </Box>
                                            )
                                        )}
                                    </Box>
                                    )}                                    
                                </Box>    
                            </Box> 
                        }
                    
                </Box>
              
            )
        }

        const Uploader = ( {customerData, uploaderOpen, setUploaderOpen, statementId } ) => {

            const [loading, setLoading] = useState(false);
            const [files, setFiles] = useState([]);
            const [pond, setPond] = useState(null);
            const [fileList, setFileList] = useState([]);

            function handleUploaderClose(){
                setUploaderOpen(false)
                setFiles([]);
                setPond(null);
                setFileList([]);
            }
            
            function handleSubmit(event){
                event.preventDefault()
                setLoading(true)
                // const fileList = []
                // files.forEach(function(file) {
                //     fileList.push({ file: file.filename, type: file.fileType, ext: file.fileExtension } )                    
                // })
                // console.log({fileList})

                if(fileList.length == 0) {
                    alert("Add one or more attachment to create entry")
                } else {
                    const fileNames = files.map( file =>  file.filename);
                    axios.post( url + "api/ledgerstatements/upload", {
                        id: statementId,
                        customer_type : portal === "buyer" ? "supplier" : "buyer",
                        customer_id: customerData?.id,
                        customer_contact_id: contact,
                        files: JSON.stringify(fileList.filter( f => fileNames.includes(f.file))),
                    }, { headers: { Authorization: `Bearer ${token}` }})
                    .then(function (response) {
                        console.log({response});
                        if(response.data.error == 0) {
                            setLoading(false);
                            setUploaderOpen(false);
                            setFiles([])
                            document.location.reload();
                        } else {
                            alert(response.data.message);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        setLoading(false)
                    });
                }
            }

            const handleCamera = () => {
                setFileValidation(false);
                navigator.camera.getPicture(function (imageData) {
                    pond.addFile("data:image/jpg;base64," + imageData)
                    .then((file) => {
                        setFiles(prevFiles => [...prevFiles, file]);
                        setFileValidation(true);
                        console.log("file", file);
                    }).catch((error) => {
                        setFileValidation(true);
                        console.log(error);
                    });
                }, function (message) {
                    setFileValidation(true);
                    alert(message);
                }, {
                    quality: 50,
                    destinationType: Camera.DestinationType.DATA_URL,
                    encodingType: Camera.EncodingType.JPEG,
                    mediaType: Camera.MediaType.PICTURE,
                    correctOrientation: true
                });
            };

            const fileRenameFunction = (file) => {
                if(file.extension == file.name) {
                    return `f_${Math.random().toString(36).slice(2, 7)}_${(+new Date).toString(36).slice(-5)}.jpg`;
                } else {
                    return `f_${Math.random().toString(36).slice(2, 7)}_${(+new Date).toString(36).slice(-5)}${file.extension}`;
                }
            }

            const [errorMessage, setErrorMessage] = useState("");
            const [openErrorAlert, setOpenErrorAlert] = useState(false);    

            const ErrorMessage = () => {

                function handleClose(){
                    setOpenErrorAlert(false)
                    setErrorMessage('')
                }

                return(
                    <>
                    <Snackbar open={openErrorAlert}  anchorOrigin={{vertical: 'top', horizontal: 'center',}} 
                        onClose={handleClose}>
                    <Alert
                        onClose={handleClose}
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {errorMessage}
                    </Alert>
                    </Snackbar>
                    </>
                )
            }

            function convertHEICToJPG(file) {
                return heic2any({
                    blob: file,
                    toType: "image/jpeg",
                    quality: 0.6
                });
            }

            function getExtension(fileName) {
                if (fileName.includes('.')) {
                    // Split the fileName by dot and return the last part
                    return '.' + fileName.split('.').pop();
                } else {
                    // If there's no dot, return an empty string indicating no extension
                    return '';
                }
            }

            return (
            <>
            <Dialog open={uploaderOpen} onClose={handleUploaderClose} sx={{minWidth: "300px"}} >
                <DialogTitle sx={{minWidth: "250px"}}>
                    {customerData?.id != 1  && <Typography style={{fontSize: "1.2em", fontWeight: "bold"}}>Uploading Statement for</Typography>}
                    <Box sx={{marginTop: "10px"}}>
                        <Alert icon={false} severity="info">
                        {customerData?.id != 1 ?
                            <> <b>{portal === "buyer" ? "Supplier" : "Buyer"}{": "}</b>{customerData?.name} </>
                            :
                            <> <b>Bulk Upload</b> </>                            
                        }
                        </Alert>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <InputLabel shrink>Attachments</InputLabel>
                        <Alert severity="info" sx={{mb:2}}>
                            <Typography variant="body2" sx={{fontSize: "12px"}}> Allowed File types are jpg, jpeg, heic, png, pdf, xls, xlsx, xlsm, xlsb, ods, csv </Typography>
                        </Alert>
                        <Box sx={{ display: 'flex', justifyContent:"center", alignItems:"center", flexDirection: 'row', gap: 1, mb: 2 }}>
                            { window.cordova &&
                                <Button variant="contained" size="medium" onClick={handleCamera}>Camera</Button>
                            }
                            <Button variant="contained" size="medium" onClick={() => {pond.browse()}}>File</Button>
                        </Box>
                        <FilePond
                            acceptedFileTypes={
                                ["application/pdf",
                                 "image/png", 
                                 "image/jpg", 
                                 "image/jpeg", 
                                 "image/heic", 
                                 'application/vnd.ms-excel',  // .xls
                                 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
                                 'application/vnd.ms-excel.sheet.macroEnabled.12', // .xlsm
                                 'application/vnd.ms-excel.sheet.binary.macroEnabled.12', // .xlsb
                                 "text/csv", //.csv
                                 "application/vnd.oasis.opendocument.spreadsheet", //.ods
                                  ""
                                ]
                            }
                            ref={(ref) => {setPond(ref)}}
                            credits={false}
                            files={files}
                            onupdatefiles={setFiles}
                            fileRenameFunction={ file => { return fileRenameFunction(file)}}
                            allowMultiple={true}
                            // server={ url + "api/pond/process"}
                            server={{
                                url: url,
                                revert: "./api/pond/process",
                                process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                                    const allowedFiles = [".jpg", ".jpeg", ".heic", ".png", ".pdf", ".xls", ".xlsx", ".xlsm", ".xlsb", ".ods", ".csv" ]  
                                    if (fileValidation) {
                                        if(!allowedFiles.includes(getExtension(file.name.toLowerCase()))){
                                            error('Unkown file extension')
                                            setOpenErrorAlert(true)
                                            setErrorMessage('Unsupported file type. Please upload files with the allowed extensions. Allowed File types are jpg, jpeg, heic, png, pdf, xls, xlsx, xlsm, xlsb, ods, csv')
                                            abort()
                                        }
                                    } else {
                                        file.name = file.name + '.jpg';
                                    }
                                    try {
                                        let fn = file.name.toLowerCase()
                                        // Check if the file type is HEIC
                                        if (fn.indexOf('.heic') !== -1) {
                                            // Convert HEIC to JPG
                                            const convertedBlob = await convertHEICToJPG(file);
                                            // Create a new file object from the Blob
                                            const newFile = new File([convertedBlob], file.name.replace(/\.heic$/i, '.jpg'), {
                                                type: "image/jpeg",
                                            });
                                            file = newFile; // Update the file reference
                                        }
                        
                                        // Now upload the file (either original or converted)
                                        // Example using fetch API
                                        const formData = new FormData();
                                        formData.append(fieldName, file, file.name);
                                        const response = await fetch(url + "api/pond/process", {
                                            method: 'POST',
                                            body: formData
                                        });
                        
                                        if (response.ok) {
                                            const data = await response.json();
                                            load(data.name);
                                            let currentFile = { file: data.name, type: data.type, ext: data.ext };
                                            setFileList(currentFiles => [...currentFiles, currentFile]);
                                        } else {
                                            error('Could not upload file');
                                        }
                                    } catch (err) {
                                        error(err.message);
                                    }}
                                
                            }}
                            name="files"
                            labelIdle='No files selected'
                            onaddfile={(error, file) => { 
                                if(!error){
                                    setLoading(true)
                                }
                            }}  
                            onprocessfiles={() => setLoading(false)}   
                          //  onprocessfilerevert={() => {setLoading(true)}}   
                            // onremovefile={() => {
                            //     setLoading(false); 
                            //     setOpenErrorAlert(false); 
                            //     setErrorMessage("")
                            // }} 
                            
                            onerror={(error, file) => {
                                console.log({error, file})
                                // const filesArray = files?.filter((item) => (item?.id !== file?.id));
                                // setFiles(filesArray)
                            }}  
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                            <LoadingButton type="submit" loading={loading} variant="contained">Save</LoadingButton>
                        </Box>
                    </form>   
                    {openErrorAlert &&
                        <>
                        <ErrorMessage />
                        </>
                    }                         
                </DialogContent>
            </Dialog>
            </>
            )
        }

        const UploadMedia = ({customerData, statementId }) => {

            const [uploaderOpen, setUploaderOpen] = useState(false);

            function handleUploaderOpen(){
                setUploaderOpen(true)
            }
            
            return (
                <Box>
                    <Button sx={{mx: 1}} variant="contained" onClick={handleUploaderOpen}>Upload</Button>
                    {uploaderOpen && <Uploader uploaderOpen={uploaderOpen} setUploaderOpen={setUploaderOpen} customerData={customerData} statementId={statementId} /> }
                </Box>
            )
        }


        return(
        <>
        <Box>
            {data?.length > 0 ?  
                <>
                <Box>                    
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead >
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {(!query) && data?.map((item, index) => {
                                        if(item?.id != 1){
                                            return (
                                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 }, }}>
                                                <TableCell>{index+1}</TableCell>
                                                <TableCell component="th" scope="row">
                                                {item.name}, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{item?.city_details?.name}</span>
                                                </TableCell>
                                                <TableCell >
                                                    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                        <UploadMedia customerData={item} statementId={id} />
                                                        <Button variant="contained" sx={{mx:1, whiteSpace: 'nowrap',}} disabled={item?.filecount ? false : true}  onClick={ () => {handleDrawerOpen(item)} }>View ({item.filecount})</Button>
                                                    </Box>
                                                </TableCell>                    
                                            </TableRow>)
                                        }})
                                }
                                {(!!query)  && (filteredData?.length > 0 
                                                ? (filteredData?.map((item, index) => {
                                                    if(item?.id != 1){
                                                    return (
                                                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 }, }}>
                                                            <TableCell>{index+1}</TableCell>
                                                            <TableCell component="th" scope="row">
                                                            {item.name}, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{item?.city_details?.name}</span>
                                                            </TableCell>
                                                            <TableCell >
                                                                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                                    <UploadMedia customerData={item} statementId={id} />
                                                                    <Button variant="contained" sx={{mx:1, whiteSpace: 'nowrap',}} disabled={item?.filecount ? false : true} onClick={ () => {handleDrawerOpen(item)} }>View ({item.filecount})</Button>
                                                                </Box>
                                                            </TableCell>                    
                                                        </TableRow>
                                                    )}
                                                })) 
                                                : 
                                                <>
                                                <TableRow>
                                                    <TableCell colSpan={3} sx={{p:5}}> <Typography sx={{textAlign: "center"}}> Data Not Available </Typography> </TableCell>
                                                </TableRow>
                                                </>
                                                )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Paper sx={{mt: 3, mb:2, }}>
                    <Box sx={{ width: "100%", py: 3, px: 2, display: "flex", flexDirection: "column", gap:1}}>
                        <Box sx={{paddingLeft: 1}} > 
                            <Typography>Bulk Upload</Typography> 
                        </Box>
                        <Box sx={{display: "flex",}}>
                            <UploadMedia customerData={bulkUpload[0]} statementId={id} />
                            <Button variant="contained" sx={{mx:1, whiteSpace: 'nowrap',}} disabled={bulkUpload[0]?.filecount ? false : true} onClick={ () => {handleDrawerOpen(bulkUpload[0])} }>View ({bulkUpload[0]?.filecount})</Button>
                        </Box>                       
                    </Box>
                </Paper>
                </>
            :
            <NoData/>            
            }          
            
        </Box>
        <Drawer 
            anchor={"bottom"}
            open={drawerOpen}
            onClose={handleDrawerClose}
        >
            <MediaGrid statementId={id} customerId={selectedCustomer?.id} customerType={portal==="buyer" ? "supplier" : "buyer"} />
            
        </Drawer>
        </>
        )
    }
        
    return (
        <Box>
            {loading ?
                <LoadingComponent loading={loading} />
                :
                <Box>
                    <Layout title={"Ledger Statements Upload"}>
                        <BaseInfo />
                        
                        <Box>
                            <Typography variant="h6" component="p" sx={{py: 3, fontWeight: "bold", fontSize: "1rem"}}>{portal === "buyer" ? "Suppliers" : "Buyers"}</Typography>
                            {((portal === "buyer" && suppliersData?.length > 0) || (portal === "supplier" && buyersData?.length > 0)) &&
                                <TextField sx={{ mb: 2 }} fullWidth
                                    placeholder={portal === "buyer" ? "Filter Suppliers" : "Filter Buyers"}
                                    value={query}
                                    onChange={event => {
                                        setQuery(event?.target?.value);
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                        query && (
                                            <IconButton onClick={() => {setQuery("")}} edge="end">
                                            <IconX />
                                            </IconButton>
                                        )
                                        ),
                                    }}
                                />
                            }
                        </Box>                        
                        { portal === "supplier" ? <TableView data={buyersData} /> : <TableView data={suppliersData} /> }                        
                    </Layout>
                </Box>
            }
        </Box> 
    )
}