import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {  Autocomplete, Avatar, Button, Chip,  FormControl,  Grid,  InputLabel,  MenuItem,  Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography, } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useContext, useState } from "react";
import Layout from "../../components/Layout";
import LoadingComponent from "../../components/LoadingComponent";
import { GlobalContext } from "../../contexts/GlobalContext";
import NoData from "../../components/NoData";
import { parseISO, format } from 'date-fns';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate } from 'react-router-dom';


dayjs.extend(utc);
dayjs.extend(timezone);

const color = { invoice: "#D5F0DA", debitnotes: "#FFE7BC", payment: "#F7D7D7"}

function getRandomHexColor() {
    return `#${Math.floor(Math.random() * 0xFFFFFF).toString(16).padStart(6, '0')}`;
}

export default function FinancialStatement () {
    const { url, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken, portal  } = useContext(GlobalContext);
    
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    const [queryString, setQueryString] = useState('');

    const [buyerOptions, setBuyerOptions] = useState([]);
    const [selectedBuyer, setSelectedBuyer] = useState(null);

    const [supplierOptions, setSupplierOptions] = useState([])
    const [selectedSupplier, setSelectedSupplier] = useState(null)

    const [selectedFromDate, setSelectedFromDate] = useState("")
    const [selectedToDate, setSelectedToDate] = useState("")

    const [isDataFetched, setIsDataFetched] = useState(false)

    // const color = { invoice: "#D5F0DA", debitnotes: "#FFE7BC", payment: "#F7D7D7"}

    function fetchData () {
        if( selectedFromDate && selectedToDate ){
            axios.post( url + 'api/statements', {

            buyer: portal === "buyer" ? customer : selectedBuyer?.id ,
            seller:  portal === "supplier" ? customer : selectedSupplier?.id,
            from_date: selectedFromDate,
            to_date: selectedToDate,  

            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setLoading(true)
                setItems(response?.data?.entries)
                setIsDataFetched(true)
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    }

    function handleFetchData () {
        if(selectedFromDate && selectedToDate){
            setLoading(true)
            setItems([]);
            setIsDataFetched(false)
            fetchData()
            setLoading(true)
        }
    }

    const fetchBuyers = (query) => {
        if (query.length) {
            axios.post( url + 'api/admin/customers', {
                mode: "buyer",
                supplier: portal === "supplier" ? customer : "",
                search_term: query
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setBuyerOptions(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }

    const fetchSuppliers = (query) => {
        if (query.length) {
            axios.post( url + 'api/admin/customers', {
                mode: "supplier",
                buyer: portal === "buyer" ? customer : "",
                search_term: query
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setSupplierOptions(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }
    
    function clearBuyerData(newValue) {
        setLoading(true)
        setItems([])        
        setSelectedBuyer(newValue)
        setSelectedFromDate("")
        setSelectedToDate("")
        setIsDataFetched(false)
        setLoading(false)
        setSelectedValue(0)
    }

    function clearSupplierData(newValue) {
        setLoading(true)
        setItems([])
        setSelectedSupplier(newValue)
        setSelectedFromDate("")
        setSelectedToDate("")
        setIsDataFetched(false)
        setLoading(false)
        setSelectedValue(0)
    }


    const ActiveTermsView = ({term}) => {
        return(
            <Box sx={{my:1}}>
                <Paper sx={{p:1}}>
                    <Grid container sx={{border: 1,  borderColor: 'divider'}}>
                        <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p" sx={{textAlign: "center", fontWeight: "bold"}}>Active Terms</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ borderBottom: 1, borderRight: 1,  borderColor: 'divider', px: 1, py:1,  textAlign: "center" }}>
                            <Typography variant="overline" component="p">Discount period</Typography>
                            <Typography variant="body" component="p" style={{wordWrap: "break-word"}}>{term?.effective_from ? format(parseISO(term?.effective_from), 'dd MMM yyyy')  : "-"} - {term?.effective_to ? format(parseISO(term?.effective_to), 'dd MMM yyyy') : "-"}</Typography>
                        </Grid>
                        {!!term?.nett_status &&
                            <>
                            <Grid item xs={6}  sx={{ borderBottom: 1, borderRight: 1, borderColor: 'divider', px: 1, py:1,  textAlign: "center"  }}>
                                <Typography variant="overline" component="p">Due Days</Typography>
                                <Typography variant="body" component="p" style={{wordWrap: "break-word"}}>{term?.due_days ? `${term?.due_days} Days`: "-" }</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ borderBottom: 1, borderRight: 1, borderColor: 'divider', px: 1, py:1,  textAlign: "center"  }}>
                                <Typography variant="overline" component="p">Due Discount</Typography>
                                <Typography variant="body" component="p" style={{wordWrap: "break-word"}}>{(term?.due_discount) ? `${term?.due_discount} %` : "-"}</Typography>
                            </Grid>
                            </>
                        }
                        {!!term?.due_status &&
                            <>
                            <Grid item xs={6}  sx={{ borderRight: 1, borderRight: 1, borderColor: 'divider', px: 1, py:1,  textAlign: "center"  }}>
                                <Typography variant="overline" component="p">Nett Days</Typography>
                                <Typography variant="body" component="p" style={{wordWrap: "break-word"}}>{term?.nett_days ? `${term?.nett_days} Days`: "-" }</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ borderColor: 'divider', px: 1, py:1,  textAlign: "center"  }}>
                                <Typography variant="overline" component="p">Nett Discount</Typography>
                                <Typography variant="body" component="p" style={{wordWrap: "break-word"}}>{(term?.nett_discount) ? `${term?.nett_discount} %` : "-"}</Typography>
                            </Grid>
                            </>
                        }
                        {!!term?.remarks &&
                            <Grid item xs={12} sx={{ borderTop: 1, borderColor: 'divider', px: 1, py:1,  textAlign: "center" }}>
                                <Typography variant="overline" component="p">Remarks</Typography>
                                <Typography variant="body" component="p" style={{wordWrap: "break-word"}}>{term?.remarks || "-"}</Typography>
                            </Grid>
                        }
                    </Grid>
                </Paper>
            </Box>
        )
    }

    const DefaultTerms = ({term}) => {
        return (
            <Box sx={{my:1}}>
                <Paper sx={{p:1}}>
                    <Grid container sx={{border: 1,  borderColor: 'divider'}}>
                        <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p" sx={{textAlign: "center", fontWeight: "bold"}}>Default Terms</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} sx={{ borderBottom: 1, borderRight: 1, borderColor: 'divider', px: 1, py:1,  textAlign: "center"  }}>
                            <Typography variant="overline" component="p">Due Days</Typography>
                            <Typography variant="body" component="p" style={{wordWrap: "break-word"}}>{term?.due_days ? `${term?.due_days} Days`: "-" }</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} sx={{ borderBottom: 1, borderRight: 1, borderColor: 'divider', px: 1, py:1,  textAlign: "center"  }}>
                            <Typography variant="overline" component="p">Due Discount</Typography>
                            <Typography variant="body" component="p" style={{wordWrap: "break-word"}}>{(term?.due_discount) ? `${term?.due_discount} %` : "-"}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1,  textAlign: "center"  }}>
                            <Typography variant="overline" component="p">Nett Days</Typography>
                            <Typography variant="body" component="p" style={{wordWrap: "break-word"}}>{term?.nett_days ? `${term?.nett_days} Days`: "-" }</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} sx={{ borderColor: 'divider', px: 1, py:1,  textAlign: "center"  }}>
                            <Typography variant="overline" component="p">Nett Discount</Typography>
                            <Typography variant="body" component="p" style={{wordWrap: "break-word"}}>{(term?.nett_discount) ? `${term?.nett_discount} %` : "-"}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        )
    }

    const Terms = ({terms}) => {

        const activeTerms = terms?.general_terms.filter((term) => term?.active == 1 )

        const defaultTerms = Object.values(terms?.default_terms)?.filter( item => !!item )   

        return(
            <>
                { activeTerms.length > 0 ? 
                    activeTerms?.map(term => <ActiveTermsView term={term} key={term?.id} />)
                    :
                    <>
                    { !!defaultTerms?.length && <DefaultTerms term={terms?.default_terms} />}
                    </>
                }
            </>
        )
    }

    const TableComponent = ({itemObj}) => {        

        const TableBodyData = () => {
            let creditTotal = 0, debitTotal = 0;
            return (
                <>                
                    <TableRow>
                                <TableCell></TableCell>
                                <TableCell sx={{fontWeight: "bold"}}>{"Opening Balance"}</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right">{itemObj["opening_balance"] < 0 ? itemObj["opening_balance"] : "" }</TableCell>
                                <TableCell align="right">{itemObj["opening_balance"] > 0 ? itemObj["opening_balance"] : "" }</TableCell>
                    </TableRow>
                    {
                        itemObj["transactions"]?.map((item, index) => {
                            let backgroundColor;
                            let debitnoteRef;
                            let area;
                            let credit;
                            let debit;
                            if(item?.area === "payment"){
                                area = "Payment"
                                backgroundColor = color.payment
                                credit = item?.amount
                                debit = ""
                                if(!!credit){
                                    creditTotal = creditTotal + parseFloat(item?.amount)
                                }
                            }else if(item?.area === "invoices"){
                                area = "Invoice"
                                backgroundColor = color.invoice
                                credit = "" 
                                debit = item?.amount
                                if(!!debit){
                                    debitTotal = debitTotal + parseFloat(item?.amount)
                                }
                            }else if(item?.area === "debitnotes"){
                                area = "Debit Note"

                                // credit = `${(JSON.parse(item?.ref?.JSON.parse(item?.ref?.summary)).total - JSON.parse(item?.ref?.JSON.parse(item?.ref?.summary)).debit_note_used) || ""} (${item?.amount})`
                                
                                
                                if(JSON.parse(item?.ref?.summary).debit_note_used){
                                    credit =  `${JSON.parse(item?.ref?.summary).total - JSON.parse(item?.ref?.summary).debit_note_used} (${JSON.parse(item?.ref?.summary).total})`
                                    creditTotal = creditTotal + parseFloat(JSON.parse(item?.ref?.summary).total - JSON.parse(item?.ref?.summary).debit_note_used)
                                }else{
                                    credit = JSON.parse(item?.ref?.summary).total
                                    creditTotal = creditTotal + parseFloat(JSON.parse(item?.ref?.summary).total)
                                }
                                debit = ""
                                backgroundColor = color.debitnotes;
                                const refString = item?.narration?.reduce((acc, object, index) => {
                                    if(item.narration.length === (index+1)){
                                        acc = object?.creditnote_voucher_no || ""
                                        return acc
                                    }else{
                                        acc = (object?.creditnote_voucher_no || "") + "/ "
                                        return acc
                                    }
                                }, "")
                                debitnoteRef = item?.ref?.voucher_no + "/ " + (refString || "No CN")
                            }
                    
                            return(
                                <TableRow key={index} sx={{backgroundColor}}>
                                    <TableCell>{format(parseISO(item?.date), 'dd MMM yyyy')}</TableCell>
                                    <TableCell>{area}</TableCell>
                                    {item?.area === "invoices" && <TableCell align="right"> {item?.ref?.voucher_no} </TableCell>}
                                    {item?.area === "payment" && <TableCell align="right"> {item?.ref?.payment_no} </TableCell>}
                                    {item?.area === "debitnotes" && <TableCell align="right"> {debitnoteRef} </TableCell>}
                                    
                                    {item?.area === "payment" ? 
                                        <TableCell align="right">
                                            {item?.narration?.map((obj, index) => {
                                                let content;
                                                if(obj?.payment_type ==="invoice"){
                                                    content = `Invoice / ${obj?.invoice_voucher_no} / ${obj?.total}`
                                                }else if(obj?.payment_type ==="invoice_discount"){
                                                    const discount = JSON.parse(obj?.metadata)?.discount?.percent
                                                    content = `Invoice Discount (${discount || 0} %) / ${obj?.invoice_voucher_no} / ${obj?.total} `
                                                }else if(obj?.payment_type === "debitnote"){
                                                    content = `Debit Note ( ${obj?.debit_voucher_no || "No DN"} / ${obj?.creditnote_voucher_no || "No CN"} ) / ${obj?.invoice_voucher_no} / ${obj?.total}`
                                                }
                                                    return <Box key={index}>{content}</Box>
                                                })
                                            }
                                        </TableCell>                                      
                                        :

                                        (item?.area === "invoices" ?
                                            <TableCell align="right"></TableCell>
                                            :
                                            (item?.area === "debitnotes" ?
                                                <TableCell align="right"></TableCell>
                                                :
                                                <TableCell align="right"></TableCell>
                                            )
                                        )
                                    }

                                    <TableCell align="right">{credit}</TableCell>
                                    <TableCell align="right">{debit}</TableCell>
                                </TableRow>  
                            )
                        })
                    }
                    <TableRow>
                                <TableCell></TableCell>
                                <TableCell sx={{fontWeight: "bold"}}>{"Grand Total"}</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right" sx={{fontWeight: "bold"}} > {parseFloat(creditTotal - parseFloat(itemObj?.credit_opening_balance))?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',})} </TableCell>
                                <TableCell align="right" sx={{fontWeight: "bold"}} > {parseFloat(debitTotal + parseFloat(itemObj?.debit_opening_balance))?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',})} </TableCell>
                    </TableRow>
                    <TableRow>
                                <TableCell></TableCell>
                                <TableCell sx={{fontWeight: "bold"}}>{"Closing Balance"}</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right" sx={{fontWeight: "bold"}}>{(debitTotal + parseFloat(itemObj?.debit_opening_balance)) - (creditTotal + parseFloat(itemObj?.credit_opening_balance)) < 0 && parseFloat((debitTotal + parseFloat(itemObj?.debit_opening_balance)) - (creditTotal + parseFloat(itemObj?.credit_opening_balance)))?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',})}</TableCell>
                                <TableCell align="right" sx={{fontWeight: "bold"}}>{(debitTotal + parseFloat(itemObj?.debit_opening_balance)) - (creditTotal + parseFloat(itemObj?.credit_opening_balance)) > 0 && parseFloat((debitTotal + parseFloat(itemObj?.debit_opening_balance)) - (creditTotal + parseFloat(itemObj?.credit_opening_balance)))?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',})}</TableCell>
                    </TableRow>               
                </>
            )
        }   

        return (
            <Box sx={{mt: 2, p:2,}}>

                {itemObj?.customer_name ? 
                    <Box sx={{display: "flex", justifyContent: "left", alignItems: "stretch", mb: 2}}>
                        <Box>
                            <Avatar sx={{ bgcolor: getRandomHexColor(), height: "100%"}} variant="square">
                                {itemObj?.customer_name?.charAt(0)?.toUpperCase()} 
                            </Avatar>
                        </Box>
                        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", background: "#fff"}}>
                            <Typography sx={{ m:1, p:1, textAlign: "center"}} variant="body2">{ itemObj?.customer_name?.toUpperCase()}, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{itemObj?.customer_city}</span></Typography>
                        </Box>                        
                    </Box>
                    :
                    <Box sx={{display: "flex", justifyContent: "left", alignItems: "stretch", mb: 2}}>
                        <Box>
                            <Avatar sx={{ bgcolor: getRandomHexColor(), height: "100%" }} variant="square">
                                {portal == "buyer" ? itemObj?.seller?.name?.charAt(0)?.toUpperCase() : itemObj?.buyer?.name?.charAt(0)?.toUpperCase()}
                            </Avatar>
                        </Box>
                        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", background: "#fff"}}>
                            <Typography sx={{ m:1, p:1, textAlign: "center"}} variant="body2"> {portal == "buyer" ? itemObj?.seller?.name?.toUpperCase() : itemObj?.buyer?.name?.toUpperCase() }, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{portal == "buyer" ? itemObj?.seller?.city_name : itemObj?.buyer?.city_name}</span></Typography>
                        </Box>                        
                    </Box>
                }

                {!!itemObj?.terms && <Terms terms={itemObj?.terms}/>}                
                
                <TableContainer component={Paper} elevation={3} >
                    <Table size={"small"} stickyHeader >
                        <TableHead>
                        <TableRow>
                            <TableCell>DATE</TableCell>
                            <TableCell >PARTICULARS</TableCell>
                            <TableCell align="right">REF</TableCell>
                            <TableCell align="right">NARRATION</TableCell>
                            <TableCell align="right">CREDIT</TableCell>
                            <TableCell align="right">DEBIT</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableBodyData /> 
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    const [selectedValue, setSelectedValue] = useState(0)

    function handleSelectChange(event){
        setSelectedValue(event?.target?.value);

        const today = new Date();

        function formatDate(date){
            return format(date, 'yyyy-MM-dd')
        }

        let fromDate, toDate;

        switch(event?.target?.value){
            case 1:
                toDate = new Date(today.getFullYear(), today.getMonth() + 1, 0)
                fromDate = new Date(toDate.getFullYear(), today.getMonth(), 1)
                break;
            case 2:
                toDate = new Date(today.getFullYear(), today.getMonth(), 0);
                fromDate = new Date(toDate.getFullYear(), toDate.getMonth(), 1);
                break;
            case 3:
                toDate = new Date(today.getFullYear(), 12, 0);
                fromDate = new Date(today.getFullYear(), 0, 1);
                break;
            case 4:
                toDate = new Date(today.getFullYear() - 1, 12, 0);
                fromDate = new Date(today.getFullYear() -1, 0, 1);
                break;
            case 5:
                toDate = (new Date(today.getFullYear(), 3, 0) > today) ? new Date(today.getFullYear(), 3, 0) :  new Date(today.getFullYear() + 1, 3, 0);
                fromDate = (new Date(today.getFullYear(), 3, 0) > today) ? new Date(today.getFullYear() - 1, 3, 1) : new Date(today.getFullYear(), 3, 1);
                break;
            case 6:
                toDate = (new Date(today.getFullYear(), 3, 0) > today) ?  new Date(today.getFullYear() - 1, 3, 0) :  new Date(today.getFullYear(), 3, 0);
                fromDate = (new Date(today.getFullYear(), 3, 0) > today) ? new Date(today.getFullYear() - 2, 3, 1) : new Date(today.getFullYear() - 1, 3, 1);
                break;
            default:
                break;

        }

        setSelectedFromDate(formatDate(fromDate))
        setSelectedToDate(formatDate(toDate))

        
    }

    const selectItems=[{
        name: "Current Month",
        value: 1,
    },
    {
        name: "Last Month",
        value: 2,
    },
    {
        name: "Current Year",
        value: 3,
    },
    {
        name: "Last Year",
        value: 4,
    },
    {
        name: "Current Financial Year",
        value: 5,
    },
    {
        name: "Last Financial Year",
        value: 6,
    }]
    
    return (
        <Box>
            {  loading ? 
            <LoadingComponent loading={loading} />
            :
            <>
            <Layout title={"Financial Statements"}>
                <Paper sx={{padding: "10px"}} elevation={3}>
                    <Box>
                        <Box sx={{mb: 2}}>
                            <Typography sx={{my:2, borderRadius: "10px", textAlign: "center"}} variant={"overline"}>Provide details to request financial statement</Typography>
                        </Box>
                        {
                            portal === "supplier" ? 
                            
                            <Autocomplete
                                sx={{ mb: 2 }}
                                options={buyerOptions}
                                clearOnBlur
                                getOptionLabel={(option) => (option.name || "")}
                                value={selectedBuyer}
                                size="small"
                                inputValue={queryString}
                                onInputChange={(event, newInputValue) => {
                                    setQueryString(newInputValue); 
                                    setBuyerOptions([]);
                                    fetchBuyers(newInputValue)
                                }}
                                onChange={(event, newValue) => {
                                    if(newValue){
                                        setSelectedBuyer(newValue)
                                    }else{
                                        clearBuyerData(newValue)
                                    }
                                }}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option.id}>
                                    {option.name} 
                                    </Box>
                                )}
                                isOptionEqualToValue ={(option, value) => option?.id === value?.id }
                                renderInput={(params) => (<TextField {...params} label="Select a Buyer" />)}
                                disabled={isDataFetched}
                                />
                            :
                            <Autocomplete
                                    sx={{ mb: 2 }}
                                    options={supplierOptions}
                                    clearOnBlur
                                    getOptionLabel={(option) => (option.name || "")}
                                    value={selectedSupplier}
                                    size={"small"}
                                    inputValue={queryString}
                                    onInputChange={(event, newInputValue) => {
                                        setQueryString(newInputValue); 
                                        setSupplierOptions([]);
                                        fetchSuppliers(newInputValue)
                                    }}
                                    onChange={(event, newValue) => {
                                        if(newValue){
                                            setSelectedSupplier(newValue)
                                        }else{
                                            clearSupplierData(newValue)
                                        }
                                    }}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} key={option.id}>
                                        {option.name} 
                                        </Box>
                                    )}
                                    isOptionEqualToValue ={(option, value) => option?.id === value?.id }
                                    renderInput={(params) => (<TextField {...params} label="Select a Seller" />)}
                                    disabled={isDataFetched}
                                    />                        
                        }

                        <FormControl sx={{ mb: 3}} size="small" fullWidth>
                            <Select
                                value={selectedValue}
                                onChange={handleSelectChange}
                                disabled={isDataFetched}
                            >
                                <MenuItem disabled={true} value={0}>
                                    Date Choice
                                </MenuItem>
                                {
                                    selectItems?.map((item, index) => (
                                        <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>

                        <>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <Grid container spacing={2} sx={{mb:3}}>
                                <Grid item xs={6}>
                                    <TextField type="date" size="small" variant="outlined" label="From Date" fullWidth 
                                    value={selectedFromDate}
                                    onChange = {(event) => {setSelectedFromDate(event?.target?.value)}}
                                    InputLabelProps={{ shrink: true }} 
                                    required={true}
                                    helperText={(selectedToDate && !selectedFromDate ) ? "Please enter From Date" : "" }
                                    FormHelperTextProps={{error: true}}
                                    InputProps={{
                                        inputProps: {
                                          max: selectedToDate || "", 
                                        },
                                      }}
                                    disabled={isDataFetched}
                                    />
                                    
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField type="date" size="small" variant="outlined" fullWidth label="To Date"
                                    value={selectedToDate}
                                    onChange = {(event) => { setSelectedToDate(event?.target?.value) }}
                                    InputLabelProps={{ shrink: true }}
                                    required={true} 
                                    helperText={(selectedFromDate && !selectedToDate ) ? "Please enter To Date" : "" }
                                    FormHelperTextProps={{error: true}}
                                    InputProps={{
                                        inputProps: {
                                          min: selectedFromDate || "", 
                                        },
                                      }}
                                    disabled={isDataFetched}
                                    />
                                    
                                </Grid>
                            </Grid>
                            </LocalizationProvider>
                        </>

                        <Box sx={{display: "flex"}}>
                            <Box sx={{flex:1}}></Box>
                            <Box sx={{flex:1, display: "flex", justifyContent: "right", alignItems: "center"}}>
                                <Button variant="contained" disabled={isDataFetched} sx={{width: "50%", m:1}} onClick={handleFetchData}>
                                    Generate
                                </Button>
                                {isDataFetched &&
                                    <Button color="error" variant="contained" sx={{width: "50%", m:1}} onClick={() => { portal === "buyer" ? clearSupplierData(null) : clearBuyerData(null) } }>
                                        Clear
                                    </Button>
                                }
                            </Box>
                        </Box>
                        
                    </Box>
                </Paper>

                {isDataFetched && 
                    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10px"}}>
                        <Box>
                            <Chip label="Debit note" sx={{ background: color.debitnotes, margin: "0 10px"}} />
                            <Chip label="Invoice" sx={{ background: color.invoice, margin: "0 10px"}} />
                            <Chip label="Payment" sx={{ background: color.payment, margin: "0 10px"}} />
                        </Box>
                    </Box>
                }

                { isDataFetched && Array.isArray(items) ? 
                    ( items?.length != 0 ? 
                        items?.map((itemObj, index) => ( <TableComponent itemObj={itemObj} key={index}/> ))  
                        : 
                        <NoData />
                    ) 
                    : 
                    <></> 
                }

                {isDataFetched && (typeof items === "object" && !(Array.isArray(items))) ? 
                    ( Object.values(items)?.length != 0 ? 
                        Object.values(items)?.map((itemObj, index) => ( <TableComponent itemObj={itemObj} key={index}/> ))  
                        : 
                        <NoData />
                    ) 
                    : 
                    <></> 
                }

            </Layout>
            </>
        }
        </Box>
    )
}