import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../../components/Layout';
import { GlobalContext } from '../../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import UploadButton from '../../components/VoucherUploader';
import MediaGrid from '../../components/MediaGrid';
import Fab from '@mui/material/Fab';
import { IconPlus, IconForms } from '@tabler/icons-react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { format, formatDistance, parse } from 'date-fns'
import LoadingComponent from '../../components/LoadingComponent';

function Chat() {
    const navigate = useNavigate();
    const { url, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken, ledgers, portal } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
 
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const [currentPage, setCurrentPage] = useState(0);


    useEffect(() => {
        setItems([]);
        setHasMore(true);
        setCurrentPage(0);
        fetchData();
        if(window.cordova) {
            window.cordova.plugins.firebase.analytics.setCurrentScreen("Chat");
        }
    }, [ ]);

    const fetchData = () => {
        var page = currentPage + 1;
        axios.post( url + 'api/chat?page=' + page, {
            customer: customer,
            contact: contact,
            phone: phone,
            portal: portal,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            console.log("data   :", response);
            setItems((prev) => [...prev, ...response.data.data]);
            setCurrentPage(parseInt(response.data.current_page));
            if(response.data.current_page == response.data.last_page) {
                setHasMore(false);
            }
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    function stringToColor(string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
    }
    
    function stringAvatar(name) {
        var split = name.split(' ');
        return {
            sx: {
            bgcolor: stringToColor(name),
            },
            children: `${split[0][0]}${split[1] ? split[1][0] : ''}`,
        };
    }

    const Item = (prop) => {
        var read = 0;
        
        if(prop.data.customer_id) {
            read = prop.data.read_customer;
        } else if(portal == "supplier") {
            read = prop.data.read_seller;
        } else {
            read = prop.data.read_buyer;
        }
        return (
            <Box onClick={event => { return navigate("/chat/channel/" + prop.data.name) }} sx={{ borderBottom: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'left', px: 3, py: 2 }}>
                 <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar {...stringAvatar(
                        portal == "supplier" 
                            ? prop.data.buyer_details.name 
                            : prop.data.seller_details.name
                        )} />
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="body" component="p">{ portal == "supplier" ? prop.data.buyer_details.name : prop.data.seller_details.name }</Typography>
                        <Typography variant="caption" component="p">{prop.data.last_update ? formatDistance(new Date(prop.data.last_update), new Date(), { addSuffix: true }) : '-'}</Typography>
                    </Box>
                    { read == 0 &&
                        <Badge color="secondary" overlap="circular" badgeContent=" " variant="dot"></Badge>
                    }
                </Stack>
            </Box>
        )
    }

    function New() {
        const [open, setOpen] = useState(false)
        const [ledger, setLedger] = useState(null)
        const [fetchedCustomers, setfetchedCustomers] =  useState([])

        const handleOpen = () => {
            setOpen(true);
        }

        const handleClose = () => {
            setOpen(false);
            setLedger(null);
            setfetchedCustomers([]);
        }


        function fetchCustomers(){
            axios.post( url + 'api/linkedcustomers', { 
                customer: customer,
                portal: portal
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                // console.log("response", response);
                console.log("Customer details fetched successfully")
                setfetchedCustomers(response?.data)
            })
            .catch(function (error) {
                console.log(error);
            })
        }

        // console.log({ledger})

        useEffect(() => { if(open){fetchCustomers()} }, [open]);

        const handleSubmit = (event) => {
            event.preventDefault();
            axios.post( url + 'api/chat/channel', {
                // customer: customer,
                contact: contact,
                phone: phone,
                creator: portal == "supplier" ? 2 : 3,
                buyer: portal == "supplier" ? ledger?.id : customer,
                seller: portal == "supplier" ? customer : ledger?.id,
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                handleClose()
                navigate("/chat/channel/" + response.data.channel.name)
            })
            .catch(function (error) {
                console.log(error);
            });
        }

        return(
            <React.Fragment>
                <Fab color="primary" aria-label="add" onClick={handleOpen} size={"medium"} sx={{ position: "fixed", bottom: (theme) => theme.spacing(2), right: (theme) => theme.spacing(2) }}>
                    <IconPlus />
                </Fab>
                <Dialog onClose={handleClose} open={open}>
                    <DialogTitle>New Conversation</DialogTitle>
                    <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                        <form onSubmit={handleSubmit}>
                            <InputLabel shrink>{ portal == "supplier" ? "Buyer" : "Supplier"}</InputLabel>
                            <Autocomplete sx={{ mb: 2 }}
                                options={fetchedCustomers}
                                getOptionLabel={(option) => option?.name || '' }
                                value={ledger}
                                onChange={(event, newValue) => {
                                    if(newValue?.name == "Unregistered") {
                                        alert("Cannot start chat with Unregistered");
                                    } else {
                                        setLedger(newValue)
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} required={true} />}
                            />

                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                                <Button type="submit" variant="contained">Start</Button>
                            </Box>
                        </form>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        )
    }

    const Forms = (prop) => {
        return (
            <Box sx={{ p: 2 }}>
                <Card onClick={event => { return navigate("/supplier/forms/") }} sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', mb: 4 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box sx={{ px: 4 }}>
                            <IconForms />
                        </Box>
                        <Box sx={{ flexGrow: 1, py: 2 }}>
                            <Typography variant="h6" component="h6">Order Forms</Typography>
                            <Typography variant="body" component="p">Manage your order forms which you can send to customers</Typography>
                        </Box>
                    </Box>
                </Card>
            </Box>
        )
    }

    return (
        <div>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title="Chat" nopadding={true} backToHome="true">
                {
                    portal == "supplier" &&
                    <Forms />
                }
                <InfiniteScroll scrollThreshold={0.5} dataLength={items.length} next={fetchData} style={{ display: 'flex', flexDirection: 'column' }} inverse={false} hasMore={hasMore} loader={<p style={{ textAlign: 'center', fontSize: '14px' }}>Loading...</p>} endMessage={<p style={{ textAlign: 'center', fontSize: '14px' }}>You have reached end of the list</p>}>
                    {items.map((row, index) => (
                        <Item key={row.id} data={row}></Item>
                    ))}
                </InfiniteScroll>
                <New />
            </Layout>
        }
        </div>
    );
}

export default Chat;
