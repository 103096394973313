import { Box, Grid, Paper, Tab, Tabs, Typography, Button, Chip, Alert } from "@mui/material";
import axios from "axios";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import BackToTop from "../../components/BackToTop";
import DebitNoteActions from "../../components/DebitNoteActions";
import ErrorMessage from "../../components/ErrorMessage";
import FilterForm from "../../components/FilterForm";
import Layout from "../../components/Layout";
import LoadingComponent from "../../components/LoadingComponent";
import MediaGrid from "../../components/MediaGrid";
import ScrollComponent from "../../components/ScrollComponent";
import UploadMedia from "../../components/UploadMedia";
import Void from "../../components/Void";
import UploadButton from "../../components/VoucherUploader";
import { GlobalContext } from "../../contexts/GlobalContext";

export default function DebitNotesPage() {

    let { status } = useParams();
    const navigate = useNavigate();
    const { url,customer, token, portal, contact, phone } = useContext(GlobalContext);
    const title = "Debit Notes";

    const fetcher = async (page, pageStatus) => {        
        const response = await axios.post( url + 'api/debitnotes?page=' + page, {
            customer: customer,
            contact: contact,
            phone: phone,
            status: pageStatus,
            creator: portal == "supplier" ? 2 : 3,
        }, { headers: { Authorization: `Bearer ${token}` }})

        return response.data
    }

    const { isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery( 
        ['debitNotes', status],  
        ({ pageParam = 1 }) => fetcher(pageParam, status),
        {
          getNextPageParam: (lastPageData) => { 
            if(lastPageData?.current_page < lastPageData.last_page){
                return lastPageData?.current_page + 1
            }else{
                return undefined
            } 
          },
          refetchOnWindowFocus: false,
        }
    ); 
    
    const Item = ({data}) => {

        var reason = '';
        if(status == 3) {
            reason = JSON.parse(data.approval_log)[0].reason;
        }
        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                    <Typography variant="overline" component="p">{portal === "buyer" ? "Supplier" : "Buyer"}</Typography>
                    <Typography variant="h6" component="h6">{ portal == "supplier" ? data.buyer_name : data.seller_name }, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{ portal == "supplier" ? data.buyer_city_details?.name : data.seller_city_details?.name }</span></Typography>
                    {
                        portal === "buyer" && !!data?.supplier_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.supplier_msme} /></Box>
                    }
                    {
                        portal === "supplier" && !!data?.buyer_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.buyer_msme} /></Box>
                    }
                </Box>
                <Grid container sx={{ borderColor: 'divider' }}>
                    <Grid item xs={4} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">{ status == 1 ? "Debit Note" : "Credit Note"} #</Typography>
                        <Typography variant="body" component="p">{data?.voucher_no ? data.voucher_no : ''}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Date</Typography>
                        <Typography variant="body" component="p">{data?.date ? format(parse(data.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Total</Typography>
                        {data?.total ? 
                            <Typography variant="body" component="p">&#8377; {data.total}</Typography> :
                            <Typography variant="body" component="p">{''}</Typography>
                        }
                    </Grid>
                    {
                        status == 3 ?
                        <Grid item xs={12} sx={{ borderTop: 1, borderColor: 'divider',px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Rejection Reason</Typography>
                            <Typography variant="body" component="p">{reason ? reason : '-'}</Typography>
                        </Grid>
                        :
                        <></>
                    }
                </Grid>
                <Box sx={{ borderTop: 1, borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                    <MediaGrid dir="debitnotes" images={data?.images} />
                </Box>
                { portal == "supplier" && data?.voucher_type == "debitnotes" &&
                    <DebitNoteActions approval={data?.approval} id={data?.id} refetch={refetch} />
                }
                {
                    portal == "buyer" && data?.approval == 1 &&
                    <Box sx={{my:1}}>
                        <Alert sx={{ textAlign: "center", justifyContent: "center",}} severity="success">Debit Note Accepted</Alert>
                    </Box>                    
                }
                {
                    portal == "buyer" && data.approval == 2 &&
                    <Alert sx={{ textAlign: "center", justifyContent: "center", }} severity="error">Debit Note Rejected</Alert>
                }
                {
                    (status == 0 ) && 
                    <Box sx={{ width: '100%', px: status == 1 ? 2 : 1, marginTop: 1 }}>
                        <UploadMedia
                            id={data?.id} module="entry"
                            dir="debitnotes" link={url + 'api/media/add'}
                            refetch={refetch}
                        />
                    </Box>
                }
                { status == 1 &&
                    <Box sx={{display: "flex", }}>
                        <Box sx={{flex: 1, paddingLeft: 1, paddingRight: 0.5}}>
                        <UploadMedia
                            id={data?.id} module="entry"
                            dir="debitnotes" link={url + 'api/media/add'} 
                            refetch={refetch}
                        />
                        </Box>
                    <Box sx={{ flex: 1, paddingLeft: 0.5, paddingRight: 1 }}>
                        <Void id={data?.voucher_id} type={ 'voucher' } voided={data?.status != 5 ? 0 : 1 } refetch={refetch} />
                    </Box>
                    </Box>
                }
                { status == 2 &&
                    <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%', px: 1, py: 1, display: "flex", gap:1 }}>
                        <Box sx={{flex:1}}> 
                            <Button variant="contained" fullWidth onClick={() => { navigate("/debitnote/" + data?.id) }}>View</Button>
                        </Box>
                        <Box sx={{flex:1}}>
                            <Void id={data?.id} type={ 'voucher' } voided={data?.status != 3 ? 0 : 1 } refetch={refetch} />
                        </Box>                        
                    </Box>
                }
            </Paper>
        )
    }

    // console.log({isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch})

    const currentTab = { name: "DebitNotes", status: { 0: "Pending Approval", 1: "Unproccessed", 2: "Approved", 3: "Rejected", 99: "All Data",}};

    return (
        <>
        {isError ? <ErrorMessage title={title} message={error?.message} /> :
            <>
            {  isLoading ? 
                <LoadingComponent loading={ isLoading } /> 
                : 
                <Layout title={title} backToHome="true">
                    <Box sx={data?.pages[0]?.data?.length !== 0 ? { borderBottom: 1, borderColor: 'divider' } : {}}>

                        <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={parseInt(status)} onChange={(event, newStatus) => { navigate("/debitnotes/" + newStatus) }}>
                            <Tab label="Pending Approval" />
                            <Tab label="Unprocessed" />
                            <Tab label="Approved" />
                            <Tab label="Rejected" />
                            <Tab label="Voided" />
                        </Tabs>

                        <ScrollComponent data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} Item={Item}  />
                    </Box>

                    <FilterForm currentTab={currentTab} navigateURL={"/filtered_debitnotes"} />

                    <UploadButton buttonType="fab" title="Debit Note" voucher_type="debitnotes" refetch={refetch}
                        createdInAreas={[ 
                            {label : portal == "supplier" ? "Supplier App" : "Buyer App"},
                            {label : "Add Button"},
                        ]}
                    />

                    <BackToTop />                  
                    
                </Layout>
            }
            </>
        }
        </>
    )
}