import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react"
import Layout from "../../../components/Layout";
import LoadingComponent from "../../../components/LoadingComponent";
import { GlobalContext } from "../../../contexts/GlobalContext";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { parseISO, format } from 'date-fns';
import DownloadPDF from "../../../components/DownloadPDF";
import DownloadExcel from "../../../components/DownloadExcel";
import { Box } from "@mui/system";
import NoData from "../../../components/NoData";
import { Autocomplete, TextField } from "@mui/material";




export default function DueReportData() {

    const { url,  customer, token, portal  } = useContext(GlobalContext);

    const title = `Due By ${portal === "buyer" ? "Supplier" : "Buyer"}`
    
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]); 
    const [filteredData, setFilteredData] = useState([]);
    const [selectedCity, setSelectedCity] = useState("");
    
    const tableRef = useRef();

    const printString = portal === "buyer" ? `Buyer : ${data?.buyer?.name}, ${data?.buyer?.city_name}` : `Supplier : ${data?.supplier?.name}, ${data?.supplier?.city_name}`;

    // console.log({printString})

    useEffect(() => {

        const fetchData = () => {
            if(portal === "buyer"){

                axios.post( url + "api/buyers/dueByBuyer/" + customer , {
                    buyer: portal === 'buyer' ? customer : "" ,
                    supplier: portal === 'supplier' ? customer : ""                
                }, { headers: { Authorization: `Bearer ${token}` }})
                .then(function (response) {
                    // console.log({response})
                    setData(response?.data)
                    setLoading(false)
                })
                .catch(function (error) {
                    console.log(error);
                })

            }else if(portal === "supplier"){

                axios.post( url + "api/suppliers/dueBySupplier/" + customer , {
                    buyer: portal === 'buyer' ? customer : "" ,
                    supplier: portal === 'supplier' ? customer : ""                
                }, { headers: { Authorization: `Bearer ${token}` }})
                .then(function (response) {
                    // console.log({response})
                    setData(response?.data)
                    setLoading(false)
                })
                .catch(function (error) {
                    console.log(error);
                })

            }
        };

        fetchData();

    }, []);

    useEffect(() => {
        if(portal === "buyer"){
            if(data?.suppliers?.length != 0){
                const filteredSuppliers = data?.suppliers?.filter((item) => {
                    if(!!selectedCity){
                        return selectedCity === item?.city_name
                    }else{
                    return true
                    }
                });

                !!filteredSuppliers && setFilteredData(filteredSuppliers)
            }
        }else if(portal === "supplier"){
            if(data?.buyers?.length != 0){
                const filteredBuyers = data?.buyers?.filter((item) => {
                    if(!!selectedCity){
                        return selectedCity === item?.city_name
                    }else{
                    return true
                    }
                });

                !!filteredBuyers && setFilteredData(filteredBuyers)
            }
        }
    }, [selectedCity])

    

    // console.log({filteredData})

    // function getRandomHexColor() {
    //     return `#${Math.floor(Math.random() * 0xFFFFFF).toString(16).padStart(6, '0')}`;
    // }    

    const TableBodyComponent = ({item}) => {

        function calculateTotalDue(item) {
            if(portal === "buyer" && item?.seller_invoices?.length !== 0 ){
                const total = item?.seller_invoices?.reduce((acc, item) => {
                    const due = JSON.parse(item?.summary)?.due;
                    acc = acc + due;
                    return acc;
                }, 0)

                return total
            }else if(portal === "supplier" && item?.buyer_invoices?.length !== 0){
                const total = item?.buyer_invoices?.reduce((acc, item) => {
                    const due = JSON.parse(item?.summary)?.due;
                    acc = acc + due;
                    return acc;
                }, 0)

                return total
            }
        }

        function calculateTotalPaid(item) {
            if(portal === "buyer" && item?.seller_invoices?.length !== 0 ){
                const total = item?.seller_invoices?.reduce((acc, item) => {
                    const paid = JSON.parse(item?.summary)?.paid
                    acc = acc + paid;
                    return acc;
                }, 0)

                return total
            }else if(portal === "supplier" && item?.buyer_invoices?.length !== 0){
                const total = item?.buyer_invoices?.reduce((acc, item) => {
                    const paid = JSON.parse(item?.summary)?.paid
                    acc = acc + paid;
                    return acc;
                }, 0)

                return total
            }
        }

        function calculateGrandTotal(item) {
            if(portal === "buyer" && item?.seller_invoices?.length !== 0 ){
                const total = item?.seller_invoices?.reduce((acc, item) => {
                    const grandTotal = +item?.total
                    acc = acc + grandTotal;
                    return acc;
                }, 0)

                return total
            }else if(portal === "supplier" && item?.buyer_invoices?.length !== 0){
                const total = item?.buyer_invoices?.reduce((acc, item) => {
                    const grandTotal = +item?.total
                    acc = acc + grandTotal;
                    return acc;
                }, 0)

                return total
            }
        }

        const TableRowData = ({rowItem, index}) => {

            const parsedData = JSON.parse(rowItem?.summary);

            return(
                <>
                <TableRow>
                    <TableCell align="left">{index+1}</TableCell>
                    <TableCell align="left">{rowItem?.voucher_no}</TableCell>
                    <TableCell align="left">{format(parseISO(rowItem?.date), 'dd MMM yyyy')}</TableCell>
                    <TableCell align="right">{+rowItem?.total}</TableCell>
                    <TableCell align="right">{parsedData?.paid}</TableCell>
                    <TableCell align="right">{parsedData?.due}</TableCell>
                </TableRow>
                </>
            )
        }

        return(
            <>            
                <TableRow>
                    <TableCell colSpan={6} sx={{fontWeight: "bold"}}>
                        {portal === "buyer" ? "Supplier" : "Buyer"} : {item?.name}, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{item?.city_name}</span>
                    </TableCell>
                </TableRow>
                <TableRow sx={{backgroundColor: "#F2F3F4"}}>
                    <TableCell>S.No.</TableCell>
                    <TableCell>Invoice No.</TableCell>
                    <TableCell align="left">Date</TableCell>
                    <TableCell align="right">Total (Rs.)</TableCell>
                    <TableCell align="right">Paid (Rs.)</TableCell>
                    <TableCell align="right">Due (Rs.)</TableCell>
                </TableRow>
                
                { portal === "buyer" ? 
                    (item?.seller_invoices?.length !== 0 && item?.seller_invoices?.map((rowItem, index) => (<TableRowData key={index}  rowItem={rowItem} index={index}  />)))
                        :
                    (item?.buyer_invoices?.length !== 0 && item?.buyer_invoices?.map((rowItem, index) => (<TableRowData key={index} rowItem={rowItem} index={index}  />)))
                }

                <TableRow sx={{backgroundColor: "#F2F3F4"}}>
                    <TableCell colSpan={3} ></TableCell>
                    <TableCell align="right" sx={{fontWeight: "bold"}}>{calculateGrandTotal(item)}</TableCell>
                    <TableCell align="right" sx={{fontWeight: "bold"}}>{calculateTotalPaid(item)}</TableCell>
                    <TableCell align="right" sx={{fontWeight: "bold"}}>{calculateTotalDue(item)}</TableCell>
                </TableRow>
            
            </>
        )
    }

    const PrintedTable = ({listData}) => {
        return (
            <TableContainer component={Paper} sx={{mb:2}}>
                <Table ref={tableRef}>
                    <TableHead>
                        <TableRow sx={{backgroundColor: "#F2F3F4"}}>
                            <TableCell sx={{fontWeight: "bold"}}>S.No.</TableCell>
                            <TableCell sx={{fontWeight: "bold"}}>Invoice No.</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Date</TableCell>
                            <TableCell align="right" sx={{fontWeight: "bold"}}>Total (Rs.)</TableCell>
                            <TableCell align="right" sx={{fontWeight: "bold"}}>Paid (Rs.)</TableCell>
                            <TableCell align="right" sx={{fontWeight: "bold"}}>Due (Rs.)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listData.map((item, index) => (<TableBodyComponent key={index} item={item} />)) }                                              
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const FilterComponent = () => {
        
        const [options, setOptions] = useState([]);

        useEffect(() => {
            if(portal === "buyer"){
                const filteredCities = data?.suppliers.reduce((value, item) => {
                    !value.includes(item?.city_name) && value.push(item?.city_name)
                    return value
                }, []);

                setOptions(filteredCities)
                
            }else if(portal === "supplier"){
                const filteredCities = data?.buyers.reduce((value, item) => {
                    !value.includes(item?.city_name) && value.push(item?.city_name)
                    return value
                }, []);

                setOptions(filteredCities)
            }
        }, [])   

        return(
            <>
            <Autocomplete sx={{ my: 3 }}
                options={options}
                size="small"
                value={selectedCity}
                onChange={(event, newValue) => {
                    newValue === null ? setSelectedCity("") : setSelectedCity(newValue)
                }}
                renderInput={(params) => <TextField {...params} required={false} label={"Filter by city"} />}
                isOptionEqualToValue={(option, value) => { 
                    if(!value){
                        return true
                    }else{
                        return option === value
                    }
                }}
            />
            </>
        )
    }
    
    return(
        <>
        {  loading ? 
            <LoadingComponent loading={loading} />
            :
            <Layout title={title}>
                
                {portal === "buyer" ? 
                
                (data?.suppliers?.length !==0 ?
                <>
                    <Box sx={{mb:2, display: "flex", gap: 1}}>
                        <DownloadPDF tableRef={tableRef} pdfTitle={title} printString={printString} />
                        <DownloadExcel tableRef={tableRef} filename={title} sx={{background: "#217346"}} />
                    </Box>
                    <FilterComponent />
                    <PrintedTable listData={filteredData?.length !== 0 ? filteredData : data?.suppliers} />
                </> : <NoData />
                )

                :

                (data?.buyers?.length !==0 ?
                <>
                    <Box sx={{mb:2, display: "flex", gap: 1}}>
                        <DownloadPDF tableRef={tableRef} pdfTitle={title} printString={printString} />
                        <DownloadExcel tableRef={tableRef} filename={title} sx={{background: "#217346"}} />
                    </Box>
                    <FilterComponent />
                    <PrintedTable listData={filteredData?.length !== 0 ? filteredData : data?.buyers} />
                </> 
                : <NoData /> 
                )

                }
                
            </Layout>
        } 
        </>
    )
}
