import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../../components/Layout';
import { GlobalContext } from '../../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import { format, parse } from 'date-fns'
import UploadButton from '../../components/VoucherUploader';
import LoadingComponent from '../../components/LoadingComponent';

function BuyerReturns() {
    let { status } = useParams();
    const navigate = useNavigate();
    const { url, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken  } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
 
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const [currentPage, setCurrentPage] = useState(0);

    const handleChange = (event, newStatus) => {
        setItems([]);
        setHasMore(true);
        setCurrentPage(0);
        return navigate("/buyer/returns/" + newStatus)
    };

    useEffect(() => {
        setItems([]);
        setHasMore(true);
        setCurrentPage(0);
        fetchData();
    }, [ status ]);

    const fetchData = () => {
        var page = currentPage + 1;
        axios.post( url + 'api/buyers/returns?page=' + page, {
            customer: customer,
            contact: contact,
            phone: phone,
            status: status,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            setItems((prev) => [...prev, ...response.data.data]);
            setCurrentPage(parseInt(response.data.current_page));
            if(response.data.current_page == response.data.last_page) {
                setHasMore(false);
            }
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const Item = (prop) => {
        return (
            <Paper onClick={event => { if(status != 0) {return navigate("/buyer/return/" + prop.data.voucher_type + "/" + prop.data.id) } }} sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                    <Typography variant="h6" component="h6">{prop.data.buyer_name}</Typography>
                </Box>
                <Grid container>
                    <Grid item xs={4} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">{ status == 1 ? "Debit Note" : "Credit Note"} #</Typography>
                        <Typography variant="body" component="p">{prop.data.voucher_no ? prop.data.voucher_no : ''}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Date</Typography>
                        <Typography variant="body" component="p">{prop.data.date ? format(parse(prop.data.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Total</Typography>
                        <Typography variant="body" component="p">&#8377; {prop.data.total ? prop.data.total : ''}</Typography>
                    </Grid>
                </Grid>
            </Paper>
        )
    }

    return (
        <div>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title="Returns" backToHome="true">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={parseInt(status)} onChange={handleChange}>
                        <Tab label="Unprocessed" />
                        <Tab label="Pending" />
                        <Tab label="Closed" />
                    </Tabs>

                    <InfiniteScroll scrollThreshold={0.5} dataLength={items.length} next={fetchData} style={{ display: 'flex', flexDirection: 'column' }} inverse={false} hasMore={hasMore} loader={<p style={{ textAlign: 'center', fontSize: '14px' }}>Loading...</p>} endMessage={<p style={{ textAlign: 'center', fontSize: '14px' }}>You have reached end of the list</p>}>
                    {items.map((row, index) => (
                        <Item key={row.id} data={row}></Item>
                    ))}
                    </InfiniteScroll>
                </Box>
                <UploadButton buttonType="fab" title="Credit Note" voucher_type="creditnotes" />
            </Layout>
        }
        </div>
    );
}

export default BuyerReturns;
