import React, { useEffect, useState, useContext, useRef } from 'react'
import Layout from '../../components/Layout';
import Chatter from '../../components/Chatter';
import { useParams } from "react-router-dom";

function ChatChannel() {
    let { id } = useParams();
    return (
        <div>
            <Layout title="Chat" nopadding={true}>
                <Chatter id={id} />
            </Layout>
        </div>
    );
}

export default ChatChannel;
