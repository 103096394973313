import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react"
import Layout from "../../../components/Layout";
import LoadingComponent from "../../../components/LoadingComponent";
import { GlobalContext } from "../../../contexts/GlobalContext";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { parseISO, format } from 'date-fns';
import { Button, Chip } from "@mui/material";
import DownloadPDF from "../../../components/DownloadPDF";
import DownloadExcel from "../../../components/DownloadExcel";
import { Box } from "@mui/system";
import NoData from "../../../components/NoData";




export default function UnclosedOverDeliveryDate() {

    const { url,  customer, token, portal  } = useContext(GlobalContext);

    const title = "Unclosed Over Delivery Date"
    
    const [loading, setLoading] = useState(true)

    const [data, setData] = useState([]); 
    
    const tableRef = useRef();

    const printString = portal === "buyer" ? `Buyer : ${data[0]?.buyer_name}` : `Supplier : ${data[0]?.seller_name}`;

    console.log({printString})

    // console.log({data})

    useEffect(() => {

        const fetchData = () => {
            axios.post( url + 'api/reports/unclosed/orders' , {
                buyer: portal === 'buyer' ? customer : "" ,
                supplier: portal === 'supplier' ? customer : ""                
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                console.log({response})
                setData(response?.data)
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            });
        };

        fetchData() 

    }, [])

    const ReportsTable = () => {

        // let sortedArray = []

        // if(data?.length != 0 ){ 
        //     sortedArray = (portal === "buyer") ? 
        //         data.sort((a, b) => a?.seller_details?.name.localeCompare(b?.seller_details?.name)) 
        //         : 
        //         data.sort((a, b) => a?.buyer_details?.name.localeCompare(b?.buyer_details?.name))
        // }

        const ReportsTableDataRow = ({item, index}) => {          

            return(
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }}}>
                    <TableCell component="th" scope="row">{index+1}</TableCell>
                    <TableCell align="left">{item?.voucher_no}</TableCell>
                    <TableCell align="left">{portal === "buyer" ? item?.seller_details?.name : item?.buyer_details?.name}, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{ portal === "buyer" ? item?.seller_details?.city_details?.name : item?.buyer_details?.city_details?.name }</span></TableCell>
                    <TableCell align="left">{format(parseISO(JSON.parse(item?.summary)?.delivery_to), 'dd MMM yyyy')}</TableCell>
                    <TableCell align="left">{format(parseISO(item?.date), 'dd MMM yyyy')}</TableCell>
                    <TableCell align="left">{item?.orderType}</TableCell>
                    <TableCell align="right">{item?.total}</TableCell>
                    <TableCell align="left"><Chip label={item?.status_label} /></TableCell>
                    <TableCell align="left">{item?.user_details?.name}</TableCell>
                </TableRow>
            )
        }

        return(
            <TableContainer component={Paper}>
                <Table ref={tableRef}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight: "bold"}}>S.No.</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Order No.</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>{portal === "buyer" ? "Supplier" : "Buyer"}</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Date</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Expected Delivery Date</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Order Type</TableCell>
                            <TableCell align="right" sx={{fontWeight: "bold"}}>Total (Rs.)</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Status</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Assigned To</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.length !== 0 && data?.map((item, index) => (<ReportsTableDataRow index={index} key={index} item={item} />))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
    
    return(
        <>
        {  loading ? 
            <LoadingComponent loading={loading} />
            :
            <Layout title={title}>
                {data?.length !==0 ?
                <>
                    <Box sx={{mb:2, display: "flex", gap: 1}}>
                        <DownloadPDF tableRef={tableRef} pdfTitle={title} printString={printString} />
                        <DownloadExcel tableRef={tableRef} filename={title} sx={{background: "#217346"}} />
                    </Box>
                    <ReportsTable />
                </> 
                :
                <NoData />
                }
            </Layout>
        } 
        </>
    )
}
