import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Layout from '../../components/Layout';
import { GlobalContext } from '../../contexts/GlobalContext'
import { useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { format, parse } from 'date-fns'
import { IconPhone, IconBrandWhatsapp, IconCheck, IconX, IconQuestionMark } from '@tabler/icons-react';
import NewCheckinMultiButton from '../../components/NewCheckinMultiButton';
import Comments from '../../components/Comments';
import Autocomplete from '@mui/material/Autocomplete';
import { Alert, TextField } from '@mui/material';
import { useTheme } from '@emotion/react';
import LoadingComponent from '../../components/LoadingComponent';


function BuyerPlan() {
    let { id } = useParams();
    const navigate = useNavigate();
    const { url, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken  } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const theme = useTheme();
 
    const [plan, setPlan] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [members, setMembers] = useState([]);
    const [visits, setVisits] = useState([]);

    useEffect(() => {
        fetchData();
        if(window.cordova) {
            window.cordova.plugins.firebase.analytics.setCurrentScreen("Buyer Plan");
        }
    }, [ ]);

    const fetchData = () => {
        axios.post( url + 'api/buyers/plans/view', {
            customer: customer,
            contact: contact,
            phone: phone,
            id: id,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            setPlan(response.data.plan);
            setDrivers(response.data.drivers);
            setMembers(response.data.members);
            setVisits(response.data.visits);
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const Person = (prop) => {
        const openLink = (link) => {
            if(!window.cordova) {
                window.open(link, '_blank').focus();
            } else {
                cordova.InAppBrowser.open(link, "_system");
            }
        }

        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', mt:2 }}>
                <Box sx={{ display: 'flex', justifyContent:"between", alignItems:"center", flexDirection: 'row', gap: 1, p: 3 }}>
                    <Typography variant="body" component="p" sx={{flexGrow: 1, mb:0 }}>{prop.data.name}</Typography>
                    <Box sx={{ display: 'flex', alignItems:"center", flexDirection: 'row', gap: 4 }}>
                        {prop.data.phone && <IconPhone onClick={event => { openLink('tel:' + prop.data.phone) }}></IconPhone>} 
                        {prop.data.phone && <IconBrandWhatsapp onClick={event => { openLink('https://wa.me/' + prop.data.phone) }}></IconBrandWhatsapp>}
                    </Box>
                </Box>
            </Paper>
        )
    }
    
    

    const Visit = (prop) => {
        // console.log({prop})
        const [status, setStatus] = useState(prop.data.buyer_status);
        const updateVisitStatus = (event, newStatus) => {
            axios.post( url + 'api/buyers/plans/status', {
                customer: customer,
                contact: contact,
                phone: phone,
                id: prop.data.id,
                status: newStatus,
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setStatus(newStatus);
            })
            .catch(function (error) {
                console.log(error);
            });
        }

        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt:2 }}>
                    <Box sx={{ px: 2, py:2 }}>
                        <Typography variant="subtitle1" component="h6">{prop.data.seller_name}, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{prop.data.city}</span></Typography>
                    </Box>
                    <Grid container>
                        <Grid item xs={6} sx={{ borderTop: 1, borderBottom: 1, borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">City</Typography>
                            <Typography variant="body" component="p">{prop.data.city ? prop.data.city : ''}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderTop: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Date & Time</Typography>
                            <Typography variant="body" component="p">{prop.data.date ? format(parse(prop.data.date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd MMM yyyy hh:mm a') : '-'}</Typography>
                        </Grid>
                        {!!prop?.data?.remarks &&
                            <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                <Typography variant="overline" component="p">Remarks</Typography>
                                <Typography variant="body" component="p">{prop?.data?.remarks || '-'}</Typography>
                            </Grid>
                        }
                    </Grid>
                    <Box sx={{ px: 2, py:2 }}>
                        <ToggleButtonGroup value={status} exclusive onChange={updateVisitStatus} fullWidth={true}>
                            <ToggleButton value={1} sx={{ gap: 2 }} color="success"><IconCheck /> Yes</ToggleButton>
                            <ToggleButton value={2} sx={{ gap: 2 }} color="warning"><IconQuestionMark /> Maybe</ToggleButton>
                            <ToggleButton value={3} sx={{ gap: 2 }} color="error"><IconX /> No</ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
            </Paper>
        )
    }
    const Info = () => {
        console.log({plan})
        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                <Grid container>
                    <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Plan #</Typography>
                        <Typography variant="body" component="p">{plan.id}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Date</Typography>
                        <Typography variant="body" component="p">{plan.date ? format(parse(plan.date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd MMM yyyy  hh:mm a') : ''}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Return Date</Typography>
                        <Typography variant="body" component="p">{plan.return_date ? format(parse(plan.return_date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd MMM yyyy  hh:mm a') : ''}</Typography>
                    </Grid>

                    {plan?.plan_additional_detail?.length !== 0 && plan?.plan_additional_detail.map((object, index) => {
                        return <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }} key={index}>
                                    <Typography variant="overline" component="p">{object?.additional_details?.name}</Typography>
                                    <Typography variant="body" component="p">{object?.value}</Typography>
                                </Grid>
                    })                        
                    }
                </Grid>
            </Paper>
        )
    }

    

    
    const uniqueVisits = [...visits].reduce((accumulator, currentValue) => {
        const existingItem = accumulator.find((item) => item.seller_name === currentValue.seller_name);
        if (!existingItem) {
          accumulator.push(currentValue);
        }
        return accumulator;
      }, []);


    const [selectedVisit, setSelectedVisit] = useState(null);

    let filteredVisits = [];

    if(selectedVisit){
        filteredVisits = visits.filter((option) => {
            return selectedVisit.seller_name === option.seller_name
        })
    }

    return (
        <>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title="Plan">
                <Box>

                    <Info />
                    
                    {Object.keys(members).length ?
                        <>
                            <Typography variant="h6" component="h6" sx={{ mt:4 }}>Team Members ({Object.keys(members).length})</Typography>
                            {members?.map((row, index) => (
                                <Person key={row.id} data={row}></Person>
                            ))}
                        </>
                        : ''
                    }

                    {Object.keys(drivers).length ?
                    <>
                        <Typography variant="h6" component="h6" sx={{ mt:4 }}>Drivers ({Object.keys(drivers).length})</Typography>
                        {drivers?.map((row, index) => (
                            <Person key={row.id} data={row}></Person>
                        ))}
                    </> : ''
                    }

                    <Alert sx={{mt: 4}} severity="info">
                        <strong>Tap the + button to create visits</strong>
                    </Alert>                    

                    {Object.keys(visits).length ?
                    <>
                    <Typography variant="h6" component="h6" sx={{ mt:4, mb:1 }}>Search Visits ({Object.keys(visits).length})</Typography>
                    
                    <Autocomplete disablePortal autoHighlight={true} options={uniqueVisits || null} sx={{padding: "10px 0"}}
                    getOptionLabel={(option) => option.seller_name}
                    onChange={(event, value) => setSelectedVisit(value)}
                    clearOnBlur={true}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        label="Select a Seller"
                        variant="outlined"
                        />
                    )} 
                    />

                    </> 
                    : 
                    <></>}

                    { selectedVisit && filteredVisits.length ? filteredVisits?.map((option, index) => {
                        return <Visit data={option} key={index} />
                    }) 
                    : 
                    <></>}

                    {(Object.keys(visits).length !== 0 && !selectedVisit) &&
                        <>
                            {visits.map((row) => (
                                <Visit key={row.id} data={row}></Visit>
                            ))}
                        </>
                    }

                    {Object.keys(visits).length === 0 && 
                        <Box>
                            <Typography variant="caption" component="p" sx={{ mt:4, textAlign: "center" }}>No visits planned yet</Typography>
                        </Box>
                    
                    } 


                    <NewCheckinMultiButton 
                        plan={plan.id} 
                        createdInAreas={[ 
                            {label : "Buyer App"},
                            {   
                                label : `Plan #${plan.id}`,     
                                link: `/clients/plans/view/${plan.id}`  
                            },
                        ]} 
                    />
                    <Comments area="plans" ref_id={plan?.id} />
                </Box>
            </Layout>
        }
        </>
    );
}

export default BuyerPlan;
