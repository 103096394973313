import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react"
import Layout from "../../../components/Layout";
import LoadingComponent from "../../../components/LoadingComponent";
import { GlobalContext } from "../../../contexts/GlobalContext";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { parseISO, format } from 'date-fns';
import { Alert, Autocomplete, Button, Chip, TextField, Typography } from "@mui/material";
import DownloadPDF from "../../../components/DownloadPDF";
import DownloadExcel from "../../../components/DownloadExcel";
import { Box } from "@mui/system";
import NoData from "../../../components/NoData";


export default function PendingOrders() {

    const { url,  customer, token, portal, getUniqueByKey  } = useContext(GlobalContext);

    const title = "Pending Orders"
    
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState([]); 
    const [filteredData, setFilteredData] = useState([]);

    const [selectedFromDate, setSelectedFromDate] = useState("");
    const [selectedToDate, setSelectedToDate] = useState("");  
    const [selectedParty, setSelectedParty] = useState(null);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    
    const [partyOptions, setPartyOptions] = useState([]);

    const tableRef = useRef();

    const printString = portal === "buyer" ? `Buyer : ${data[0]?.buyer_details?.name}, ${data[0]?.buyer_details?.city_details?.name}` : `Supplier : ${data[0]?.seller_details?.name}, ${data[0]?.seller_details?.city_details?.name}`;

    useEffect(() => {

        const fetchData = () => {
            axios.post( url + `api/reports/pending/orders/${portal}` , {
                buyer: portal === 'buyer' ? customer : "" ,
                supplier: portal === 'supplier' ? customer : "",               
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                console.log({response})
                setData(response?.data)
                const partyOptionsRaw = response?.data?.map(
                    item => (
                        (portal === "buyer")
                        ? { id: item.seller_details.id, name:  item.seller_details.name }
                        : { id: item.buyer_details.id, name:  item.buyer_details.name }
                    )
                )
                setPartyOptions( getUniqueByKey(partyOptionsRaw, 'id') );
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            });
        };

        fetchData() 

    }, [])

    const ReportsTable = ({data}) => {

        function calculateTotal() {
            return data?.reduce((acc, item) => {
                acc.totalSelectedQty += parseFloat(item?.selected_qty);
                acc.totalDispatchedQty += parseFloat(item?.dispatched);
                acc.totalCancelledQty += parseFloat(item?.cancelled);
                acc.totalExcessQty += parseFloat(item?.excess_qty);
                acc.totalPendingQty += parseFloat(item?.pending);

                return acc;
            } , 
            {
                totalSelectedQty: 0,
                totalDispatchedQty: 0,
                totalCancelledQty: 0,
                totalExcessQty: 0,
                totalPendingQty: 0
            })
        }

        const total = calculateTotal(); 
   
        const ReportsTableDataRow = ({item, index}) => {            

            return(
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="left">{index+1}</TableCell>
                    <TableCell component="th" scope="row">{item?.voucher_no || "-"}</TableCell>
                    <TableCell align="left">{(portal === "supplier" ? item?.buyer_name : item?.supplier_name) || ""}, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{portal === "supplier" ? item?.buyer_details?.city_details?.name : item?.seller_details?.city_details?.name}</span></TableCell>
                    <TableCell align="left">{item?.orderType}</TableCell>
                    <TableCell align="left">{format(parseISO(item?.date), 'dd MMM yyyy')}</TableCell>
                    <TableCell align="left"><Chip label={item?.status_label} /></TableCell>
                    <TableCell align="right">{item?.selected_qty}</TableCell>
                    <TableCell align="right">{item?.dispatched}</TableCell>
                    <TableCell align="right">{item?.cancelled}</TableCell>
                    <TableCell align="right">{item?.excess_qty}</TableCell>
                    <TableCell align="right">{item?.pending}</TableCell>
                    <TableCell>{item?.remarks || "-"}</TableCell>
                </TableRow>
            )
        }

        return(
            <>
            { data?.length === 0 ? <NoData /> :
            <TableContainer component={Paper}>
                <Table ref={tableRef}>
                    <TableHead>
                        <TableRow sx={{backgroundColor: "#F2F3F4"}}>
                            <TableCell sx={{fontWeight: "bold"}}>S.No.</TableCell>
                            <TableCell sx={{fontWeight: "bold"}}>Order No.</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>{portal === "supplier" ? "Buyer" : "Supplier"}</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Order Type</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Date</TableCell>
                            <TableCell align="left" sx={{fontWeight: "bold"}}>Status</TableCell>
                            <TableCell align="right" sx={{fontWeight: "bold"}}>Selected Qty</TableCell>
                            <TableCell align="right" sx={{fontWeight: "bold"}}>Dispatched Qty</TableCell>
                            <TableCell align="right" sx={{fontWeight: "bold"}}>Cancelled Qty</TableCell>
                            <TableCell align="right" sx={{fontWeight: "bold"}}>Excess Qty</TableCell>
                            <TableCell align="right" sx={{fontWeight: "bold"}}>Pending Qty</TableCell>
                            <TableCell sx={{fontWeight: "bold"}}>Remarks</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.length !== 0 && data?.map((item, index) => (<ReportsTableDataRow key={index} item={item} index={index} />))}
                        <TableRow sx={{backgroundColor: "#F2F3F4"}}>
                            <TableCell colSpan={6} sx={{fontWeight: "bold", textAlign: "end"}}>Total</TableCell>
                            <TableCell align="right" sx={{fontWeight: "bold"}}>{total?.totalSelectedQty?.toFixed(2)}</TableCell>
                            <TableCell align="right" sx={{fontWeight: "bold"}}>{total?.totalDispatchedQty?.toFixed(2)}</TableCell>
                            <TableCell align="right" sx={{fontWeight: "bold"}}>{total?.totalCancelledQty?.toFixed(2)}</TableCell>
                            <TableCell align="right" sx={{fontWeight: "bold"}}>{total?.totalExcessQty?.toFixed(2)}</TableCell>
                            <TableCell align="right" sx={{fontWeight: "bold"}}>{total?.totalPendingQty?.toFixed(2)}</TableCell>
                            <TableCell align="right" sx={{fontWeight: "bold"}}></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            }
            </>
        )
    }

    const FilterComponent = () => {
         
        const [fromDate, setFromDate] = useState("");
        const [toDate, setToDate] = useState(""); 
        const [party, setParty] = useState("");

        const handleApply = () => {
            if ( party || (!!fromDate && !!toDate) ){
                setIsFilterApplied(true);
                if ((!!fromDate && !!toDate)){
                    setSelectedFromDate(fromDate);
                    setSelectedToDate(toDate);
                }
                if (party){
                    setSelectedParty(party);
                }

                const filteredData = data.filter((item) => {
                    const date = new Date(item?.date);
                    return (
                        (
                            (!!fromDate && !!toDate)
                                ? date>= new Date(fromDate) && date <= new Date(toDate)
                                : true
                        )
                        &&
                        (
                            party
                            ? (
                                (portal === "buyer")
                                    ? party.id === item.seller_details.id
                                    : party.id === item.buyer_details.id
                            )
                            : true
                        )
                    );
                });

                setFilteredData(filteredData);
            }
            
        }
        
        const handleClear = () => {
            setFromDate("")
            setToDate("")
            setIsFilterApplied(false)
            setSelectedFromDate("");
            setSelectedToDate("");
            setSelectedParty("");
            setFilteredData([]);
        }

        const handleDateRange = () => {
            const uniqueDateList = [... new Set(data?.map(item => item?.date))]?.map(item => new Date(item));
            const sortedDateList = uniqueDateList.sort((a,b)=>(a-b));
            return {
                startDate: format(sortedDateList[0], 'yyyy-MM-dd'),
                endDate: format(sortedDateList[sortedDateList?.length - 1], 'yyyy-MM-dd'),
            }
        }

        useEffect(() => {
            if (selectedFromDate && selectedToDate){
                setFromDate(selectedFromDate);
                setToDate(selectedToDate);
            }
            if (selectedParty){
                setParty(selectedParty);
            }
        }, []);

        return(
            <Paper elevation={3}>
            <Box sx={{marginBottom: "10px", p:2, minWidth: "300px", maxWidth: "700px" }}>  
                <Typography sx={{mb:2, fontWeight: "bold"}} variant="subtitle2" >Date Filter</Typography>              
                <div style={{display: "flex", gap: "10px", width: "100%", marginTop:"10px"}}>
                        <TextField
                        value={fromDate}
                        onChange = {(event) => {setFromDate(event?.target?.value) }}
                        required={true} 
                        helperText={ (!!toDate && !fromDate) ? "Please enter From Date" : "" }
                        FormHelperTextProps={{error: true}}
                        InputProps={{
                            inputProps: {
                            max: toDate || handleDateRange().endDate, 
                            min:  handleDateRange().startDate,
                            },
                        }}
                        disabled={isFilterApplied} 
                        size="small" type="date" fullWidth
                        />
                        <TextField
                        value={toDate}
                        onChange = {(event) => { setToDate(event?.target?.value) }}
                        required={true} 
                        helperText={ (!!fromDate && !toDate) ? "Please enter To Date" : "" }
                        FormHelperTextProps={{error: true}}
                        InputProps={{
                            inputProps: {
                            min: fromDate || handleDateRange().startDate, 
                            max: handleDateRange().endDate,
                            },
                        }}
                        disabled={isFilterApplied} 
                        size="small" type="date" fullWidth
                        />
                        <Autocomplete sx={{ mb: 2 }}  fullWidth
                            options={ partyOptions }
                            size="small"
                            getOptionLabel={(option) => option.name ? option.name : '' }
                            value={party}
                            onChange={(event, newValue) => {
                                setParty(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} required={false} />}
                            isOptionEqualToValue={(option, value) => { 
                                if(!value){
                                    return true
                                }else{
                                    return option?.id == value?.id
                                }
                            }}
                            disabled={isFilterApplied} 
                        />
                </div>

                <div style={{display: "flex", gap: "5px"}}>
                    <Button variant="contained" disabled={isFilterApplied} sx={{mt: 2}} onClick={handleApply} >Apply</Button>  
                    {isFilterApplied && <Button variant="contained" color="error" disabled={!isFilterApplied} sx={{mt: 2}} onClick={handleClear} >Clear</Button> }
                </div>
            </Box>
            
            </Paper>
        )
    }

    return(
        <>
        {  loading ? 
            <LoadingComponent loading={loading} />
            :
            <Layout title={title}>
                {data?.length !==0 ?
                    <>
                        <Box sx={{mb:2, display: "flex", gap: 1}}>
                            <DownloadPDF tableRef={tableRef} pdfTitle={title} printString={printString}/>
                            <DownloadExcel tableRef={tableRef} filename={title} sx={{background: "#217346"}} />
                        </Box>
                        <FilterComponent />
                        {
                            isFilterApplied && 
                                <Alert severity="success" sx={{my: 1}}>
                                    <Box sx={{fontWeight: "bold"}}>Filter Applied</Box>
                                    {
                                        (selectedFromDate && selectedToDate) 
                                        &&
                                        (
                                            <>
                                                <Box>From Date: <span style={{fontWeight: "bold"}}>{format(parseISO(selectedFromDate), 'dd MMM yyyy')}</span></Box>
                                                <Box>To Date:<span style={{fontWeight: "bold"}}>{format(parseISO(selectedToDate), 'dd MMM yyyy')}</span></Box>
                                            </>
                                        )
                                    }
                                    {
                                        (selectedParty) 
                                        &&
                                        (
                                            <>
                                                <Box>
                                                    { portal === "buyer" ? "Supplier" : "Buyer" }
                                                    : <span style={{fontWeight: "bold"}}>{ selectedParty?.name }</span></Box>
                                            </>
                                        )
                                    }
                                </Alert>
                        }
                        {
                            isFilterApplied ? <ReportsTable data={filteredData} /> : <ReportsTable data={data} />

                        }
                    </> 
                    :
                    <NoData />
                }
            </Layout>
        } 
        </>
    )
}
