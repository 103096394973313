import { useContext } from "react";
import Layout from "../../components/Layout";
import { GlobalContext } from "../../contexts/GlobalContext";


export default function PaymentPlanner() {

    const { url,  customer, token, portal  } = useContext(GlobalContext);
    console.log({ url,  customer, token, portal  })

    return(
        <>
            <Layout title={"Payment planner"} backToHome="true">
                
            </Layout>
        </>
    )
}