import React, { useContext, useEffect, useState } from 'react'
import axios from "axios";
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { IconPlus } from '@tabler/icons-react';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { GlobalContext } from '../contexts/GlobalContext'
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { Alert, Snackbar } from '@mui/material';

function NewCheckinButton(props) {
    const { url, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken, ledgers, portal  } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [ledger, setLedger] = useState("")
    const [date, setDate] = useState("")
    const [remarks, setRemarks] = useState("")

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    
    const [fetchedCustomers, setfetchedCustomers] =  useState([]);

    const handleOpen = () => {
        setOpen(true);
    }

    const clearModalData = () => {
        setLedger("");
        setDate("");
        setRemarks("");
        setfetchedCustomers([]);
    }

    const handleClose = () => {
        setOpen(false);
        clearModalData()
    }

    const handleAlertClose = () => {
        setOpenAlert(false)
    }

    function fetchCustomers(){
        axios.post( url + 'api/linkedcustomers', { 
            customer: customer,
            portal: portal
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            // console.log("response", response);
            console.log("Customer details fetched successfully")
            setfetchedCustomers(response?.data)
        })
        .catch(function (error) {
            console.log(error);
        })
    }

    useEffect(() => { if(open){fetchCustomers()} }, [open]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        if(props.buyer  == undefined || props.seller  == undefined) {
            if(portal == "supplier") {
                var seller = customer;
                var buyer = ledger?.id;
            } else {
                var seller = ledger?.id;
                var buyer = customer;
            }
        } else {
            var seller = props.seller;
            var buyer = props.buyer;
        }
        axios.post( url + 'api/checkin/add', { 
            customer: customer,
            contact: contact,
            phone: phone,
            date: date,
            remarks: remarks,
            seller: seller,
            buyer: buyer,
            plan: props.plan,
            created_in_areas : JSON.stringify(props.createdInAreas),
            creator: portal == "supplier" ? 2 : 3,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            console.log("response", response);
            if(response.data.error == 0) {
                setLoading(false);
                // window.location.reload();
                // navigate(0);
                props.refetch ? props.refetch() : document.location.reload();
                handleClose()
            }else if(response.data.error == 1){
                setOpenAlert(true)
                handleClose()
                setLoading(false);
            }
        })
        .catch(function (error) {
            setLoading(false);
            console.log(error);
        });
    }



    // console.log({format: format(new Date(), "yyyy-MM-dd'T'HH:mm")})
    // console.log({time:  format(new Date(), "yyyy-MM-dd")})
    return (
        <>
        <div>
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>New Visit</DialogTitle>
                <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                    <form onSubmit={handleSubmit}>
                        <InputLabel shrink>Date & Time</InputLabel>
                        <TextField sx={{ mb: 2 }} fullWidth required={true} type="datetime-local" 
                            value={date}
                            InputProps={{
                                inputProps: {
                                  max: `${format(new Date(), "yyyy-MM-dd")}T23:59`,
                                },
                              }}
                            onChange={event => {
                                const { value } = event.target;
                                setDate(value);
                            }} />
                        { (props.buyer  == undefined || props.seller == undefined) &&
                            <Box>
                                <InputLabel shrink>{ portal == "supplier" ? "Buyer" : "Supplier"}</InputLabel>
                                <Autocomplete sx={{ mb: 2 }}
                                    options={ fetchedCustomers }
                                    getOptionLabel={(option) => option?.name || '' }
                                    value={ledger}
                                    onChange={(event, newValue) => {
                                        setLedger(newValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} required={true} />}
                                    isOptionEqualToValue={(option, value) => {
                                        if(value == ""){
                                            return true
                                        }
                                        return option?.id === value?.id
                                    }}
                                />
                            </Box>
                        }
                        <InputLabel shrink>Remarks</InputLabel>
                        <TextField sx={{ mb: 2 }} fullWidth required={false} 
                            value={remarks}
                            onChange={event => {
                                const { value } = event.target;
                                setRemarks(value);
                            }} 
                            rows={4}
                            multiline={true}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                            <LoadingButton type="submit" loading={loading} variant="contained">Save</LoadingButton>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
            {props.buttonType=="card" ?
                <Card sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', minHeight: '143px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 4, py:4 }} onClick={handleOpen}>
                    { props.icon }
                    <Typography variant="body1" component="p" sx={{ textAlign: 'center'}}>New Check-In</Typography>
                </Card>
                :
                <Fab color="primary" aria-label="add" size={"medium"} onClick={handleOpen} sx={{ position: "fixed", bottom: (theme) => theme.spacing(2), right: (theme) => theme.spacing(2) }}>
                    <IconPlus />
                </Fab>
            }
            
        </div>
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
            <Alert onClose={handleAlertClose} severity="error" variant="filled"  sx={{ width: '100%' }}>
                Another check-in already exists for this Date, Buyer, and Supplier!
            </Alert>
        </Snackbar>
        </>
    )
}
export default NewCheckinButton;