import { LoadingButton } from '@mui/lab';
import { Alert, Autocomplete, Box, Dialog, DialogContent, DialogTitle, Fab, Grid, InputLabel, MenuItem, Select, TextField, } from '@mui/material'
import { IconFilter  } from '@tabler/icons-react'
import axios from 'axios';
import React, { useContext, useState } from 'react'
import { GlobalContext } from '../contexts/GlobalContext'; 
import { FilterDataContext } from '../contexts/FilteredDataContext';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';


function FilterForm({currentTab, navigateURL}) {

    const navigate = useNavigate()

    const { url, user, token, portal, customer  } = useContext(GlobalContext);
    const { setFilterDataBuyer, setFilterDataSupplier,  setFilterDataFromDate, setFilterDataToDate,
          clearContextState, setFilterDataStatus, setFilterDataQuery } = useContext(FilterDataContext);

    const [ modalOpen, setModalOpen ] = useState(false);
    const [loading, setLoading] = useState(false);

    const [selectedBuyer, setSelectedBuyer] = useState("")
    const [selectedSupplier, setSelectedSupplier] = useState("")
    const [selectedFromDate, setSelectedFromDate] = useState("")
    const [selectedToDate, setSelectedToDate] = useState("") 

    const [buyerOptions, setBuyerOptions] = useState([])
    const [supplierOptions, setSupplierOptions] = useState([])
    const [selectedCurrentStatus, setSelectedCurrentStatus] = useState(99);

    const [queryData, setQueryData] = useState("");

    // const currentTab = { 
    //     name: "Checkins", 
    //     status: { 0: "Upcoming", 1: "Ckecked-in", 2: "Ordered", 3: "Not Ordered", 4: "Not Visited", 99: "All Data",}
    // };   

    function clearFilterData(){
        setLoading(true);
        setModalOpen(false);
        setSelectedBuyer("")
        setSelectedSupplier("");
        setSelectedFromDate();
        setSelectedToDate();
        setSelectedCurrentStatus(99);
        setBuyerOptions([]);
        setSupplierOptions([]);
        setQueryData("");
        setLoading(false);
    }

    function setGlobalState(){

        portal === "supplier" ? setFilterDataBuyer(selectedBuyer) : setFilterDataBuyer(customer);
        portal === "buyer" ? setFilterDataSupplier(selectedSupplier) : setFilterDataSupplier(customer);
        selectedFromDate ? setFilterDataFromDate(format(new Date(new Date(selectedFromDate).setHours(0,0,0,0)), 'yyyy-MM-dd HH:mm:ss')) :  setFilterDataFromDate("");
        selectedToDate ? setFilterDataToDate(format(new Date(new Date(selectedToDate).setHours(23,59,59,999)), 'yyyy-MM-dd HH:mm:ss')) :  setFilterDataToDate("");
        selectedCurrentStatus !== 99 ? setFilterDataStatus(selectedCurrentStatus) : setFilterDataStatus("allData");
        if(currentTab?.name === "Invoices" || currentTab?.name === "Orders" || currentTab?.name === "CreditNotes" || currentTab?.name === "DebitNotes"){
            setFilterDataQuery(queryData.trim())
        }
    }
    
    function handleSubmit(){
        if((selectedFromDate && selectedToDate) || (!selectedFromDate && !selectedToDate)){
            setLoading(true);
            clearContextState()
            setGlobalState()
            clearFilterData();
            setLoading(false);
            navigate(navigateURL)
        }
        
    }

    const fetchBuyers = (query) => {
        if (query.length) {
            axios.post( url + 'api/admin/customers', {
                mode: "buyer",
                supplier: customer,
                search_term: query
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setBuyerOptions(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }

    const fetchSuppliers = (query) => {
        if (query.length) {
            axios.post( url + 'api/admin/customers', {
                mode: "supplier",
                buyer: customer,
                search_term: query
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setSupplierOptions(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }

//  console.log({selectedFromDate, selectedToDate})

  return (
    <>
        <Fab  color="primary" aria-label="add" onClick={() => {setModalOpen(true)}} 
            sx={{
                position: "fixed", 
                bottom: (theme) => theme.spacing(2), 
                left: (theme) => theme.spacing(2) 
            }}
            size={"medium"}
        >
            <IconFilter />
        </Fab>

        <Dialog onClose={clearFilterData} open={modalOpen}>
            <DialogTitle><b>Filter</b></DialogTitle>
            <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>

                {(currentTab?.name === "Orders" || currentTab?.name === "CustomOrders" ||  currentTab?.name === "Invoices" || currentTab?.name === "CreditNotes" || currentTab?.name === "DebitNotes" || currentTab?.name === "Payments") && <Alert severity="info" sx={{ mb: 4 }}>Unproccessed data will not be listed in filtered data</Alert>}

                {
                    portal === "supplier" ? 
                    (
                        <>
                            <InputLabel shrink>Buyer </InputLabel>
                            <Autocomplete sx={{ mb: 2 }}
                                
                                options={ buyerOptions }
                                size="small"
                                getOptionLabel={(option) => option.name ? option.name : '' }
                                onInputChange={(event, newInputValue) => { setBuyerOptions([]); fetchBuyers(newInputValue) }}
                                value={selectedBuyer}
                                onChange={(event, newValue) => {
                                    setSelectedBuyer(newValue)
                                }}
                                renderInput={(params) => <TextField {...params} required={false} />}
                                isOptionEqualToValue={(option, value) => { 
                                    if(!value){
                                        return true
                                    }else{
                                        return option?.id == value?.id
                                    }
                                }}
                            />
                        </>
                    )
                    :
                    (
                        <>
                            <InputLabel shrink>Supplier </InputLabel>
                            <Autocomplete sx={{ mb: 2 }}
                                
                                options={ supplierOptions }
                                size="small"
                                getOptionLabel={(option) => option.name ? option.name : '' }
                                onInputChange={(e, newInputValue) => { setSupplierOptions([]); fetchSuppliers(newInputValue) }}
                                value={selectedSupplier}
                                onChange={(e, newValue) => {
                                    setSelectedSupplier(newValue)
                                }}
                                renderInput={(params) => <TextField {...params} required={false} />}
                                isOptionEqualToValue={(option, value) => { 
                                    if(!value){
                                        return true
                                    }else{
                                        return option?.id == value?.id
                                    }
                                }}
                            />
                        </>
                    )
                }
                
                <Grid container spacing={2} sx={{mb:2}}>
                    <Grid item xs={12}>
                        <InputLabel shrink>From Date</InputLabel>
                        <TextField type="date"  variant="outlined" fullWidth 
                        value={selectedFromDate}
                        size="small"
                        onChange = {(event) => {setSelectedFromDate(event?.target?.value)}}
                        InputLabelProps={{ shrink: true }} 
                        helperText={(selectedToDate && !selectedFromDate ) ? "Please enter From Date" : "" }
                        FormHelperTextProps={{error: true}}
                        required={currentTab?.name === "Orders" ? true : false}
                        InputProps={{
                            inputProps: {
                                max: selectedToDate || "", 
                            },
                            }}
                        />
                        
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel shrink>To Date</InputLabel>
                        <TextField type="date"  variant="outlined" fullWidth 
                        value={selectedToDate}
                        size="small"
                        onChange = {(event) => { setSelectedToDate(event?.target?.value) }}
                        InputLabelProps={{ shrink: true }}
                        helperText={(selectedFromDate && !selectedToDate ) ? "Please enter To Date" : "" }
                        FormHelperTextProps={{error: true}}
                        required={currentTab?.name === "Orders" ? true : false}
                        InputProps={{
                            inputProps: {
                                min: selectedFromDate || "",
                            },
                            }}
                        />                                    
                    </Grid>
                </Grid>                
                
                {
                    (currentTab?.name === "Checkins" || currentTab?.name === "CustomOrders" || currentTab?.name === "Payments" || currentTab?.name === "Tickets" || currentTab?.name === "Orders" || currentTab?.name === "Invoices") && 
                    <>
                    <InputLabel shrink>Select status</InputLabel>
                    <Select
                        
                        sx={{ mb: 2 }}
                        size="small"
                        fullWidth
                        value={selectedCurrentStatus}
                        onChange={(event) => setSelectedCurrentStatus(event.target.value) }
                    >
                        {
                            Object.entries(currentTab.status).map( ([key, value], index) => (<MenuItem value={+key} key={index}>{value}</MenuItem>))
                        }
                    </Select>
                    </>
                }

                {(currentTab?.name === "Invoices" || currentTab?.name === "Orders" || currentTab?.name === "CreditNotes" || currentTab?.name === "DebitNotes") &&
                    <>
                        <InputLabel shrink>Query</InputLabel>
                        <TextField sx={{mb:2}} 
                            value={queryData}
                            onChange={ (event) => {setQueryData(event?.target?.value)} }
                            placeholder='Invoice number, Order number ...' 
                            fullWidth 
                            size={"small"} 
                            variant="outlined" 
                        />
                    </>
                }

                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, mt: 3 }}>
                    <LoadingButton loading={loading} variant="contained" color={"error"} onClick={clearFilterData} fullWidth>Clear Filter</LoadingButton>
                    <LoadingButton loading={loading} disabled={!((selectedFromDate && selectedToDate) || (!selectedFromDate && !selectedToDate))} variant="contained" onClick={handleSubmit} fullWidth>Filter</LoadingButton>                    
                </Box>
            </DialogContent>
        </Dialog>
    </>
  )
}

export default FilterForm