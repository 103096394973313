import React, { useContext } from 'react'
import axios from "axios";
import Paper from '@mui/material/Paper';
import Layout from '../../components/Layout';
import { GlobalContext } from '../../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import NewPlanButton from '../../components/NewPlanButton';
import { format, parse } from 'date-fns';
import LoadingComponent from '../../components/LoadingComponent'; 
import { Button } from '@mui/material';
import PlansFilterForm from '../../components/PlansFilterForm';
import ErrorMessage from '../../components/ErrorMessage';
import ScrollComponent from '../../components/ScrollComponent';
import { useInfiniteQuery } from 'react-query';
import BackToTop from '../../components/BackToTop';

function BuyerPlansPage() {
    const navigate = useNavigate();
    const { url, customer, token } = useContext(GlobalContext);
    const title = "Plans";

    const fetcher = async (page) => {        
        const response = await axios.post( url + 'api/buyers/plans?page=' + page, {
            customer: customer,
        }, { headers: { Authorization: `Bearer ${token}` }})

        return response.data
    }

    const { isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery( 
        'plans',  
        ({ pageParam = 1 }) => fetcher(pageParam),
        {
          getNextPageParam: (lastPageData) => { 
            if(lastPageData?.current_page < lastPageData.last_page){
                return lastPageData?.current_page + 1
            }else{
                return undefined
            } 
          },
          refetchOnWindowFocus: false,
        }
    );

    // console.log({isLoading, data, isError, error, fetchNextPage, hasNextPage})

    const Item = ({data}) => {
        return (
            <Paper onClick={() => { navigate("/buyer/plan/" + data.id) }} sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                <Grid container>
                    <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Plan #</Typography>
                        <Typography variant="body" component="p">{data.id}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Date</Typography>
                        <Typography variant="body" component="p">{data.date ? format(parse(data.date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd MMM yyyy  hh:mm a') : ''}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Return Date</Typography>
                        <Typography variant="body" component="p">{data.return_date ? format(parse(data.return_date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd MMM yyyy  hh:mm a') : ''}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ borderTop: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Button variant="contained"  onClick={() => navigate("/buyer/plan/" + data.id)} >View Plan</Button>
                    </Grid>
                </Grid>
            </Paper>
        )
    }      

    return (
        <>
        {
            isError ? 
            <ErrorMessage title={title} message={error?.message} /> 
            :
            <Box>
                {  isLoading ? 
                    <LoadingComponent loading={isLoading} />
                    : 
                    <Layout title={title} backToHome="true">
                        <Box sx={data?.pages[0]?.data?.length !== 0 ? { borderBottom: 1, borderColor: 'divider' } : {}}>
                            <ScrollComponent data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} Item={Item}  />                            
                        </Box>
                        <PlansFilterForm />
                        <NewPlanButton refetch={refetch}
                            createdInAreas={[ 
                                {label : "Buyer App"},
                                {label : "Add Button"},
                            ]} 
                        />
                        <BackToTop />
                    </Layout>
                }        
            </Box>
        }
        </>
    )    
}

export default BuyerPlansPage;
