import React, { useContext } from 'react'
import axios from "axios";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../../components/Layout';
import { GlobalContext } from '../../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import { format, parse } from 'date-fns'
import Button from '@mui/material/Button';
import UploadButton from '../../components/VoucherUploader';
import CheckinButton from '../../components/CheckinButton';
import NewCheckinButton from '../../components/NewCheckinButton';
import MediaGrid from '../../components/MediaGrid';
import { Alert, Chip, TextField } from '@mui/material';
import LoadingComponent from '../../components/LoadingComponent';
import { IconCalendar } from '@tabler/icons-react';
import FilterForm from '../../components/FilterForm';
import { FilterDataContext } from '../../contexts/FilteredDataContext';
import { useInfiniteQuery } from 'react-query';
import ScrollComponent from '../../components/ScrollComponent';
import ErrorMessage from '../../components/ErrorMessage';
import BackToTop from '../../components/BackToTop';


function CheckInsPage(){

    let { status } = useParams();
    const navigate = useNavigate();

    const { url,  customer, token, portal  } = useContext(GlobalContext);
    const { setFilterDataBuyer, setFilterDataSupplier,  setFilterDataFromDate,
        setFilterDataToDate, clearContextState, setFilterDataStatus } = useContext(FilterDataContext);
    
    const title = "Check-Ins";
    
    const fetcher = async (page, pageStatus) => {        
        const response = await axios.post( url + 'api/visits?page=' + page, {
            buyer: portal === "buyer" ? customer : "",
            supplier: portal === "supplier" ? customer : "",
            status: pageStatus,
        }, { headers: { Authorization: `Bearer ${token}` }})

        return response.data
    }

    const { isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery( 
        ['checkins', status],  
        ({ pageParam = 1 }) => fetcher(pageParam, status),
        {
          getNextPageParam: (lastPageData) => { 
            if(lastPageData?.current_page < lastPageData.last_page){
                return lastPageData?.current_page + 1
            }else{
                return undefined
            } 
          },
          refetchOnWindowFocus: false,
        }
    );

    // console.log({ isLoading, data, error, isError, fetchNextPage, hasNextPage })

    const currentTab = { name: "Checkins", status: { 0: "Upcoming", 1: "Ckecked-in", 2: "Ordered", 3: "Not Ordered", 4: "Not Visited", 99: "All Data",}};

    const Item = ({data}) => {
        
        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                
                <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                    <Typography variant="overline" component="p">{  portal == "supplier" ?  "Buyer" : "Supplier" }</Typography>
                    <Typography variant="h6" component="h6">{  portal == "supplier" ? data.buyer_name : data.seller_name }, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{data?.city}</span></Typography>
                    {
                        portal === "buyer" && !!data?.supplier_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.supplier_msme} /></Box>
                    }
                    {
                        portal === "supplier" && !!data?.buyer_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.buyer_msme} /></Box>
                    }
                </Box>
                <Grid container>
                    <Grid item xs={12} sx={{borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">{ (data?.status_label === "Upcoming" || data?.status_label === "Checked-In") ? "Plan Date" : "Date"}</Typography>
                        <Typography variant="body" component="p">{!!data.date ? format(parse(data.date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd MMM yyyy HH:mm:ss') : '-'}</Typography>
                    </Grid>
                    {data?.status_label === "Checked-In" &&
                        <Grid item xs={12} sx={{borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Check-in Date</Typography>
                            <Typography variant="body" component="p">{!!data?.checkin_on ? format(new Date(data?.checkin_on), 'dd MMM yyyy HH:mm:ss') :"-"}</Typography>
                        </Grid>
                    }
                    <Grid item xs={12} sx={{borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">{status == 2 ? "Check-in Remarks" : "Remarks"}</Typography>
                        <Typography variant="body" component="p">{ data.remarks ? data.remarks : "-" }</Typography>
                    </Grid>
                    {
                        data?.order_details !== null ? 
                            <>
                            {
                                    data.order_details?.remarks ? 
                                    <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                                        <Typography variant="overline" component="p">Order Remarks</Typography>
                                        <Typography variant="body" component="p">{ data.order_details.remarks }</Typography>
                                    </Grid> : <></>
                            }
                            <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                                <MediaGrid dir="orders" images={data?.order_details.images} />
                            </Grid>
                            <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                                {data?.order_details?.show_button ?
                                    <Button variant="contained" fullWidth  
                                    onClick={() => { if(status != 0) {  navigate("/order/" + data?.order_details.id) } }}>View Order</Button>
                                    :
                                    <Alert severity="warning">This order data is being processed</Alert>
                                }
                            </Grid>
                            </> 
                        : 
                            <>
                            {
                                data?.entry_details !== null ? 
                                <>
                                {
                                    data.entry_details?.remarks ? 
                                    <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                                        <Typography variant="overline" component="p">Order Remarks</Typography>
                                        <Typography variant="body" component="p">{ data.entry_details.remarks }</Typography>
                                    </Grid> : <></>
                                }
                                <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                                    <MediaGrid dir="orders" images={data?.entry_details.images} />
                                </Grid> 
                                <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                                    <Alert severity="warning">This order data is being processed</Alert> 
                                </Grid>
                                </>
                                : 
                                <></> 
                            }
                            </>
                    }
                </Grid>
                { (data.status == 1 || data.status == 2) &&
                    <Box sx={{ borderTop: 1, borderColor: 'divider',  display: 'flex', flexDirection: 'column', gap: 3, p: 1 }}>
                        <ItemAction itemStatus={data.status} id={data.id} order={data.order_id} buyer={data.buyer} seller={data.seller} buyer_name={data?.buyer_name} seller_name={data?.seller_name} />
                    </Box>
                }
                
            </Paper>
        )
    }

    function ItemAction ({itemStatus, id, order, buyer, seller, seller_name, buyer_name}) {

        if(portal == "supplier") {
            if(itemStatus == 1) {
                return (<CheckinButton creator="2" id={id} refetch={refetch} />);
            } else if (itemStatus == 2) {
                return (<UploadButton buttonType="button" title="Order" seller={customer} buyer={buyer} link={id} voucher_type="orders" refetch={refetch} buyer_name={buyer_name} />);
            }
        } else {
            if(itemStatus == 1) {
                return (<CheckinButton creator="3" id={id} refetch={refetch} />);
            } else if (itemStatus == 2) {
                return (<UploadButton buttonType="button" title="Order" seller={seller} buyer={customer} link={id} voucher_type="orders" refetch={refetch} seller_name={seller_name} />);
            }
        }
    }

    function handleTodayData() { 
        clearContextState();
        portal === "supplier" ? setFilterDataBuyer("") : setFilterDataBuyer(customer);
        portal === "buyer" ? setFilterDataSupplier("") : setFilterDataSupplier(customer);
        setFilterDataFromDate(format(new Date(new Date().setHours(0,0,0,0)), 'yyyy-MM-dd HH:mm:ss'));
        setFilterDataToDate(format(new Date(new Date().setHours(23,59,59,999)), 'yyyy-MM-dd HH:mm:ss'));
        setFilterDataStatus(status)
        navigate("/filtered_checkins")        
    }


    return (
        <>       
            {isError ? <ErrorMessage title={title} message={error?.message} /> :
            <>
            {  isLoading ? 
                <LoadingComponent loading={isLoading} />
                : 
                <Layout title={title} backToHome="true">
                    <Box sx={data?.pages[0]?.data?.length !== 0 ? { borderBottom: 1, borderColor: 'divider' } : {}}>

                        <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={parseInt(status)} onChange={(event, newStatus) => { navigate("/checkins/" + newStatus); }}>
                            <Tab label="Upcoming" />
                            <Tab label="Checked-In" />
                            <Tab label="Ordered" />
                            <Tab label="Not Ordered" />
                            <Tab label="Not Visited" />
                        </Tabs>

                        { data?.pages[0]?.data?.length !== 0 && 
                        <Box sx={{ paddingTop: "10px",paddingRight: "5px", display: "flex", justifyContent: "end", alignItems: "center"}}>
                            <Button variant="contained" onClick={handleTodayData}  >
                                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", gap: 1}}>
                                    <Box>< IconCalendar /></Box>
                                    <Box>Today's Check-Ins</Box>
                                </Box>                            
                            </Button>
                        </Box>}                

                        <ScrollComponent data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} Item={Item}  />

                    </Box>
                    
                    <FilterForm currentTab={currentTab} navigateURL={"/filtered_checkins"} />

                    <NewCheckinButton refetch={refetch}
                        creator={portal == "supplier" ? 2 : 3} 
                        createdInAreas={[ 
                            {label : portal == "supplier" ? "Supplier App" : "Buyer App"},
                            {label : "Add Button"},
                        ]} 
                    />
                    <BackToTop />
                    
                </Layout>
            }
            </>
            }
        </>
    );
}

export default CheckInsPage;
