import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'; // Grid version 1
import { GlobalContext } from '../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import LoadingComponent from '../components/LoadingComponent';


function Switcher() {
    const navigate = useNavigate();
    const { url, phone, setLedgers, loggedIn, setLoggedIn, multi, setMulti, setPhone, contact, setContact, customer, setCustomer, token, setToken, portal, setPortal, setSessionToken  } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [switcher, setSwitcher] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetchData();
        console.log(loading);
        if(window.cordova) {
            window.cordova.plugins.firebase.analytics.setUserId(contact);
            console.log("Requesting token");
            window.cordova.plugins.firebase.messaging.getToken().then(function(token) {
                console.log("Got device token: ", token);
                sendToken(token);
            });
        }
    }, []);

    useEffect(() => {
        if(data?.customers) {
            if(Object.keys(data?.customers).length > 1) {
                setMulti(true);
            }
        }
        console.log("data?.customers", data?.customers);
    }, [data?.customers]);

    const sendToken = () => {
        axios.post(url + "api/token", {
            phone: phone,
            token: token,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const fetchData = () => {
        axios.post(url + "api/accounts", {
            phone: phone,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            console.log({response})
            console.log(response);
            setData(response?.data);
            setLoading(false);
        })
        .catch(function (error) {
            console.log({error});
            if(error.response?.data?.hasOwnProperty("message")) {
                if(error.response?.data?.message == "Unauthenticated.") {
                    setPhone(""); 
                    setToken(""); 
                    setLoggedIn(""); 
                    setSessionToken("");
                    return navigate("/")
                }
            }
        });
    }


    const handleCompany = (customer_id, contact_id, is_supplier, is_buyer) => {
        setLoading(true);
        axios.post(url + "api/switch", {
            customer: customer_id,
            contact: contact_id,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            setLedgers(response?.data);
            setContact(contact_id);
            setCustomer(customer_id);
            setLoading(false);
            if(is_supplier == 1 && is_buyer == 0) {
                setPortal("supplier");
                return navigate("/supplier");
            } else if(is_supplier == 0 && is_buyer == 1) {
                setPortal("buyer");
                return navigate("/buyer");
            } else if(is_supplier == 1 && is_buyer == 1) {
                setSwitcher(true);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const Companies = () => {
        return (
            <Box style={{overflow: "auto", width: "100%"}}>
                    {data?.customers.map((row, index) => (
                        <Card key={row.customer_name} onClick={() => handleCompany(row.customer, row.contact, row.is_supplier, row.is_buyer)} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', my: 2, px: 4, py:4 }}>
                            <ListItemText>{row.customer_name}</ListItemText>
                        </Card>          
                    ))}                    
            </Box>
        );
    }

    const RenderView = () => {
        if(!switcher) {
            setMulti(true);
            return (
                <Box sx={{ p:1, height: "100%", width: "80%", maxWidth: "400px", display: "flex" }}>
                    <Box sx={{flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <Typography variant="h6" mb={3} component="h6" sx={{width: "100%"}}>Select a Company</Typography>
                        <Companies/>
                    </Box>
                </Box>
            )
        } else if(switcher) {
            setMulti(true);
            return (
            <Box sx={{ p:1, width: "80%", maxWidth: "400px", display: "flex", flexDirection: "column" }}>
                <Typography variant="h6" mb={3} component="h6">Select a portal</Typography>
                <Card onClick={event => { setPortal("supplier"); return navigate("/supplier") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mb: 4, px: 4, py:4 }}>
                    <Typography variant="body1" component="p">Supplier</Typography>
                </Card>

                <Card onClick={event => { setPortal("buyer"); return navigate("/buyer") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mb: 4, px: 4, py:4 }}>
                    <Typography variant="body1" component="p">Buyer</Typography>
                </Card>
            </Box> 
            )
        }
    }

    return (
        <Box sx={{ height:"100vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", py: 2 }}>
            {  loading ? 
                <LoadingComponent loading={loading} />
            : 
                <RenderView />
            }
        </Box>
    );
    
}

export default Switcher;
