import { LoadingButton } from '@mui/lab';
import {Box, Dialog, DialogContent, DialogTitle, Fab, Grid, InputLabel,TextField, } from '@mui/material'
import { IconFilter  } from '@tabler/icons-react';
import React, { useContext, useState } from 'react';
import { FilterDataContext } from '../contexts/FilteredDataContext';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

export default function PlansFilterForm(){

    const navigate = useNavigate()

    const { setPlansFromDate, setPlansToDate, clearContextState } = useContext(FilterDataContext);

    const [ modalOpen, setModalOpen ] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedFromDate, setSelectedFromDate] = useState("")
    const [selectedToDate, setSelectedToDate] = useState("");

    function clearFilterData(){
        setLoading(true);
        setModalOpen(false);
        setSelectedFromDate();
        setSelectedToDate();
        setLoading(false);
    }

    function setGlobalState(){
        selectedFromDate ? setPlansFromDate(format(new Date(new Date(selectedFromDate).setHours(0,0,0,0)), 'yyyy-MM-dd HH:mm:ss')) :  setPlansFromDate("");
        selectedToDate ? setPlansToDate(format(new Date(new Date(selectedToDate).setHours(23,59,59,999)), 'yyyy-MM-dd HH:mm:ss')) :  setPlansToDate("");
    }

    function handleSubmit(){
        if(selectedFromDate && selectedToDate){
        setLoading(true);
        clearContextState();
        setGlobalState();
        clearFilterData();
        setLoading(false);
        navigate("/filtered_plans")
        }
    }

    return(
        <>
        <Fab color="primary" aria-label="add" onClick={() => {setModalOpen(true)}} 
            sx={{ 
                position: "fixed", 
                bottom: (theme) => theme.spacing(2), 
                left: (theme) => theme.spacing(2) 
            }}
            size={"medium"}
        >
            <IconFilter />
        </Fab>

        <Dialog onClose={clearFilterData} open={modalOpen}>
            <DialogTitle>Filter</DialogTitle>
            <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                                
                <Grid container spacing={2} sx={{mb:4}}>
                    <Grid item xs={12}>
                        <InputLabel shrink>From Date</InputLabel>
                        <TextField type="date"  variant="outlined" fullWidth 
                        value={selectedFromDate}
                        size="small"
                        onChange = {(event) => { setSelectedFromDate(event?.target?.value) }}
                        InputLabelProps={{ shrink: true }} 
                        helperText={(selectedToDate && !selectedFromDate ) ? "Please enter From Date" : "" }
                        FormHelperTextProps={{error: true}}
                        InputProps={{
                            inputProps: {
                                max: selectedToDate || "", 
                            },
                            }}
                        />
                        
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel shrink>To Date</InputLabel>
                        <TextField type="date"  variant="outlined" fullWidth 
                        value={selectedToDate}
                        size="small"
                        onChange = {(event) => { setSelectedToDate(event?.target?.value) }}
                        InputLabelProps={{ shrink: true }}
                        helperText={(selectedFromDate && !selectedToDate ) ? "Please enter To Date" : "" }
                        FormHelperTextProps={{error: true}}
                        InputProps={{
                            inputProps: {
                                min: selectedFromDate || "",
                            },
                            }}
                        />                                    
                    </Grid>
                </Grid>                
                
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                    <LoadingButton loading={loading} disabled={!((selectedFromDate && selectedToDate) || (!selectedFromDate && !selectedToDate))} variant="contained" onClick={handleSubmit}>Filter</LoadingButton>
                </Box>
            </DialogContent>
        </Dialog>
        </>
    )
}