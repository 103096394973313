import { Alert, AlertTitle, Box, Button, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import BackToTop from "../../../components/BackToTop";
import ErrorMessage from "../../../components/ErrorMessage";
import Layout from "../../../components/Layout";
import LoadingComponent from "../../../components/LoadingComponent";
import ScrollComponent from "../../../components/ScrollComponent";
import { FilterDataContext } from "../../../contexts/FilteredDataContext";
import { GlobalContext } from "../../../contexts/GlobalContext";

export default function FilteredPlans(){

    const navigate = useNavigate();
    
    const { plansFromDate, plansToDate } = useContext(FilterDataContext);
    const { url, customer, token } = useContext(GlobalContext);

    const title = "Filtered Plans";

    const fetcher = async (page) => {        
        const response = await axios.post( url + 'api/buyers/plans?page=' + page, {
            customer: customer,
            from_date: plansFromDate,
            to_date: plansToDate,
        }, { headers: { Authorization: `Bearer ${token}` }})

        return response.data
    }

    const { isLoading, data, error, isError, fetchNextPage, hasNextPage } = useInfiniteQuery( 
        'filteredPlans',  
        ({ pageParam = 1 }) => fetcher(pageParam),
        {
          getNextPageParam: (lastPageData) => { 
            if(lastPageData?.current_page < lastPageData.last_page){
                return lastPageData?.current_page + 1
            }else{
                return undefined
            } 
          },
          refetchOnWindowFocus: false,
        }
    );

    if(!filterDataBuyer && !filterDataSupplier){
        navigate(-1, { replace: true })
        return null;
    }

    // console.log({isLoading, data, error, isError, fetchNextPage, hasNextPage})

    const Item = ({data}) => {
        return (
            <Paper onClick={() => { navigate("/buyer/plan/" + data.id) }} sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                <Grid container>
                    <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Plan #</Typography>
                        <Typography variant="body" component="p">{data.id}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Date</Typography>
                        <Typography variant="body" component="p">{data.date ? format(parse(data.date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd MMM yyyy  hh:mm a') : ''}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Return Date</Typography>
                        <Typography variant="body" component="p">{data.return_date ? format(parse(data.return_date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd MMM yyyy  hh:mm a') : ''}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ borderTop: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Button variant="contained"  onClick={() => navigate("/buyer/plan/" + data.id)} >View Plan</Button>
                    </Grid>
                </Grid>
            </Paper>
        )
    }

    if(isError){
        return (
            <ErrorMessage title={title} message={error?.message} /> 
        )
    }

    return(
        <>
            {isError ? <ErrorMessage title={title} message={error?.message} /> :
                <Box>            
                    {  isLoading ? 
                    <LoadingComponent loading={isLoading} />
                    : 
                    <Layout title={title} >                
                        <Box>
                            <Alert severity="success" >
                                <AlertTitle sx={{fontSize: "0.5 em"}}> Results </AlertTitle>
                                {(plansFromDate && plansToDate) && 
                                ` From: ${format(new Date(plansFromDate), 'dd/MM/yyyy')} To: ${format(new Date(plansToDate), 'dd/MM/yyyy')}`
                                }
                            </Alert>                    
                        </Box>
                        <ScrollComponent data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} Item={Item}  /> 
                        <BackToTop alone={true} />               
                    </Layout>
                    }
                </Box>
            }
        </>
    )
}