import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../../components/Layout';
import { GlobalContext } from '../../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import NewProduct from '../../components/NewProduct';
import { IconPencil, IconTrash,} from '@tabler/icons-react';
import Fancybox from '../../components/Fancybox';
import LoadingComponent from '../../components/LoadingComponent';
import NoData from '../../components/NoData';
import { Alert, Autocomplete, Dialog, DialogActions, DialogContent, IconButton, Paper, Tab, Tabs, TextField } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone';


function CatalogManage() {

    const navigate = useNavigate();

    let { id, status } = useParams();
    const { cdn, url, phone, contact, customer, token, } = useContext(GlobalContext);
    const title = "Catalog"

    const [loading, setLoading] = useState(true);
    const [categoryDetail, setCategoryDetail] = useState(null);

    useEffect(() => {
        const fetchCategoryDetails = () => {
            axios.post( url + 'api/suppliers/category/details', {
                customer: customer,
                category: id,
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setCategoryDetail(response?.data)
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            });
        }

        fetchCategoryDetails()
    }, [])



    const ProductsList = () => {

        const [items, setItems] = useState([]);
        const [sizes, setSizes] = useState([]);
        const [hasMore, setHasMore] = useState(true);
        const [currentPage, setCurrentPage] = useState(0);

        useEffect(() => {
            setItems([]);            
            setHasMore(true);
            setCurrentPage(0);
            fetchData();
            if(window.cordova) {
                window.cordova.plugins.firebase.analytics.setCurrentScreen("Supplier Manage Catalog");
            }
        }, []);
    
        const fetchData = () => {
            var page = currentPage + 1;
            axios.post( url + 'api/suppliers/catalog/products?page=' + page, {
                customer: customer,
                contact: contact,
                phone: phone,
                category: id,
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                // console.log({response})
                setItems((prev) => [...prev, ...response.data.products.data]);
                setSizes(response.data.sizes);
                setCurrentPage(parseInt(response.data.products.current_page));
                if(response.data.products.current_page == response.data.products.last_page) {
                    setHasMore(false);
                }
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });
        }
        
        function Item (prop) {
            
            const removeData = (receivedId, index) => {
                if(confirm("Do you really want to delete this awesome product?")) {
                    axios.post( url + 'api/suppliers/catalog/products/delete', {
                        customer: customer,
                        contact: contact,
                        phone: phone,
                        id: receivedId,
                    }, { headers: { Authorization: `Bearer ${token}` }})
                    .then(function (response) {
                        location.reload();
                        // let newItems = items.splice(index, 1);
                        // setItems(newItems);
                        setLoading(false);

                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                }
            }
            let images = JSON.parse(prop.data.image);

            // console.log({images})
            
            return (
                <Card sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center',}}>
                    {(images != null && images?.length > 0) ? 
                        // <CardMedia sx={{ height: 140 }} image={cdn + 'catalog/' + images[0]?.file} data-fancybox="products" href={cdn + 'catalog/' + images[0].file}/> 
                        <CardMedia component="div" sx={{ height: 180, position: 'relative' }} >
                            <img
                                src={cdn + 'catalog/' + images[0]?.file}
                                alt="Product Image"
                                data-fancybox={"products"}
                                href={cdn + 'catalog/' + images[0].file}
                                style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'fill'
                                }}
                            />
                        </CardMedia>
                        : 
                        <Box sx={{height: 180, background: "#f0f0f0", display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Typography variant="caption">No Image</Typography>
                        </Box>
                    }
                    <CardContent sx={{ borderBottom: 1, borderColor: 'divider', py:1}}>
                        <Typography variant="body2" component="div">{prop.data?.name}</Typography>
                        <Typography variant="caption"  component="div">{prop.data?.sizes?.name}</Typography>
                    </CardContent>
                    <CardActions sx={{ display: 'flex', justifyContent: 'center', gap:1, py:1, px:0}}>
                        <NewProduct type="Edit" category={id} sizes={sizes} data={prop.data}><IconPencil /></NewProduct>
                        <Button onClick={() => { removeData(prop.data.id, prop.index) }} ><IconTrash /></Button>
                    </CardActions>
                </Card>
            )
        }

        return(
            <>
            {!!items?.length  ?
                <>
                    <Fancybox options={{ Carousel: { infinite: false } }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Box sx={{mb: 2}}>
                                <Typography variant="overline" component="p" sx={{p:1, fontSize: ".7rem"}}>List of Products</Typography>
                            </Box>
                            <InfiniteScroll scrollThreshold={0.5} dataLength={items.length} next={fetchData} inverse={false} hasMore={hasMore} loader={<p style={{ textAlign: 'center', fontSize: '14px' }}>Loading...</p>} endMessage={ <p style={{ textAlign: 'center', fontSize: '14px' }}>You have reached end of the list</p>}>
                                <Grid container spacing={2}>                                
                                    {items.map((row, index) => (
                                        <Grid item  xs={6} sm={4} md={3} lg={2} key={row.id}>
                                            <Item  data={row} index={index}></Item>
                                        </Grid>
                                    ))}
                                </Grid>                            
                            </InfiniteScroll>
                        </Box>                    
                    </Fancybox>   
                </>
                : 
                <NoData />
                }
                <NewProduct type="New" category={id} sizes={sizes} />
            </>
        )
    }

    const ConnectedBuyers = () => {

        const [isLoading, setIsLoading] = useState(true);
        const [linkedBuyers, setLinkedBuyers] = useState([]);
        const [buyersWithAccess, setBuyersWithAccess] = useState([]);
        const [buyersWithoutAccess, setBuyersWithoutAccess] = useState([]);
        const [selectedBuyers, setSelectedBuyers] = useState([]);
        const [customerCategory, setCustomerCategory] = useState("");

        const [fetchBuyers, setFetchBuyers] = useState(false);
        const [openModal, setOpenModal] = useState(false);
        const [selectedBuyer, setSelectedBuyer] = useState(null);

        const fetchLinkedBuyers = () => {
            axios.post( url + 'api/suppliers/catalog/buyers', {
                supplier: customer,
                category: id,
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setLinkedBuyers(response?.data?.buyers)
                setCustomerCategory(response?.data?.customer_category_id)
                setIsLoading(false)
                // console.log({response})
            })
            .catch(function (error) {
                console.log(error);
            });
        };

        useEffect(() => {  fetchLinkedBuyers() }, [])

        useEffect(() => {
            setIsLoading(true);
            if(linkedBuyers?.length > 0){
                const allowedBuyers = linkedBuyers?.filter((item) => item?.access == 1)?.sort((a, b) => a?.name?.localeCompare(b?.name));
                setBuyersWithAccess(allowedBuyers)
                const notAllowedBuyers = linkedBuyers?.filter((item) => item?.access == 0)?.sort((a, b) => a?.name?.localeCompare(b?.name));
                setBuyersWithoutAccess(notAllowedBuyers)
            }
            setIsLoading(false);
        }, [linkedBuyers])

        useEffect(() => {
            if(fetchBuyers){
                fetchLinkedBuyers()
                console.log("fetched")
                setFetchBuyers(false)
            }
        }, [fetchBuyers])

        // console.log({linkedBuyers, buyersWithAccess, buyersWithoutAccess, selectedBuyers: JSON.stringify(selectedBuyers)})

        function removeItem(item){
            setSelectedBuyer(item)
            handleModalOpen()
        }

        const handleModalOpen = () => {
            setOpenModal(true)
        };

        const handleModalClose = () => {
            setOpenModal(false)
            setSelectedBuyer(null);
        };

        const removeBuyer = () => {
            axios.post( url + 'api/suppliers/catalog/buyers/remove', {
                supplier: customer,
                category: id,
                buyer: selectedBuyer?.id,
                customer_category: customerCategory?.id
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                // console.log({response})
                setSelectedBuyer(null)
                setFetchBuyers(true)
            })
            .catch(function (error) {
                console.log(error);
            });
        }

        const handleRemoveItem = () => {
            removeBuyer()
            handleRemoveItemCancel();
        }

        const handleRemoveItemCancel = () => {
            setSelectedBuyer(null);
            handleModalClose();            
        }

        function handleAddBuyers(){
            
            const addBuyers = () => {
                if(selectedBuyers?.length > 0){

                    const buyersList = selectedBuyers?.map((item) => {return item?.id})
                    const formData = new FormData();
                    formData.append('supplier', customer);
                    formData.append('category', id);
                    formData.append('buyer', JSON.stringify(buyersList)); // Assuming buyersList is an array or object
                    formData.append('customer_category', customerCategory?.id);

                    axios.post( url + 'api/suppliers/catalog/buyers/add', formData, { headers: { Authorization: `Bearer ${token}` }})
                    .then(function (response) {
                        setSelectedBuyers([])
                        setFetchBuyers(true)
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }}

            addBuyers()
        }

        const RemoveItem = () => {

            // console.log({selectedBuyer});

            return(
                <>
                    { !!selectedBuyer && !!selectedBuyer?.name &&
                    <Dialog open={openModal} onClose={handleModalClose} >               
                        <DialogContent>
                                <Typography variant="body2" component="p">Are you certain about removing <b>{selectedBuyer?.name}</b>? Their access will be revoked.</Typography>                            
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="error" onClick={handleRemoveItemCancel}>Cancel</Button>
                            <Button variant="contained" onClick={handleRemoveItem} autoFocus>Agree</Button>
                        </DialogActions>
                    </Dialog>
                    }                
                </>
            )
        }

        return(
            <>  
                {!isLoading ?
                <>
                <Box sx={{mt:1}}>                
                    <Paper elevation={3} sx={{p:3, mx:1, my:2}}>
                        <Typography variant="h6" sx={{fontWeight: "bold"}} component="p">Buyer Preferences</Typography>
                        <Autocomplete
                            size="small"
                            multiple
                            limitTags={2}
                            options={buyersWithoutAccess}
                            value={selectedBuyers}
                            onChange={(event, newValue) => {
                                setSelectedBuyers(newValue)
                            }}
                            getOptionLabel={(option) => option?.name}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Buyers to connect"/>
                            )}
                            sx={{my:3}}
                            isOptionEqualToValue={(option, value) => { 
                                if(!value){
                                    return true
                                }else{
                                    return option?.id == value?.id
                                }
                            }}
                        />
                        <Button variant="contained" startIcon={<PersonAddAlt1OutlinedIcon />} onClick={handleAddBuyers}>Add Buyer</Button>
                    </Paper>
                    <Box sx={{p:2, mb:2}}>
                        <Typography variant="h6" sx={{fontWeight: "bold"}} component="p">List of Connected Buyers</Typography>
                        {buyersWithAccess?.length > 0 ?
                            buyersWithAccess?.map((item) => {
                                return(
                                    <Box key={item?.id}>
                                    <Paper elevation={3} sx={{p:2, my:2, display: "flex", justifyContent: "space-between", alignItems: "center", borderRadius: 2}}>
                                        <div>  
                                            <Typography variant="body2" component="p">{item?.name}, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{item?.city_details?.name}</span></Typography>
                                        </div>
                                        <div>
                                        <IconButton aria-label="delete" onClick={() => {removeItem(item)}}>
                                            <DeleteOutlineOutlinedIcon />
                                        </IconButton>
                                        </div>
                                    </Paper>
                                    </Box>
                                )
                            })
                        :
                            // <Paper elevation={2} sx={{p:2, display: "flex", justifyContent: "center", alignItems: "center", mt:2}}>
                            //     <Typography variant="subtitle2" component="p">No Buyer is connected</Typography>
                            // </Paper>
                            <Box sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="caption" sx={{mt:4, textAlign: "center"}}>No Buyer is Connected</Typography>
                            </Box>
                            
                        }
                    </Box>
                </Box>
                <RemoveItem />
                </> :
                <Box sx={{display: "flex", justifyContent: "center"}}>
                    <Typography variant="overline" sx={{mt:4, textAlign: "center"}}>Loading...</Typography>
                </Box>
                }
            </>
        )
    }
    

    return (
        <div>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title={title} backToTab={true} link={"/supplier/catalog"}>

                <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={parseInt(status)} onChange={(event, newValue) => {navigate(`/supplier/catalog/manage/${id}/${newValue}`)}} sx={{mb:2}}>
                    <Tab value={1} label="Products List" />
                    <Tab value={2}  label="Connected Buyers" />
                </Tabs>

                { !!categoryDetail?.name && 
                    <Alert sx={{p:1, mb:1}} icon={<CategoryTwoToneIcon />} severity="info">
                        <Typography variant="overline" sx={{fontSize: "0.7rem", fontWeight: 600}}>Category: {categoryDetail?.name}</Typography>
                    </Alert>
                }

                {status == 1 && <ProductsList/>}

                {status == 2 && <ConnectedBuyers />}

            </Layout>
        }
        </div>
    );
}

export default CatalogManage;
