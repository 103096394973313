import React, { useContext, useState } from 'react'
import axios from "axios";
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { IconPlus } from '@tabler/icons-react';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { GlobalContext } from '../contexts/GlobalContext'
import { useNavigate } from 'react-router-dom';

function NewPlanButton(props) {
    const { url, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken, portal  } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [buyer, setBuyer] = useState({})
    const [date, setDate] = useState("")
    const [returnDate, setReturnDate] = useState("")

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setDate("");
        setReturnDate("");

    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        axios.post( url + 'api/buyers/plans/add', {
            customer: customer,
            contact: contact,
            phone: phone,
            date: date,
            return_date: returnDate,
            creator: portal == "supplier" ? 2 : 3,
            created_in_areas : JSON.stringify(props.createdInAreas),
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            console.log("response", response);
            if(response.data.error == 0) {
                setLoading(false);
                // window.location.reload();
                // navigate(0);
                props.refetch ? props.refetch() : document.location.reload();
                handleClose()
            }
        })
        .catch(function (error) {
            setLoading(false);
            console.log(error);
        });
    }

    return (
        <div>
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>New Plan</DialogTitle>
                <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                    <form onSubmit={handleSubmit}>
                        <InputLabel shrink>Date & Time</InputLabel>
                        <TextField sx={{ mb: 2 }} fullWidth required={true} type="datetime-local" 
                            value={date}
                            onChange={event => {
                                const { value } = event.target;
                                setDate(value);
                            }} 
                            InputProps={{
                                inputProps: {
                                    max: returnDate || "", 
                                },
                            }}
                        />
                        <InputLabel shrink>Return Date & Time</InputLabel>
                        <TextField sx={{ mb: 2 }} fullWidth required={true} type="datetime-local" 
                            value={returnDate}
                            onChange={event => {
                                const { value } = event.target;
                                setReturnDate(value);
                            }}
                            InputProps={{
                                inputProps: {
                                    min: date || "", 
                                },
                            }}
                            />
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                            <LoadingButton type="submit" loading={loading} variant="contained">Save</LoadingButton>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
            {props.buttonType=="card" ?
                <Card sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', px: 4, py:4 }} onClick={handleOpen}>
                    { props.icon }
                    <Typography variant="body1" component="p">New Check-In</Typography>
                </Card>
                :
                <Fab color="primary" aria-label="add" size={"medium"} onClick={handleOpen} sx={{ position: "fixed", bottom: (theme) => theme.spacing(2), right: (theme) => theme.spacing(2) }}>
                    <IconPlus />
                </Fab>
            }
        </div>
    )
}
export default NewPlanButton;