import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Layout from '../../components/Layout';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Autocomplete, Button,  DialogTitle,  List,  ListItem,  ListItemButton,  ListItemIcon,  ListItemText,  TextField, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GlobalContext } from '../../contexts/GlobalContext';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import MailIcon from '@mui/icons-material/Mail';
import InfiniteScroll from "react-infinite-scroll-component";
import { useTheme } from '@emotion/react';
import LoadingComponent from '../../components/LoadingComponent';
import NoData from '../../components/NoData';
import Dialog from '@mui/material/Dialog';



export default function Transports() {

    const { url, customer, token } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);

    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);

    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState({});

    const [numbersList, setNumbersList] = useState([]);
    const [open, setOpen] = useState(false);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({        
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.secondary.main , // #044B7F
          borderRight: '1px solid #15649e !important',
          color: "#fff"
        },
        [`&.${tableCellClasses.body}`]: {
          borderRight: '1px solid #e3e1e1'
        },
        '&:last-child': {
            borderRight: 0
        },
    }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: "#f2f2f2",
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            
        },
    }));


    useEffect(() => {
        setItems([]);        
        setHasMore(true);
        setCurrentPage(0);
        setLoading(true)
        fetchData();
    }, [selectedCity]);

    const theme = useTheme();

    const fetchData = async () => {

        if(selectedCity?.id){
            const response = await axios.post(url + 'api/support/transports?city='+selectedCity.id, {} ,{ headers: { Authorization: `Bearer ${token}` }})
            .then((data) =>  {
                setItems(data?.data?.transports?.data)
                setHasMore(false)
                setLoading(false)
            })
            .catch(err =>  {setLoading(false); console.log({error: err})})}
        else{        
        let page = currentPage + 1;
        axios.post( url + 'api/support/transports?page=' + page , {
            customer: customer,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            setItems((prev) => [...prev, ...response.data?.transports?.data]);
            setCurrentPage(parseInt(response.data.transports.current_page));
            if(response.data.current_page == response.data.last_page) {
                setHasMore(false);
            }
            setLoading(false);            
        })
        .catch(function (error) {
            setLoading(false);
            console.log(error);
        });
    }}

    const isMobile = useMediaQuery('(max-width:600px)');
    

    useEffect(()=> {
        axios.post(url + 'api/helper/transports/cities', {} ,{ headers: { Authorization: `Bearer ${token}` }})
        .then(data =>  setCities(data.data))
        .catch(err =>  console.log({error: err}))
    }, []);

    const openLink = (link) => {
        if(!window.cordova) {
            window.open(link, '_blank').focus();
        } else {
            cordova.InAppBrowser.open(link, "_system");
        }
    }

    const FilterComponent = ({sx}) => {
        
        return (
            <Autocomplete
            sx={sx}
            options={cities}
            getOptionLabel={(option) => {
                if(typeof option === 'string'){
                    return option
                }else{
                    return option.name
                }
            }}
            renderInput={(params) => <TextField {...params} label="Filter Transports by City" />}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option.name}
                </Box>
              )}
            onChange = {(event, newValue) => { setSelectedCity(newValue) }}
            isOptionEqualToValue = {(option, selectedValue) => option.name === selectedValue}
            value={selectedCity && selectedCity?.name } 
        />
        )
    }

    const DesktopTable = () => {
        return (            
            <Box style={{marginBottom: "5px", marginTop: "2px"}} >
            <Box sx={{display: "flex", justifyContent: "flex-end", margin: "10px"}}>
                <FilterComponent sx={{width: "33%"}} />
            </Box>
            
            <InfiniteScroll scrollThreshold={0.5} dataLength={items.length} next={fetchData} style={{ display: 'flex', flexDirection: 'column' }} inverse={false} hasMore={hasMore} loader={<p style={{ textAlign: 'center', fontSize: '14px' }}>Loading...</p>} endMessage={<p style={{ textAlign: 'center', fontSize: '14px' }}>You have reached end of the list</p>}>
       
            <TableContainer component={Paper}>
                <Table>
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell align="left">#</StyledTableCell>
                        <StyledTableCell align="left">Name</StyledTableCell>
                        <StyledTableCell align="left">Contact</StyledTableCell>
                        <StyledTableCell align="left">Address</StyledTableCell>
                        <StyledTableCell align="left">Phone</StyledTableCell>
                        <StyledTableCell align="left">Email</StyledTableCell>
                    </StyledTableRow>                    
                </TableHead>
                <TableBody>
                    {items?.map((item,index) => {
                        return <DesktopTableRow key={index+1} index={index+1} item={item} />
                    })}
                </TableBody>
                </Table>
            </TableContainer>

            </InfiniteScroll>
            </Box>           
        )}


    const DesktopTableRow = ({item, index}) => { 
        const [numberStyle, setNumberStyle] = useState({});
        let mobileNumbers = [];
            const isMultipleNumber = item.phone?.includes(",")
            if(isMultipleNumber){
                mobileNumbers = item.phone.split(',')
            }
        
        return (
            <StyledTableRow>
                <StyledTableCell align="left">{index}</StyledTableCell>
                <StyledTableCell align="left">{item?.name}</StyledTableCell>
                <StyledTableCell align="left">{item?.contact_person}</StyledTableCell>
                <StyledTableCell align="left">{item?.address}</StyledTableCell>
                <StyledTableCell align="left">
                    { !!item?.phone &&
                    <Typography sx={numberStyle} onClick={isMultipleNumber ? () => handleMultipleNumbers(mobileNumbers) :() => { openLink(`tel:${item?.phone}`) }}  onMouseEnter={()=> setNumberStyle({cursor: "pointer", color: theme.palette.primary.main, textDecoration: "underline" })}
                        onMouseLeave={()=>setNumberStyle({color: "#000"})}>
                            {item?.phone}
                    </Typography>
                    }
                </StyledTableCell>
                <StyledTableCell align="left">
                    {!!item?.email && <Typography sx={{cursor: "pointer"}} onClick={()=> openLink(`mailto:${item?.email}`)}>{item?.email}</Typography>}
                </StyledTableCell>
            </StyledTableRow>
        );
    }

    function handleMultipleNumbers (numberArray)  {
        console.log({numberArray})
        setOpen(true);
        setNumbersList(numberArray);
    }

    const MobileView = () => {

        const Item = ({transport, index}) => {

            let mobileNumbers = [];
            const isMultipleNumber = transport.phone?.includes(",")
            if(isMultipleNumber){
                mobileNumbers = transport.phone.split(',')
            }      
            return (
                <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', my:2 }}>
                    <Box sx={{padding: 2}}>

                        <Typography variant="h6" component="h6"  sx={{mb: 1}}><b>{transport.name}</b></Typography>

                        {transport?.contact_person ? 
                        <Box sx={{display: 'flex', gap: 1, mb: 1, alignItems: "center",}}>
                        <PersonIcon fontSize='small' />
                        <Typography variant="h6" component="h6" >{transport?.contact_person?.toUpperCase()}</Typography>
                        </Box> : <></>}                        
                        
                        {transport.address ? 
                        <Box sx={{display: 'flex', gap: 1, mb: 1}}>
                        <LocationOnIcon fontSize='small'  />
                        <Typography variant="h6" component="h6" >{transport.address}</Typography>
                        </Box> : <></>}

                        {!!transport.phone ?
                            <Box sx={{display: "flex", gap: 2, mb: 1}}>
                                <Box sx={{display: 'flex', gap: 1, flex:2, justifyContent: "flex-start", alignItems: "center",}}>
                                    <PhoneIcon fontSize='small'  />
                                    <Typography variant="h6" component="h6">{transport.phone}</Typography>
                                </Box>
                                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <Button onClick={isMultipleNumber ? () => handleMultipleNumbers(mobileNumbers) :() => { openLink(`tel:${transport.phone}`) }} 
                                    variant="contained" sx={{ flex: 1, overflow: 'hidden', whiteSpace: 'nowrap', }}>Call</Button>
                                </Box>
                            </Box> 
                            :
                            <Box sx={{display: "flex", gap: 2, mb: 1}}>
                                <Box sx={{display: 'flex', gap: 1, flex:2, justifyContent: "flex-start", alignItems: "center",}}>
                                    <PhoneIcon fontSize='small'  />
                                    <Typography variant="h6" component="h6">{transport?.phone}</Typography>
                                </Box>
                                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <Button disabled variant="contained" sx={{ flex: 1, overflow: 'hidden', whiteSpace: 'nowrap', }}>Call</Button>
                                </Box>
                            </Box>
                        }

                        { transport?.email && 
                            <Box sx={{display: "flex", gap: 2, }}>
                                <Box sx={{display: 'flex', gap: 1, flex:2, justifyContent: "flex-start", alignItems: "center", }}>
                                    <MailIcon fontSize='small'  />
                                    <Typography variant="h6" component="h6">{transport?.email && transport?.email}</Typography>
                                </Box>
                                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <Button onClick={()=> openLink(`mailto:${transport?.email}`)} 
                                    variant="contained" sx={{ flex: 1, overflow: 'hidden', whiteSpace: 'nowrap', }}>Email</Button>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Paper>
            )
        };

        return(
        <>
        <FilterComponent sx={{margin: "5px"}} />
        { 
        <InfiniteScroll scrollThreshold={0.5} dataLength={items.length} next={fetchData} style={{ display: 'flex', flexDirection: 'column' }} inverse={false} hasMore={hasMore} loader={<p style={{ textAlign: 'center', fontSize: '14px' }}>Loading...</p>} endMessage={<p style={{ textAlign: 'center', fontSize: '14px' }}>You have reached end of the list</p>}>
        {
            items?.map((transport, index) => {
                return <Item transport={transport} index={index} key={index} />
            })
        }
        </InfiniteScroll>
        }

        </>
        )
    }; 

    function handleModalClose() {
        setOpen(false);
        setNumbersList([]);
    }
    
    return (
        <>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title="Transports">
                { 
                items?.length === 0 
                     ? <NoData />
                     : (isMobile ? <MobileView/> : <DesktopTable />) 
                 }
                <Dialog  onClose={handleModalClose} open={open}>
                <DialogTitle >
                   <Typography sx={{fontSize: "20px"}}>Contact Numbers</Typography>
                </DialogTitle>
                <List>
                    {numbersList?.map( (number, index) => {
                        if(number){
                            return  <ListItem key={index} >
                                        <ListItemButton onClick={() => {openLink(`tel:${number.trim()}`); console.log(`openLink(tel:${number.trim()})`)} } >
                                        <ListItemIcon >
                                            <PhoneIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={number.trim()} />
                                        </ListItemButton>
                                    </ListItem>} 
                        }
                     )}
                </List>
                
                </Dialog>
            </Layout>
        }
        </>
    );
}
