import React, { useContext, useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { IconLayoutGrid, IconArrowNarrowLeft, IconHome } from '@tabler/icons-react';
import { GlobalContext } from '../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";

function Layout(props) {
    const { portal } = useContext(GlobalContext);
    const navigate = useNavigate();

    // const goBack = (event) => {
    //     if(Object.hasOwn(props, 'backToHome')) {
    //         if(portal == "supplier") {
    //             return navigate("/supplier/")
    //         } else {
    //             return navigate("/buyer/")
    //         }
    //     } else {
    //         return navigate(-1)
    //     }
    // }

    const goBack = () => {

      if (props.backToHome) {
        if (portal === "supplier") {
          navigate("/supplier/");
        } else {
          navigate("/buyer/");
        }
      } else if (props?.backToTab) {
        if (portal === "supplier") {
          navigate(props?.link);
        } else {
          navigate(props?.link);
        }
      } else {
        navigate(-1, {
          onSuccess: () => {
            if(props?.backToTop){
              window.scrollTo({
                top: 0,
                behavior: "auto",
              })
            }
          },
        });
      }

      if(props?.backToTop) {        
          window.scrollTo({
            top: 0,
            behavior: "auto",
          })
      }
        
      };

    return (
        <Box sx={{ flexGrow: 1, paddingBottom: 'calc(env(safe-area-inset-bottom) + 10px)' }}>
            <AppBar position="sticky" color="secondary" sx={{ backgroundImage: "none", padding: 'calc(env(safe-area-inset-top) + 5px)' }}>
                <Toolbar>
                    {  !props.hasOwnProperty('hideNavigation') && 
                    <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={event => { goBack(); }} >
                        <IconArrowNarrowLeft />
                    </IconButton>
                    }
                    <Typography  component="p" sx={{ flexGrow: 1, textAlign: 'center', fontSize: "1.1em" }}>{props.title}</Typography>
                    {  !props.hasOwnProperty('hideNavigation') && 
                    <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={event => { if(portal == "supplier") { return navigate("/supplier/") } else { return navigate("/buyer/") } }} >
                        <IconHome />
                    </IconButton>
                    }
                </Toolbar>
            </AppBar>
            {  props.hasOwnProperty('nopadding') ?
                props.children
                :
                <Box sx={{ p: 2 }}>{props.children}</Box>
            }
        </Box>
    )
}
export default Layout;
