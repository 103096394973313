import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../../components/Layout';
import TicketReply from '../../components/TicketReply';
import { GlobalContext } from '../../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import { format, formatDistance, parse } from 'date-fns'
import MediaGrid from '../../components/MediaGrid';
import UploadMedia from '../../components/UploadMedia';
import LoadingComponent from '../../components/LoadingComponent';



function Ticket() {
    let { id } = useParams();
    const navigate = useNavigate();
    const { url, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken, portal  } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [title, setTitle] = useState("Ticket");

    useEffect(() => {
        fetchData();
        if(window.cordova) {
            window.cordova.plugins.firebase.analytics.setCurrentScreen("Ticket View");
        }
    }, [ ]);

    const fetchData = () => {
        axios.post( url + 'api/ticket', {
            customer: customer,
            contact: contact,
            phone: phone,
            id: id,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            setData(response.data);
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const Info = () => {
        if (data.hasOwnProperty('ticket')) {
            var ticket_status = "Open";
            if(data.ticket.status == 1) {
                ticket_status = "Open"
            } else if(data.ticket.status == 2) {
                ticket_status = "Closed"
            }
            return (
                <React.Fragment>
                    <Paper sx={{ border: 1, borderColor: 'divider', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, mb: 4 }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                            <Typography variant="overline" component="p">{portal == "supplier" ? "Buyer" : "Supplier"}</Typography>
                            <Typography variant="h6" component="h6">{portal == "supplier" ? data.ticket.buyer_name : data.ticket.seller_name }</Typography>
                        </Box>
                        <Grid container>
                            <Grid item xs={4} sx={{ borderRight: 1, borderBottom: 1,  borderColor: 'divider', px: 1, py:1 }}>
                                <Typography variant="overline" component="p">Ticket #</Typography>
                                <Typography variant="body" component="p">{data.ticket.ticket_no ? data.ticket.ticket_no : '-'}</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{  borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                <Typography variant="overline" component="p">Date</Typography>
                                <Typography variant="body" component="p">{data.ticket.date ? format(parse(data.ticket.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : '-'}</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                <Typography variant="overline" component="p">Status</Typography>
                                <Chip label={ticket_status} />
                            </Grid>
                            <Grid item xs={12} sx={{ px: 1, py:1 }}>
                                <Typography variant="overline" component="p">Subject</Typography>
                                <Typography variant="body" component="p">{data.ticket.subject ? data.ticket.subject : ''}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                    <TicketReply id={data.ticket.id} />
                </React.Fragment>
            )
        } else {
            return (
                <Paper sx={{ border: 1, borderColor: 'divider', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, px: 2, py:2 }}>
                    <Typography variant="overline" component="p">Loading...</Typography>
                </Paper>
            )
        }
    }

    const Messages = () => {
        return (<React.Fragment>
        {data.messages.map((row, index) => (
            <Paper key={index} sx={{ border: 1, borderColor: 'divider', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, mb: 4 }}>
                <Grid container>
                    <Grid item xs={12} sx={{ borderBottom: 1,  borderColor: 'divider', px: 2, py: 2 }}>
                        <Typography variant="body" component="p">{row.content}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Created on</Typography>
                        <Typography variant="body" component="p">{row.created_at ? formatDistance(new Date(row.created_at), new Date(), { addSuffix: true }) : '-'}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Created by</Typography>
                        <Typography variant="body" component="p">{row.creator_name ? row.creator_name : '-'}</Typography>
                    </Grid>
                </Grid>
                <MediaGrid dir="tickets" images={row.images} />
                {
                    <Box sx={{px: 2,}}>
                        <UploadMedia 
                        id={row.id} module="ticket_message"
                        dir="tickets" link={url + 'api/media/add'}
                    />
                    </Box>
                }
            </Paper>
        ))}
        </React.Fragment>)
    }

    return (
        <div>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title={title}>
                <Info />
                <Messages />
            </Layout>
        }
        </div>
    );
}

export default Ticket;
